import { Badge } from "@/components/atoms/Badge";

interface ConvocationBadgeProps {
  sentDate?: Date;
}

const ConvocationBadge = ({ sentDate }: ConvocationBadgeProps) => {
  if (sentDate)
    return (
      <Badge variant="green">
        Envoyée le {new Date(sentDate).toLocaleDateString("fr-FR")}
      </Badge>
    );
  else return <Badge variant="white">Non disponible</Badge>;
};

export default ConvocationBadge;
