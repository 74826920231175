import { useLogInMutation } from "@/api/auth/auth";
import { Role } from "@/api/enums/Role";
import {
  ConnectionSchema,
  onErrorConnect,
} from "@/components/templates/ConnectPage/ConnectionForm";
import ConnectionPage from "@/components/templates/ConnectPage/ConnectPage";
import { useAuthStore } from "@/hooks/AuthStore";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { useState } from "react";
import { toast } from "sonner";

const Login = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const { login } = useAuthStore();

  useRoleBasedRedirect(null);

  const loginMutation = useLogInMutation();

  const onSubmit = (data: ConnectionSchema) => {
    loginMutation.mutate(
      {
        username: data.email,
        key: data.key,
      },
      {
        onSuccess: async (loginResponse) => {
          if (loginResponse.role !== Role.ADMIN) {
            setErrorMsg("Ce compte n'a pas le rôle administrateur.");
            return;
          }

          login(data.email, loginResponse);
          toast.success("Connexion réussie.");
        },
        onError: (error) => {
          setErrorMsg(onErrorConnect(error));
        },
      }
    );
  };

  return (
    <ConnectionPage
      onSubmit={onSubmit}
      errorMsg={errorMsg}
      pending={loginMutation.isPending}
    />
  );
};

export default Login;
