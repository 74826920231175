import { CandidateStatus } from "@/api/candidate/dto/candidate-status.enum";
import { CandidateBasicDtoClient } from "@/api/candidate/dto/candidate.dto";
import { Badge } from "@/components/atoms/Badge";

interface StatusBadgeProps {
  basicInfo: CandidateBasicDtoClient;
}

const StatusBadge = ({ basicInfo }: StatusBadgeProps) => {
  let status = null;

  if (basicInfo.onGoingRegistration) status = CandidateStatus.OnGoing;
  else if (
    basicInfo.lastCertificate &&
    new Date(basicInfo.lastCertificate.expirationDate) >= new Date()
  )
    status = CandidateStatus.Valid;
  else status = CandidateStatus.Expired;

  switch (status) {
    case CandidateStatus.Expired:
      return <Badge variant="white">Expiré</Badge>;
    case CandidateStatus.OnGoing:
      return <Badge variant="orange">En cours</Badge>;
    case CandidateStatus.Valid:
      return <Badge variant="green">Valide</Badge>;
  }
};

export default StatusBadge;
