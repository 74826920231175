import { ExamStatus } from "@/api/enums/exam-status.enum";
import { ExamType } from "@/api/enums/ExamType";
import { ExamDetailedDtoClient } from "@/api/exam/dto/exam-detailed.dto";
import { useQueryExamDetailedPaginated } from "@/api/exam/exam";
import ExamStatusBadge from "@/components/molecules/Badges/ExamStatusBadge";
import ExamTypeBadge from "@/components/molecules/Badges/ExamTypeBadge";
import PercentageProgress from "@/components/molecules/Progress/PercentageProgress";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { SessionsTab } from "../sessions-tab.enum";

const tabToStatus = (tab: SessionsTab): ExamStatus[] => {
  switch (tab) {
    case SessionsTab.All:
      return [];
    case SessionsTab.Open:
      return [ExamStatus.QCMS_GENERATED, ExamStatus.QCMS_NOT_GENERATED];
    case SessionsTab.Closed:
      return [ExamStatus.QCMS_GENERATED, ExamStatus.QCMS_NOT_GENERATED];
    case SessionsTab.Corrected:
      return [ExamStatus.CORRECTED];
    case SessionsTab.NotesGenerated:
      return [ExamStatus.NOTES_GENERATED];
    case SessionsTab.CertificatesGenerated:
      return [ExamStatus.CERTIFICATES_GENERATED];
  }
};

interface SessionTableProps {
  tab: SessionsTab;
  sessionTypeTab: ExamType | "all";
  searchText?: string;
}

const columnHelper = createColumnHelper<ExamDetailedDtoClient>();

const SessionsTable = ({
  sessionTypeTab,
  tab,
  searchText,
}: SessionTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent("sessions-page-index", 0);
  const navigate = useNavigate();

  const examsQuery = useQueryExamDetailedPaginated(
    {
      pageIndex,
      searchText,
      status: tabToStatus(tab),
      type: sessionTypeTab === "all" ? undefined : sessionTypeTab,
      registrationClosed:
        tab === SessionsTab.Closed
          ? true
          : tab === SessionsTab.Open
          ? false
          : undefined,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("number", {
        id: "number",
        header: "N° Session",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("type", {
        id: "type",
        header: "Type",
        cell: (info) => <ExamTypeBadge examType={info.getValue()} />,
      }),
      columnHelper.accessor("registeringEndDate", {
        id: "registeringEndDate",
        header: "Clôt. insc.",
        cell: (info) => new Date(info.getValue()).toLocaleDateString("fr-FR"),
      }),
      columnHelper.accessor("date", {
        id: "date",
        header: "Date exam.",
        cell: (info) => new Date(info.getValue()).toLocaleDateString("fr-FR"),
      }),
      columnHelper.accessor(
        ({ status, registeringEndDate }) => ({ status, registeringEndDate }),
        {
          id: "status",
          header: "Statut",
          cell: (info) => {
            const { registeringEndDate, status } = info.getValue();
            return (
              <ExamStatusBadge
                registeringEndDate={new Date(registeringEndDate)}
                status={status}
              />
            );
          },
        }
      ),
      columnHelper.accessor(
        ({ registeredCount, preRegisteredCount }) =>
          preRegisteredCount - registeredCount,
        {
          id: "preRegistered",
          header: "Pré-inscrits",
          cell: (info) => info.getValue(),
        }
      ),
      // pre-registered count stores all registered candidates,
      // whether they are registered or not,
      // which is what must be shown here
      columnHelper.accessor("preRegisteredCount", {
        id: "registered",
        header: "Inscrits",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor(
        ({ preRegisteredCount, registeredCount }) =>
          preRegisteredCount === 0
            ? NaN
            : (registeredCount / preRegisteredCount) * 100,
        {
          id: "registeredPercentage",
          header: "% inscrits",
          cell: (info) => <PercentageProgress percentage={info.getValue()} />,
        }
      ),
      columnHelper.accessor(
        ({ successCount, registeredCount, status }) =>
          ![ExamStatus.QCMS_GENERATED, ExamStatus.QCMS_NOT_GENERATED].includes(
            status
          ) && registeredCount !== 0
            ? (successCount / registeredCount) * 100
            : NaN,
        {
          id: "successPercentage",
          header: "% réussite",
          cell: (info) => <PercentageProgress percentage={info.getValue()} />,
        }
      ),
    ],
    []
  );

  const [rows, is_placeholder] = useTablePlaceholderRows(examsQuery.data);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-auto border border-gray-200 w-full">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="whitespace-nowrap"
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.session.root(row.original.id)
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={examsQuery.data?.totalPages ?? 0}
      />
    </div>
  );
};

export default SessionsTable;
