import { Progress } from "@/components/atoms/Progress";
import { cn } from "@/lib/utils";

interface PercentageProgressColorProps {
  percentage: number;
}

const PercentageProgressColor = ({
  percentage,
}: PercentageProgressColorProps) => {
  if (Number.isNaN(percentage)) return false;

  return (
    <div className="flex flex-row items-center gap-3 min-w-36">
      <Progress
        value={percentage}
        className="h-2 bg-gray-200"
        indicatorClassName={cn({
          "bg-red-500": percentage <= 20,
          "bg-orange-500": percentage > 20 && percentage < 80,
          "bg-green-500": percentage > 80,
        })}
      />
      {Math.round(percentage)}%
    </div>
  );
};

export default PercentageProgressColor;
