import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/molecules/Pagination";
import { useEffect } from "react";

interface SmartPaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const SmartPagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: SmartPaginationProps) => {
  const getPageNumbers = () => {
    const pageNumbers: (number | "ellipsis")[] = [];

    // Always show the first page
    if (currentPage > 2) {
      pageNumbers.push(0, "ellipsis");
    } else {
      for (let i = 0; i < Math.min(3, totalPages); i++) {
        pageNumbers.push(i);
      }
    }

    // Show pages around the current page
    for (
      let i = Math.max(2, currentPage - 1);
      i < Math.min(totalPages - 1, currentPage + 2);
      i++
    ) {
      if (!pageNumbers.includes(i)) pageNumbers.push(i);
    }

    // Always show the last page
    if (currentPage < totalPages - 3) {
      pageNumbers.push("ellipsis", totalPages - 1);
    } else {
      for (let i = Math.max(totalPages - 2, currentPage); i < totalPages; i++) {
        if (!pageNumbers.includes(i)) pageNumbers.push(i);
      }
    }

    return pageNumbers;
  };

  const handlePageClick = (page: number | "ellipsis") => {
    if (page === "ellipsis") return;
    onPageChange(page);
  };

  const pages = getPageNumbers();

  // come back to first page when pageIndex is out of bounds
  useEffect(() => {
    if (currentPage > totalPages - 1) onPageChange(0);
  }, [currentPage, totalPages, onPageChange]);

  if (totalPages < 2) return false;

  return (
    <Pagination>
      <PaginationContent className="w-full justify-between">
        {/* Previous button */}
        <PaginationItem>
          <PaginationPrevious
            isDisabled={currentPage === 0}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (currentPage > 0) onPageChange(currentPage - 1);
            }}
          />
        </PaginationItem>

        {/* Page numbers */}
        <div className="flex flex-row items-center">
          {pages.map((page, index) => (
            <PaginationItem key={index}>
              {page === "ellipsis" ? (
                <PaginationEllipsis />
              ) : (
                <PaginationLink
                  href="#"
                  isActive={page === currentPage}
                  onClick={(e) => {
                    e.preventDefault();
                    handlePageClick(page);
                  }}
                >
                  {page + 1}
                </PaginationLink>
              )}
            </PaginationItem>
          ))}
        </div>

        {/* Next button */}
        <PaginationItem>
          <PaginationNext
            isDisabled={currentPage === totalPages - 1}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              if (currentPage < totalPages - 1) onPageChange(currentPage + 1);
            }}
          />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
};

export default SmartPagination;
