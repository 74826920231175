export enum DocumentType {
  CERTIFICATE = "CERTIFICATE",
  FOREIGN_CERTIFICATE = "FOREIGN_CERTIFICATE",
  CONVOCATION = "CONVOCATION",
  RESULTS = "RESULTS",
  DEBIT_PERSONAL_PAID = "DEBIT_PERSONAL_PAID",
  DEBIT_PERSONAL_UNPAID = "DEBIT_PERSONAL_UNPAID",
  DEBIT_COMPANY_UNPAID = "DEBIT_COMPANY_UNPAID",
  DEBIT_COMPANY_PAID = "DEBIT_COMPANY_PAID",
  ATTENDANCE_ATTESTATION = "ATTENDANCE_ATTESTATION",
  REDUCED_PRICE_JUSTIFICATION = "REDUCED_PRICE_JUSTIFICATION",
  FORMATION_ATTESTATION = "FORMATION_ATTESTATION",
  PROROGATION = "PROROGATION",
}
