import { FieldMandatoryType } from "./mandatory-type";

interface FieldWithOptionalityProps {
  str: string;
  fieldMandatoryType: FieldMandatoryType;
}

export const FieldWithOptionality = ({
  fieldMandatoryType,
  str,
}: FieldWithOptionalityProps) => {
  switch (fieldMandatoryType) {
    case FieldMandatoryType.MANDATORY:
      return <>{str} *</>;
    case FieldMandatoryType.MANDATORY_ON_CERTIFICATE:
      return (
        <>
          {str} <span className="text-brand-600">*</span>
        </>
      );
    case FieldMandatoryType.OPTIONAL:
      return <>str</>;
  }
};

export default FieldWithOptionality;
