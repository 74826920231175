import { ExamRegistrationStatus } from "@/api/enums/exam-registration-status.enum";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { CandidatesTab } from "./candidates-tab.enum";

const statusPerTab = {
  [CandidatesTab.All]: [],
  [CandidatesTab.Incomplete]: [ExamRegistrationStatus.UNCOMPLETE],
  [CandidatesTab.Complete]: [
    ExamRegistrationStatus.DONE,
    ExamRegistrationStatus.COMPLETE,
    ExamRegistrationStatus.WAITING_RESULTS,
  ],
};

interface CandidatesFilterTabsProps {
  setStatus: React.Dispatch<React.SetStateAction<ExamRegistrationStatus[]>>;
}

const CandidatesFilterTabs = ({ setStatus }: CandidatesFilterTabsProps) => {
  const [tab, setTab] = usePersistent<CandidatesTab>(
    "admin-session-candidates-tab",
    CandidatesTab.All
  );

  return (
    <Tabs
      onValueChange={(e) => {
        setTab(e as CandidatesTab);
        setStatus(statusPerTab[e as CandidatesTab]);
      }}
      value={tab}
    >
      <TabsList
        variant="transparent"
        className="pb-0 mb-0 border-b border-b-gray-200 rounded-none"
      >
        {[
          {
            name: "Tous",
            tab: CandidatesTab.All,
          },
          {
            name: "En cours",
            tab: CandidatesTab.Incomplete,
          },
          {
            name: "Finalisée",
            tab: CandidatesTab.Complete,
          },
        ].map(({ name, tab }) => (
          <TabsTrigger variant="underlineBlue" value={tab} key={tab}>
            {name}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default CandidatesFilterTabs;
