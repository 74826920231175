import { ExtensionType } from "@/api/enums/ExtensionType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { RenewingType } from "@/api/enums/RenewingType";
import { id, utc } from "@/constants/zodTypes";
import {
  merchandisesSchema,
  perimeterSchema,
  transportsSchema,
} from "@molecules/PerimeterForm/PerimeterFormSchema";
import { z } from "zod";

const certificatesSchema = z
  .number()
  .array()
  .superRefine((ids, ctx) => {
    if (ids.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Veuillez choisir un certificat",
      });
    }
  });

const registerStepSchema = z.discriminatedUnion("type", [
  // initial
  z.object({
    type: z.literal(RegistrationType.INITIAL),
    initialExamId: id("Veuillez choisir un examen."),
    initialProperties: z.object({
      utc: utc(),
      perimeter: perimeterSchema,
    }),
  }),
  // extension
  z.object({
    type: z.literal(RegistrationType.EXTENSION),
    extensionExamId: id("Veuillez choisir un examen."),
    extensionProperties: z.object({
      utc: utc(),
      certificateId: certificatesSchema, // one certificate only must be chosen but this make things easier with components
      extension: z.discriminatedUnion("extensionType", [
        // extend transports
        z.object({
          extensionType: z.literal(ExtensionType.TRANSPORTS),
          extendedTransportModes: transportsSchema,
        }),
        // extend merchandises
        z.object({
          extensionType: z.literal(ExtensionType.MERCHANDISES),
          extendedMerchandiseClasses: merchandisesSchema,
        }),
      ]),
    }),
  }),
  // renewal
  z.object({
    type: z.literal(RegistrationType.RENEW),
    renewingExamId: id("Veuillez choisir un examen."),
    renewingProperties: z
      .object({
        renewingCertificatesIDs: certificatesSchema,
        perimeter: perimeterSchema,
        renewingType: z.nativeEnum(RenewingType),
      })
      .superRefine(({ renewingCertificatesIDs, renewingType }, ctx) => {
        if (renewingCertificatesIDs.length === 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Veuillez choisir au moins 1 certificat.",
            path: ["renewingCertificatesIDs"],
          });

          return;
        }

        if (
          [RenewingType.REDUCED, RenewingType.SAME].includes(renewingType) &&
          renewingCertificatesIDs.length > 1
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Veuillez choisir 1 certificat maximum.",
            path: ["renewingCertificatesIDs"],
          });
        }

        if (
          renewingType === RenewingType.FUSION &&
          renewingCertificatesIDs.length < 2
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: "Veuillez choisir au moins 2 certificats.",
            path: ["renewingCertificatesIDs"],
          });
        }
      }),
  }),
]);

type RegisterStepSchema = z.infer<typeof registerStepSchema>;

export { registerStepSchema };
export type { RegisterStepSchema };
