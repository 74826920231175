import { ExamStatus } from "@/api/enums/exam-status.enum";
import { useQueryExamQcmsByDatePaginated } from "@/api/qcm/qcm";
import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import SmartPagination from "@/components/molecules/SmartPagination";
import usePersistent from "@/hooks/use-persistent";
import { keepPreviousData } from "@tanstack/react-query";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import ExamQCMs from "./ExamQCMs";
import { QCMsTab } from "./qcms-tab.enum";

const tabToStatus = (tab: QCMsTab): ExamStatus[] => {
  switch (tab) {
    case QCMsTab.All:
      return [ExamStatus.QCMS_NOT_GENERATED, ExamStatus.QCMS_GENERATED];
    case QCMsTab.ToGenerate:
      return [ExamStatus.QCMS_NOT_GENERATED];
    case QCMsTab.Generated:
      return [ExamStatus.QCMS_GENERATED];
  }
};

const Qcms = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [tab, setTab] = usePersistent<QCMsTab>(
    "admin-dashboard-qcms-tab",
    QCMsTab.All
  );
  const [pageIndex, setPageIndex] = usePersistent(
    "admin-dashboard-qcms-pageIndex",
    0
  );

  const query = useQueryExamQcmsByDatePaginated(
    {
      pageIndex,
      searchText: debouncedSearchText,
      status: tabToStatus(tab),
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  return (
    <div className="flex flex-col bg-white h-screen">
      <div className="flex flex-col border-b border-b-gray-200 px-6 pt-3 gap-2 bg-white relative">
        <h1>QCM</h1>
        <Tabs onValueChange={(e) => setTab(e as QCMsTab)} value={tab}>
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={QCMsTab.All}>
              Tous
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={QCMsTab.ToGenerate}>
              À générer
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={QCMsTab.Generated}>
              Généré
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value as string)}
          placeholder="Rechercher"
        />
        <div className="flex flex-col">
          {!query.data ? (
            <LoadingSpinner
              isLoading={query.isLoading}
              isError={query.isError}
              loadingMessage="Chargement des QCMs..."
              errorMessage="Erreur lors du chargement des QCMs."
            />
          ) : (
            query.data.data.map(({ date, exams }, i) => (
              <ExamQCMs
                key={new Date(date).getTime()}
                index={i}
                total={query.data.data.length}
                data={{ date: new Date(date), exams }}
              />
            ))
          )}
        </div>
        <SmartPagination
          currentPage={pageIndex}
          onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
          totalPages={query.data?.totalPages ?? 0}
        />
      </div>
    </div>
  );
};

export default Qcms;
