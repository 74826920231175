import { FormationCertificateDtoClient } from "@/api/dto/formation-certificate.dto";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { useMutationFormationAttestationStatus } from "@/api/formation-attestation/formation-attestation";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import FileLink from "@/components/molecules/FileLink";
import { Card, CardTitle } from "@/components/molecules/RegistrationCard/Card";
import { Perimeter } from "@/lib/perimeter";
import { cn } from "@/lib/utils";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import AttestationStatusBadge from "./AttestationStatusBadge";
import FormationAttestationDialog from "./FormationAttestationDialog";

interface FormationAttestationCardProps {
  attestation: FormationCertificateDtoClient;
  candidateId: number;
  registrationId: number;
  examPerimeter: Perimeter;
  disabled?: boolean;
}

const FormationAttestationCard = ({
  attestation,
  candidateId,
  registrationId,
  examPerimeter,
  disabled = false,
}: FormationAttestationCardProps) => {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [attestationDialogOpen, setAttestationDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const statusMutation = useMutationFormationAttestationStatus();

  return (
    <>
      <ConfirmDialog
        isOpen={confirmDialogOpen}
        title={"Invalidation de l'attestation de formation"}
        cancelStr="Annuler"
        validateStr="Confirmer"
        onResult={(accepted) => {
          if (accepted) {
            statusMutation.mutate(
              {
                registrationId,
                attestationId: attestation.id,
                validated: false,
              },
              {
                onSuccess: () => {
                  toast.success("Attestation invalidée");
                  queryClient.invalidateQueries({
                    queryKey:
                      keyFactory.candidate.onGoingRegistration(candidateId),
                  });
                  setConfirmDialogOpen(false);
                },
                onError: () => {
                  toast.error("Échec de la requête", {
                    description:
                      "Votre action n'a pas pu être prise en compte.",
                  });
                },
              }
            );
          } else setConfirmDialogOpen(false);
        }}
      />
      <FormationAttestationDialog
        isOpen={attestationDialogOpen}
        close={() => setAttestationDialogOpen(false)}
        formationAttestation={attestation}
        candidateId={candidateId}
        registrationId={registrationId}
        examPerimeter={examPerimeter}
      />
      <Card>
        <div
          className={cn("flex flex-row justify-between w-full items-center", {
            "opacity-50": disabled,
          })}
        >
          <div className="flex flex-row gap-2 items-center">
            <CardTitle>{Perimeter.toCode(attestation.perimeter)}</CardTitle>
            <AttestationStatusBadge status={attestation.status} />
            <FileLink showName={false} file={attestation.certificate} />
          </div>
          {attestation.status === ValidationStatus.VALIDATED ? (
            <Button
              disabled={disabled}
              variant="ghostRed"
              onClick={() => setConfirmDialogOpen(true)}
            >
              Annuler
            </Button>
          ) : (
            <Button
              disabled={disabled}
              className="rounded-lg"
              onClick={() => setAttestationDialogOpen(true)}
            >
              Consulter
            </Button>
          )}
        </div>
      </Card>
    </>
  );
};

export default FormationAttestationCard;
