import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DocumentType } from "@/api/dto/document-type.enum";
import { keyFactory } from "@/api/keyFactory";
import { useMutationReducedPriceNewDocument } from "@/api/reduced-price/reduced-price";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import { emptyFile, file } from "@/constants/zodTypes";
import useFileUpload from "@/hooks/FileUpload";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  file: file(),
});

interface AddDocumentDialogProps {
  isOpen: boolean;
  close: () => void;
  registration: CandidateRegistrationDtoClient;
}

type FormType = z.infer<typeof schema>;

const AddDocumentDialog = ({
  isOpen,
  close,
  registration,
}: AddDocumentDialogProps) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const uploadMutation = useMutationReducedPriceNewDocument();
  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.REDUCED_PRICE_JUSTIFICATION
  );

  const form = useForm<FormType>({
    resolver: zodResolver(schema),
    defaultValues: {
      file: emptyFile(),
    },
  });

  const onSubmit = async (values: FormType) => {
    const { data, error, success } = schema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting :", error);
      return;
    }

    const [file] = await ensureUploaded(data.file);

    if (!file) {
      toast.error("Échec de la requête", {
        description: "Erreur lors de l'envoi du document",
      });
      return;
    }

    uploadMutation.mutate(
      {
        registrationId: registration.id,
        file,
      },
      {
        onSuccess: () => {
          toast.success("Document envoyé");
          queryClient.invalidateQueries({
            queryKey: keyFactory.candidate.onGoingRegistration(Number(id)),
          });
          close();
        },
        onError: () => {
          toast.error("Échec de la requête", {
            description: "Votre action n'a pas pu être prise en compte.",
          });
        },
      }
    );
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent onClose={close} className="w-96 p-4">
        <DialogHeader>
          <DialogTitle>Ajout de justiticatif</DialogTitle>
          <DialogDescription hidden={true}>
            Ajout de justificatif du tarif réduit
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="file"
              render={() => (
                <FormItem className="w-full">
                  <DropzoneMolecule />
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row w-full gap-4">
              <Button
                variant="red"
                onClick={close}
                type="button"
                className="w-1/2"
              >
                Annuler
              </Button>
              <Button
                variant="green"
                type="submit"
                className="w-1/2"
                disabled={uploadMutation.isPending}
              >
                {uploadIsPending
                  ? "Envoi du document..."
                  : uploadMutation.isPending
                  ? "En cours..."
                  : "Valider"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddDocumentDialog;
