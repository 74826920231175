import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { keyFactory } from "@/api/keyFactory";
import { useMutationReducedPriceStatus } from "@/api/reduced-price/reduced-price";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import FileLink from "@/components/molecules/FileLink";
import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { toast } from "sonner";

interface ConsultDocumentDialogProps {
  isOpen: boolean;
  close: () => void;
  registration: CandidateRegistrationDtoClient;
}

const ConsultDocumentDialog = ({
  isOpen,
  close,
  registration,
}: ConsultDocumentDialogProps) => {
  const { id } = useParams();
  const queryClient = useQueryClient();

  const statusMutation = useMutationReducedPriceStatus();

  const { reducedPriceDocument } = registration;

  const onError = () => {
    toast.error("Échec de la requête", {
      description: "Votre action n'a pas pu être prise en compte.",
    });
  };

  const onSuccess = (validated: boolean) => {
    toast.success(validated ? "Document validé" : "Document refusé");
    queryClient.invalidateQueries({
      queryKey: keyFactory.candidate.onGoingRegistration(Number(id)),
    });
    close();
  };

  if (!reducedPriceDocument) return false;
  if (!id) return false;

  return (
    <Dialog open={isOpen}>
      <DialogContent onClose={close} className="w-96 p-4">
        <DialogHeader>
          <DialogTitle>Validation du tarif réduit</DialogTitle>
          <DialogDescription hidden={true}>
            Validation du justificatif de tarif réduit
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4">
          <FileLink
            variant="secondary"
            file={reducedPriceDocument}
            outerBox={true}
          />
          <div className="flex flex-row gap-4">
            <Button
              onClick={() =>
                statusMutation.mutate(
                  {
                    registrationId: registration.id,
                    validated: false,
                  },
                  {
                    onError,
                    onSuccess: () => onSuccess(false),
                  }
                )
              }
              variant="red"
              className="w-1/2"
            >
              Refuser
            </Button>
            <Button
              onClick={() =>
                statusMutation.mutate(
                  {
                    registrationId: registration.id,
                    validated: true,
                  },
                  {
                    onError,
                    onSuccess: () => onSuccess(true),
                  }
                )
              }
              variant="green"
              className="w-1/2"
              disabled={statusMutation.isPending}
            >
              {statusMutation.isPending ? "En cours..." : "Valider"}
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConsultDocumentDialog;
