import { Button } from "@/components/atoms/Button";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/atoms/Collapsible";
import CopyableInput from "@/components/molecules/CopyableInput";
import { ChevronDown, ChevronUp } from "lucide-react";

interface TransferInfoProps {
  candidateFirstName: string;
  candidateLastName: string;
  price?: number;
  isOpen: boolean;
  setOpen: (b: boolean) => void;
  registrationEndDate: Date;
}

const TransferInfo = ({
  candidateFirstName,
  candidateLastName,
  price,
  isOpen,
  setOpen,
  registrationEndDate,
}: TransferInfoProps) => {
  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setOpen}
      className="w-full space-y-2"
    >
      <CollapsibleTrigger asChild className="w-full">
        <Button variant="blueLink" className="gap-2">
          <span className="font-medium">
            Je préfère payer via un virement bancaire
          </span>
          {isOpen ? (
            <ChevronUp className="h-5 w-5" />
          ) : (
            <ChevronDown className="h-5 w-5" />
          )}
          <span className="sr-only">Toggle</span>
        </Button>
      </CollapsibleTrigger>

      <CollapsibleContent>
        <div className="w-full text-gray-600 font-normal text-xs mb-2">
          Privilégiez le paiement par carte pour que votre candidat soit
          instantanément inscrit par nos équipes. Voici les informations
          bancaires à entrer pour effectuer votre virement. Le virement doit
          être effectué avant la date de clôture des inscriptions :{" "}
          <span className="font-semibold">
            {new Date(registrationEndDate).toLocaleDateString("fr-FR")}
          </span>
          . Le paiement par chèque n’est pas accepté.
        </div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col w-full gap-2">
            <div className="flex flex-row gap-2">
              <div className="flex flex-col gap-1 w-1/2">
                Bénéficiaire
                <CopyableInput text="CIFMD" />
              </div>
              <div className="flex flex-col gap-1 w-1/2">
                Banque
                <CopyableInput text="CREDIT INDUSTRIEL ET COMMERCIAL" />
              </div>
            </div>
            <div className="flex flex-col gap-1">
              BIC
              <CopyableInput text="-" />
            </div>
            <div className="flex flex-col gap-1">
              IBAN
              <CopyableInput text="FR76 3006 6104 5100 0104 2540 144" />
            </div>
            <div className="flex flex-col gap-1">
              À renseigner sur votre virement
              <CopyableInput
                text={`${candidateLastName} ${candidateFirstName}`}
              />
            </div>
            <div className="flex flex-col gap-1">
              Montant
              <CopyableInput text={price?.toString() ?? "Chargement..."} />
            </div>
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
};

export default TransferInfo;
