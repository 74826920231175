import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { TransportMode } from "@/api/enums/transport-mode.enum";
import { Checkbox } from "@/components/atoms/CheckBox";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/atoms/Sheet";
import { Perimeter } from "@/lib/perimeter";
import YearSetter from "./YearsSetter";

interface FilterSheetProps {
  perimeter: Perimeter;
  setPerimeter: (perimeter: Perimeter) => void;
  possibleValidationYears: Date[];
  validationYears: Date[];
  setValidationYears: (validationYears: Date[]) => void;
  possibleDeactivationYears: Date[];
  deactivationYears: Date[];
  setDeactivationYears: (deactivationYears: Date[]) => void;
  possibleActivationYears: Date[];
  activationYears: Date[];
  setActivationYears: (activationYears: Date[]) => void;
}

const FilterSheet = ({
  activationYears,
  deactivationYears,
  perimeter,
  possibleActivationYears,
  possibleDeactivationYears,
  possibleValidationYears,
  validationYears,
  setValidationYears,
  setActivationYears,
  setDeactivationYears,
  setPerimeter,
}: FilterSheetProps) => {
  return (
    <Sheet>
      <SheetTrigger className="border-gray-200 border rounded-lg px-3 py-2 text-gray-800">
        Filtrer
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-4 overflow-auto">
        <SheetHeader>
          <SheetTitle>Filtrer</SheetTitle>
          <SheetDescription hidden={true}>Filtres</SheetDescription>
        </SheetHeader>
        <div className="flex flex-col gap-2">
          <span>Mode</span>
          <div className="grid grid-cols-2 gap-4">
            {[
              {
                value: [TransportMode.ROAD],
                label: "R",
              },
              {
                value: [TransportMode.BOAT],
                label: "N",
              },
              {
                value: [TransportMode.TRAIN],
                label: "F",
              },
              {
                value: Object.values(TransportMode),
                label: "T",
              },
            ].map(({ label, value }) => {
              const checked =
                value.every((m) => perimeter.transportModes.includes(m)) &&
                perimeter.transportModes.every((m) => value.includes(m));

              const id = `mode-${label}`;

              return (
                <div className="flex flex-row items-center gap-2" key={label}>
                  <Checkbox
                    checked={checked}
                    onClick={() =>
                      setPerimeter({
                        ...perimeter,
                        transportModes: checked ? [] : value,
                      })
                    }
                    id={id}
                  />
                  <label htmlFor={id}>{label}</label>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <span>Classe</span>
          <div className="grid grid-cols-2 gap-4">
            {[
              {
                value: [MerchandiseClass.EXPLOSIVES],
                label: "1",
              },
              {
                value: [MerchandiseClass.CHEMICAL],
                label: "C",
              },
              {
                value: [MerchandiseClass.GAS],
                label: "2",
              },
              {
                value: [MerchandiseClass.HYDROCARBONS],
                label: "H",
              },
              {
                value: [MerchandiseClass.RADIOACTIVE],
                label: "7",
              },
              {
                value: Object.values(MerchandiseClass),
                label: "B",
              },
            ].map(({ label, value }) => {
              const checked =
                value.every((m) => perimeter.merchandises.includes(m)) &&
                perimeter.merchandises.every((m) => value.includes(m));
              const id = `mode-${label}`;

              return (
                <div className="flex flex-row items-center gap-2" key={label}>
                  <Checkbox
                    checked={checked}
                    onClick={() =>
                      setPerimeter({
                        ...perimeter,
                        merchandises: checked ? [] : value,
                      })
                    }
                    id={id}
                  />
                  <label htmlFor={id}>{label}</label>
                </div>
              );
            })}
          </div>
        </div>
        <YearSetter
          title="Année de validation"
          possibleYears={possibleValidationYears}
          years={validationYears}
          setYears={setValidationYears}
        />
        <YearSetter
          title="Année de désactivation"
          possibleYears={possibleDeactivationYears}
          years={deactivationYears}
          setYears={setDeactivationYears}
        />
        <YearSetter
          title="Année d'activation"
          possibleYears={possibleActivationYears}
          years={activationYears}
          setYears={setActivationYears}
        />
      </SheetContent>
    </Sheet>
  );
};

export default FilterSheet;
