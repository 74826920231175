import { ExamQuestionStateDtoClient } from "@/api/live-exam/dto/exam.dto";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/atoms/Collapsible";
import { Progress } from "@/components/atoms/Progress";
import { ScrollArea } from "@/components/atoms/ScrollArea";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import {
  ColumnPerimeter,
  ColumnPerimeterUtil,
} from "@/constants/column-perimeter.enum";
import { Perimeter } from "@/lib/perimeter";
import { cn } from "@/lib/utils";
import { CheckIcon, ChevronRight } from "lucide-react";
import { useMemo } from "react";

interface SidebarProps {
  exam: ExamQuestionStateDtoClient[];
  jumpToQuestion: (questionId: number) => void;
  setPerimeter: React.Dispatch<React.SetStateAction<string>>;
  perimeter: string;
  displayWaiting: boolean;
  setDisplayWaiting: (displayWaiting: boolean) => void;
}

const ProgressBar = ({
  exam,
  jumpToQuestion,
  setPerimeter,
  perimeter,
  displayWaiting,
  setDisplayWaiting,
}: SidebarProps) => {
  const questionsByPerimeter = useMemo(() => {
    if (!exam) return [];

    const d: Record<string, ExamQuestionStateDtoClient[]> = {};
    exam.forEach(({ question, answerOrder }) => {
      const key = Perimeter.toCode(question.perimeter);
      if (!d[key]) d[key] = [];

      d[key].push({ question, answerOrder });
    });

    const columnPerimeters = ColumnPerimeterUtil.sorted().map((p) =>
      ColumnPerimeterUtil.toNormalCode(p as ColumnPerimeter)
    );

    // Sort by column perimeter.
    const sorted = [...Object.entries(d)].sort(
      ([aPerimeter], [bPerimeter]) =>
        columnPerimeters.indexOf(aPerimeter as ColumnPerimeter) -
        columnPerimeters.indexOf(bPerimeter as ColumnPerimeter)
    );

    return sorted;
  }, [exam]);

  const totalAnswered = exam.filter(
    ({ answerOrder }) => answerOrder !== undefined
  ).length;

  return (
    <div className="flex flex-col w-full bg-white border-gray-200 rounded-lg py-4 border max-w-sm">
      <Tabs
        value={String(displayWaiting)}
        onValueChange={(t) => setDisplayWaiting(t === "true")}
        className="px-4 pb-2"
      >
        <TabsList className="p-0 w-full">
          {[
            {
              text: "Toutes les questions",
              value: false,
            },
            {
              text: "Mises en attente",
              value: true,
            },
          ].map(({ text, value }) => (
            <TabsTrigger
              value={String(value)}
              className="flex-grow m-0.5 py-1.5 items-center justify-center"
              key={String(value)}
            >
              {text}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <div className="flex flex-row items-center gap-3 min-w-36 pb-2 px-4">
        <Progress
          value={(totalAnswered / exam.length) * 100}
          className="h-2 bg-gray-200"
          indicatorClassName="bg-purple-600"
        />
        <span className="whitespace-nowrap">
          {totalAnswered} / {exam.length}
        </span>
      </div>
      <ScrollArea className="overflow-y-auto px-4">
        {questionsByPerimeter.map(([sectionPerimeter, questions], i) => {
          const answeredCount = questions.filter(
            ({ answerOrder }) => answerOrder !== undefined
          ).length;

          const open = !displayWaiting && perimeter === sectionPerimeter;

          return (
            <Collapsible
              key={i}
              open={open}
              onOpenChange={() => setPerimeter(sectionPerimeter)}
              className={cn({
                "border-x border-gray-200": open,
                "bg-gray-50": open,
              })}
              disabled={displayWaiting}
            >
              <CollapsibleTrigger className="flex flex-row items-center justify-between px-2 py-3.5 w-full border-t border-t-gray-200 disabled:bg-gray-50 disabled:cursor-not-allowed disabled:opacity-20 ">
                <span className="font-extrabold whitespace-nowrap">
                  {Perimeter.toExamText(sectionPerimeter)}
                </span>
                <div className="flex flex-row gap-2 items-center">
                  <div
                    className={cn("rounded-full w-2 h-2", {
                      "bg-green-500": answeredCount === questions.length,
                      "bg-yellow-500":
                        answeredCount > 0 && answeredCount < questions.length,
                      "bg-red-500": answeredCount === 0,
                    })}
                  />
                  <span>
                    {answeredCount}/{questions.length}
                  </span>
                  <span
                    className={cn("transition-transform duration-200", {
                      "rotate-90": open,
                      "rotate-0": !open,
                    })}
                  >
                    <ChevronRight className="w-4 h-4" />
                  </span>
                </div>
              </CollapsibleTrigger>
              <CollapsibleContent>
                {questions.map(({ answerOrder, question }, i) => (
                  <button
                    key={i}
                    className="flex flex-row items-center justify-between px-2 py-1.5 w-full hover:bg-gray-100"
                    onClick={() => {
                      jumpToQuestion(question.id);
                      setPerimeter(sectionPerimeter);
                    }}
                  >
                    <span>Question {i + 1}</span>
                    <CheckIcon
                      className={cn("w-4 h-4 text-white  rounded-full p-0.5", {
                        "bg-brand-600": answerOrder !== undefined,
                        "bg-gray-300": answerOrder === undefined,
                      })}
                    />
                  </button>
                ))}
              </CollapsibleContent>
            </Collapsible>
          );
        })}
      </ScrollArea>
    </div>
  );
};

export default ProgressBar;
