import { useMutationExamResultGenerateDocuments } from "@/api/exam-result/exam-result";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { useQueryClient } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface GenerateNotesDialogProps {
  examId: number;
}

const GenerateNotesDialog = ({ examId }: GenerateNotesDialogProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const { isPending, mutate } = useMutationExamResultGenerateDocuments({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.exam.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.registration.base(),
      });

      setOpen(false);
      toast.success("Relevés de notes générés");
    },
    onError: () => {
      setOpen(false);
      toast.error("Erreur", {
        description: "Les relevés de notes n'ont pas pu être générés",
      });
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="flex flex-row items-center gap-2">
          <Check className="rounded-full w-5 h-5 bg-white text-brand-600 p-0.5" />
          Générer les relevés de notes
        </Button>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-6 p-4"
      >
        <DialogHeader>
          <DialogTitle>Générer les relevés de notes</DialogTitle>
          <DialogDescription hidden={true}>
            Génération des relevés de notes
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row gap-2 w-full">
          <Button
            disabled={isPending}
            variant="ghostWithBorders"
            onClick={() => setOpen(false)}
            className="flex-grow"
          >
            Annuler
          </Button>
          <Button
            onClick={() => mutate(examId)}
            disabled={isPending}
            className="flex-grow"
          >
            {isPending ? "En cours..." : "Générer"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GenerateNotesDialog;
