import { useMutationCertificateAddProrogation } from "@/api/certificate/certificate";
import { CertificateDtoClient } from "@/api/dto/certificate.dto";
import { DocumentType } from "@/api/dto/document-type.enum";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import DatePicker from "@/components/molecules/DatePicker";
import DropzoneMolecule from "@/components/molecules/Dropzone";
import {
  date,
  emptyFile as emptyFile,
  file,
  nullDate,
} from "@/constants/zodTypes";
import useFileUpload from "@/hooks/FileUpload";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const prorogationSchema = z.object({
  newExpirationDate: date(new Date()),
  prorogationDocument: file(),
});

type ProrogationSchema = z.infer<typeof prorogationSchema>;

interface AddProrogationDialogProps {
  close: () => void;
  candidateId: number;
  certificate?: CertificateDtoClient;
}

const AddProrogationDialog = ({
  close,
  candidateId,
  certificate,
}: AddProrogationDialogProps) => {
  const queryClient = useQueryClient();
  const prorogationAddMutation = useMutationCertificateAddProrogation();
  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.PROROGATION
  );

  const form = useForm<ProrogationSchema>({
    resolver: zodResolver(prorogationSchema),
    defaultValues: {
      newExpirationDate: nullDate(),
      prorogationDocument: emptyFile(),
    },
  });

  const onSubmit = async (values: ProrogationSchema) => {
    if (!certificate) return;

    const { data, error, success } = prorogationSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting :", error);
      return;
    }

    const { newExpirationDate, prorogationDocument } = data;
    const [file] = await ensureUploaded(prorogationDocument);

    if (!file) {
      toast.error("Prorogation non envoyée", {
        description: "Erreur lors de l'envoi du document",
      });
      return;
    }

    prorogationAddMutation.mutate(
      {
        certificateId: certificate.id,
        newExpirationDate,
        prorogationDocument: file,
      },
      {
        onSuccess: () => {
          toast.success("Prorogation envoyée");
          queryClient.invalidateQueries({
            queryKey: keyFactory.certificate.all(candidateId),
          });
          form.reset();
          close();
        },
        onError: () => {
          toast.error("Prorogation non envoyée", {
            description: "Erreur lors de l'envoi de la prorogation",
          });
        },
      }
    );
  };

  if (!certificate) return false;

  return (
    <Dialog open={certificate !== undefined}>
      <DialogContent onClose={close} className="w-96 p-4">
        <DialogHeader>
          <DialogTitle>Prorogation</DialogTitle>
          <DialogDescription hidden={true}>
            Ajout de prorogation
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="prorogationDocument"
              render={() => (
                <FormItem className="w-full">
                  <DropzoneMolecule />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="newExpirationDate"
              render={() => (
                <FormItem className="w-full">
                  <DatePicker
                    placeholder="Date de prorogation"
                    disabledDates={(date) =>
                      date < new Date() ||
                      date < new Date(certificate.expirationDate)
                    }
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="flex flex-row w-full gap-4 mt-4">
              <Button
                variant="red"
                onClick={close}
                type="button"
                className="w-1/2"
                disabled={uploadIsPending || prorogationAddMutation.isPending}
              >
                Annuler
              </Button>
              <Button
                variant="green"
                type="submit"
                className="w-1/2"
                disabled={uploadIsPending || prorogationAddMutation.isPending}
              >
                {uploadIsPending
                  ? "Envoi du document..."
                  : prorogationAddMutation.isPending
                  ? "En cours..."
                  : "Valider"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddProrogationDialog;
