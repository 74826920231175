import { useQueryCandidateAllPaginated } from "@/api/candidate/candidate";
import { CandidateStatus } from "@/api/candidate/dto/candidate-status.enum";
import { CandidateBasicDtoClient } from "@/api/candidate/dto/candidate.dto";
import { Badge } from "@/components/atoms/Badge";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { CandidatesTab } from "../candidates-tab.enum";
import StatusBadge from "./StatusBadge";

interface CandidatesTableProps {
  tab: CandidatesTab;
  searchText?: string;
}

const candidatesTabsStatuses = {
  [CandidatesTab.All]: undefined,
  [CandidatesTab.Valid]: CandidateStatus.Valid,
  [CandidatesTab.Expired]: CandidateStatus.Expired,
  [CandidatesTab.OnGoing]: CandidateStatus.OnGoing,
};

const columnHelper = createColumnHelper<CandidateBasicDtoClient>();

const CandidatesTable = ({ tab, searchText }: CandidatesTableProps) => {
  const [pageIndexes, setPageIndexes] = usePersistent("candidates-page-index", {
    [CandidatesTab.All]: 0,
    [CandidatesTab.Valid]: 0,
    [CandidatesTab.Expired]: 0,
    [CandidatesTab.OnGoing]: 0,
  });
  const status = useMemo<CandidateStatus | undefined>(
    () => candidatesTabsStatuses[tab],
    [tab]
  );
  const navigate = useNavigate();

  const { isError, data: queryData } = useQueryCandidateAllPaginated(
    {
      pageIndex: pageIndexes[tab] ?? 0,
      status,
      searchText,
    },
    { placeholderData: keepPreviousData }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor(
        ({ firstName, lastName }) => firstName + " " + lastName,
        {
          id: "name",
          header: "Candidat",
          cell: (info) => (
            <span className="text-gray-900 font-medium whitespace-nowrap">
              {info.getValue()}
            </span>
          ),
        }
      ),
      columnHelper.accessor("email", {
        id: "email",
        header: "Adresse email",
        cell: (info) => info.getValue(),
      }),
      columnHelper.accessor("lastCertificate", {
        id: "lastCertificatePerimeter",
        header: "Pér.",
        cell: (info) => {
          const certificate = info.getValue();
          if (!certificate) return <></>;
          return (
            <Badge variant="white">
              {Perimeter.toCode(certificate.perimeter)}
            </Badge>
          );
        },
      }),
      columnHelper.accessor((row) => row, {
        id: "status",
        header: "Statut",
        cell: (info) => <StatusBadge basicInfo={info.getValue()} />,
      }),
      columnHelper.accessor("lastCertificate", {
        id: "lastCertificateNumber",
        header: "N° certificat",
        cell: (info) => {
          const certificate = info.getValue();
          if (!certificate) return <></>;
          return certificate.number;
        },
      }),
      columnHelper.accessor("lastCertificate", {
        id: "lastCertificateValidityEndDate",
        header: "Fin de validité",
        cell: (info) => {
          const certificate = info.getValue();
          if (!certificate) return <></>;
          return new Date(certificate.expirationDate).toLocaleDateString(
            "fr-FR"
          );
        },
      }),
    ],
    []
  );

  const [rows, is_placeholder] = useTablePlaceholderRows(queryData);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!queryData)
    return (
      <LoadingSpinner
        isError={isError}
        isLoading={false}
        errorMessage="Erreur lors du chargement des candidats."
      />
    );

  const { totalPages } = queryData;

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.candidate.root(row.original.id)
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndexes[tab]}
        onPageChange={(newPageIndex) =>
          setPageIndexes({ ...pageIndexes, [tab]: newPageIndex })
        }
        totalPages={totalPages}
      />
    </div>
  );
};

export default CandidatesTable;
