import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { PayementUtil } from "@/lib/payement-util";
import { RegistrationUtil } from "@/lib/registration-util";
import { Info } from "lucide-react";

interface TopMessageProps {
  registration: CandidateRegistrationDtoClient;
}

const WaitingDelegationPayement = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-red-500 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Vous êtes pré-inscrit
        </span>
        <span className="text-gray-600">
          Nous sommes en attente du paiement de la part de votre délégataire
          désigné. Le paiement doit avoir lieu avant la date de clôture des
          inscriptions : aucune dérogation n'est accordée. N'hésitez pas à
          relancer votre délégataire si nécessaire.
        </span>
      </div>
    </div>
  );
};

const WaitingPersonalPayement = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-red-500 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Vous êtes pré-inscrit
        </span>
        <span className="text-gray-600">
          Votre règlement doit être effectué avant la date de clôture des
          inscriptions
        </span>
      </div>
    </div>
  );
};

const WaitingReducedPriceDocumentValidation = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-red-500 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Vous êtes pré-inscrit
        </span>
        <span className="text-gray-600">
          Votre règlement doit être effectué avant la date de clôture des
          inscriptions. Vous pourrez payer lorsque le justificatif de tarif
          réduit sera validé.
        </span>
      </div>
    </div>
  );
};

const WaitingFormationAttestationUpload = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-red-500 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Attestation non encore déposée
        </span>
        <span className="text-gray-600">
          En attente du ou des justificatif(s)
        </span>
      </div>
    </div>
  );
};

const WaitingFormationAttestationValidation = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-red-500 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="text-gray-800 font-semibold">
          Attestation(s) déposée(s) à vérifier
        </span>
        <span className="text-gray-600">En attente de validation</span>
      </div>
    </div>
  );
};

const InvalidFormationAttestation = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-red-500 flex-shrink-0" />
      <span className="text-gray-800 font-semibold">
        Attestation déposée invalide ou incomplète
      </span>
    </div>
  );
};

const WaitingPreTestValidation = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-red-500 flex-shrink-0" />
      <span className="text-gray-800 font-semibold">
        Le test préalable n'est pas encore validé.
      </span>
    </div>
  );
};

const AllGood = () => {
  return (
    <div className="flex flex-row gap-3 items-center shadow-sm rounded-lg px-2 py-2">
      <Info className="w-4 h-4 text-green-600 flex-shrink-0" />
      <span className="text-gray-800 font-semibold">
        Tous les pré-requis sont validés, votre convocation vous a été envoyée
      </span>
    </div>
  );
};

const TopMessage = ({ registration }: TopMessageProps) => {
  const {
    reducedPriceStatus,
    delegation,
    formationCertificatesStatus,
    allAttestationsProvided,
    preTestStatus,
  } = registration;

  if (
    delegation &&
    [
      DelegationStatus.WAITING_ACCEPTATION,
      DelegationStatus.WAITING_ACCOUNT_CREATION,
    ].includes(delegation.status)
  )
    return <WaitingDelegationPayement />;

  if (reducedPriceStatus === ValidationStatus.PENDING)
    return <WaitingReducedPriceDocumentValidation />;

  if (!PayementUtil.isPaid(registration.payementStatus))
    return <WaitingPersonalPayement />;

  if (!allAttestationsProvided) return <WaitingFormationAttestationUpload />;

  if (formationCertificatesStatus === ValidationStatus.PENDING)
    return <WaitingFormationAttestationValidation />;

  if (formationCertificatesStatus === ValidationStatus.REFUSED)
    return <InvalidFormationAttestation />;

  if (preTestStatus === ValidationStatus.PENDING)
    return <WaitingPreTestValidation />;

  if (RegistrationUtil.isCompleted(registration)) return <AllGood />;
};

export default TopMessage;
