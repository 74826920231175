import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Badge } from "@/components/atoms/Badge";
import GreenCheck from "@/components/atoms/GreenCheck";
import RedCross from "@/components/atoms/RedCross";

interface ReducedPriceStatusBadgeProps {
  status: ValidationStatus;
}

const ReducedPriceStatusBadge = ({ status }: ReducedPriceStatusBadgeProps) => {
  switch (status) {
    case ValidationStatus.PENDING:
      return <Badge variant="red">À valider</Badge>;
    case ValidationStatus.UNAVAILABLE:
      return false;
    case ValidationStatus.VALIDATED:
      return <GreenCheck />;
    case ValidationStatus.REFUSED:
      return <RedCross />;
  }
};

export default ReducedPriceStatusBadge;
