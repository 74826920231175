import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "sonner";

const Exam = () => {
  const navigate = useNavigate();

  // disconnect  socket
  useEffect(() => {
    return () => {
      socket.disconnect();
    };
  }, []);

  // add listeners
  useEffect(() => {
    // exam finished
    socket.on("examFinished", () => {
      toast.success("Examen terminé", {
        description: "Les administrateurs ont clôturé l'examen.",
      });
      navigate(ROUTE.exam.done());
    });

    // additional time
    socket.on("additionalTime", (minutes: number) => {
      toast.success("Temps supplémentaire", {
        description: `Votre temps supplémentaire est fixé à ${minutes} minutes.`,
      });
    });

    return () => {
      socket.off("examFinished");
    };
  }, [navigate]);

  return <Outlet />;
};

export default Exam;
