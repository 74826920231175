import { ENDPOINT } from "@/constants/endpoints";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ExportCopiesDto } from "./dto/export-copies.dto";

const downloadFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
};

const copies = async (body: ExportCopiesDto) =>
  await axios
    .post<Blob>(ENDPOINT.export.postCopies(), body, { responseType: "blob" })
    .then((res) =>
      downloadFile(
        res.data,
        `copies-${body.global ? "globales" : "partielles"}.zip`
      )
    );

export const useMutationExportCopies = (
  props?: UseMutationOptions<void, AxiosError, ExportCopiesDto>
) =>
  useMutation({
    mutationFn: copies,
    ...props,
  });
