import { ResultsTab } from "@/api/exam/dto/results-tab.enum";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";

interface ResultsFilterTabsProps {
  tab: ResultsTab;
  setTab: (tab: ResultsTab) => void;
}

const ResultsFilterTabs = ({ tab, setTab }: ResultsFilterTabsProps) => {
  return (
    <Tabs onValueChange={(e) => setTab(e as ResultsTab)} value={tab}>
      <TabsList
        variant="transparent"
        className="pb-0 mb-0 border-b border-b-gray-200 rounded-none"
      >
        {[
          {
            name: "Tous",
            tab: ResultsTab.All,
          },
          {
            name: "Admis",
            tab: ResultsTab.Passed,
          },
          {
            name: "Ajourné",
            tab: ResultsTab.Refused,
          },
          {
            name: "Absent",
            tab: ResultsTab.Missing,
          },
          {
            name: "Reporté",
            tab: ResultsTab.Reported,
          },
        ].map(({ name, tab }) => (
          <TabsTrigger variant="underlineBlue" value={tab} key={tab}>
            {name}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default ResultsFilterTabs;
