import { cn } from "@/lib/utils";

const NoteDisplay = ({
  grade,
  gradeTotal,
  passed,
}: {
  grade: number | string;
  gradeTotal: number;
  passed: boolean;
}) => {
  return (
    <span
      className={cn("font-bold whitespace-nowrap", {
        "text-red-600": !passed,
        "text-green-600": passed,
      })}
    >
      {grade} / {gradeTotal}
    </span>
  );
};

export default NoteDisplay;
