import { Role } from "@/api/enums/Role";
import { ROUTE } from "@/constants/routes";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "./AuthStore";

const useRoleBasedRedirect = (neededRole: Role | null = null) => {
  const { session, logout } = useAuthStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!session && neededRole === null) return;
    if (session && session.role === neededRole) return;

    if (session) {
      switch (session.role) {
        case Role.ADMIN:
          navigate(ROUTE.admin.dashboard.root());
          break;
        case Role.CANDIDATE:
          navigate(ROUTE.candidate.dashboard.root());
          break;
        case Role.COMPANY:
          navigate(ROUTE.company.dashboard.root());
          break;
        default:
          console.error("Incoherent session state : " + session);
          logout();
          break;
      }
    } else {
      switch (neededRole) {
        case Role.ADMIN:
          navigate(ROUTE.admin.login());
          break;
        case Role.CANDIDATE:
          navigate(ROUTE.connectionHome());
          break;
        case Role.COMPANY:
          navigate(ROUTE.connectionHome());
          break;
        default:
          console.error("Incoherent session state : " + session);
          logout();
          break;
      }
    }
  }, [session, neededRole, navigate, logout]);
};

export default useRoleBasedRedirect;
