import logo from "@/assets/logo.png";
import { useAuthStore } from "@/hooks/AuthStore";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import { LogOut, PanelLeft } from "lucide-react";
import { ReactNode, useState } from "react";
import DisconnectPrompt from "./DisconnectPrompt";

interface SideBarProps {
  collapsedByDefault?: boolean;
  collapsable?: boolean;
  expandedChildren?: ReactNode;
  collapsedChildren?: ReactNode;
}
const SideBar = ({
  collapsable = true,
  collapsedByDefault = false,
  expandedChildren,
  collapsedChildren,
}: SideBarProps) => {
  const { session } = useAuthStore();

  const [collapsed, setCollapsed] = usePersistent(
    `sidebar-collapsed-${session?.role}`,
    collapsedByDefault
  );
  const [disconnectIsOpen, setDisconnectIsOpen] = useState(false);

  return (
    <>
      <DisconnectPrompt
        isOpen={disconnectIsOpen}
        close={() => setDisconnectIsOpen(false)}
      />
      <div
        className={cn(
          "flex flex-col sticky top-0 flex-start justify-between bg-brand-25 border-r border-r-gray-200 gap-8 pt-8 pb-6 h-screen transition-all duration-300",
          {
            "w-72 px-4": !collapsed,
            "w-16 items-center": collapsed,
          }
        )}
      >
        <div className="flex flex-col gap-4">
          <div
            className={cn("flex pl-4 pr-4  items-center self-stretch", {
              "flex-row justify-between": !collapsed,
              "flex-col gap-4": collapsed,
            })}
          >
            <img
              className={cn({
                "w-16 h-16": !collapsed,
                "w-8 h-8": collapsed,
              })}
              src={logo}
              alt="Description of the image"
            />
            {collapsable && (
              <button
                onClick={() => setCollapsed(!collapsed)}
                className="text-gray-600 hover:text-gray-500 rounded-md transition-colors duration-200"
              >
                <PanelLeft className="w-6 h-6" />
              </button>
            )}
          </div>
          <div className="flex flex-col px-2 items-center">
            {collapsed ? collapsedChildren : expandedChildren}
          </div>
        </div>
        <div className="flex flex-col px-2 gap-4 text-gray-700">
          {/* <button
            className="flex flex-row gap-3 items-center justify-start self-stretch"
            onClick={() => setDisconnectIsOpen(true)}
          >
            <User2 />
            Administrateurs
          </button> */}
          <button
            className="flex flex-row gap-3 items-center justify-start self-stretch"
            onClick={() => setDisconnectIsOpen(true)}
          >
            <LogOut className="w-5 h-5 flex-shrink-0" />
            {!collapsed && <span>Se déconnecter</span>}
          </button>
        </div>
      </div>
    </>
  );
};

export default SideBar;
