import { ExamRegistrationStatus } from "@/api/enums/exam-registration-status.enum";
import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import ExamPerimetersSheet from "./ExamPerimetersSheet";
import { RegistrationsTab } from "./registrations-tab.enum";
import RegistrationsTable from "./RegistrationsTable/RegistrationsTable";
import SessionFilterSheet from "./SessionFilterSheet";

const tabToStatus = {
  [RegistrationsTab.All]: [
    ExamRegistrationStatus.UNCOMPLETE,
    ExamRegistrationStatus.COMPLETE,
  ],
  [RegistrationsTab.INCOMPLETE]: [ExamRegistrationStatus.UNCOMPLETE],
  [RegistrationsTab.COMPLETE]: [ExamRegistrationStatus.COMPLETE],
};

const Registrations = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [tab, setTab] = usePersistent<RegistrationsTab>(
    "admin-dashboard-registrations-tab",
    RegistrationsTab.All
  );
  const [examFilterSheetOpen, setExamFilterSheetOpen] = useState(false);
  const [examIds, setExamIds] = usePersistent<number[]>(
    "admin-registrations-examIds",
    []
  );

  return (
    <div className="flex flex-col bg-white h-screen">
      <SessionFilterSheet
        isOpen={examFilterSheetOpen}
        setOpen={setExamFilterSheetOpen}
        setSelectedExamIds={setExamIds}
        selectedExamIds={examIds}
      />
      <div className="flex flex-col border-b border-b-gray-200 px-6 pt-3 gap-2 bg-white relative">
        <h1>Inscriptions</h1>
        <Tabs onValueChange={(e) => setTab(e as RegistrationsTab)} value={tab}>
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={RegistrationsTab.All}>
              Toutes
            </TabsTrigger>
            <TabsTrigger
              variant="underlineBlue"
              value={RegistrationsTab.INCOMPLETE}
            >
              En cours
            </TabsTrigger>
            <TabsTrigger
              variant="underlineBlue"
              value={RegistrationsTab.COMPLETE}
            >
              Finalisées
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <div className="flex flex-row gap-3 items-center">
          <Input
            className="shadow-none"
            id="shadcn-input"
            searchIcon
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value as string)}
            placeholder="Rechercher"
          />
          <ExamPerimetersSheet />
        </div>
        <RegistrationsTable
          openExamFilter={() => setExamFilterSheetOpen(true)}
          status={tabToStatus[tab]}
          searchText={debouncedSearchText}
          examIds={examIds}
        />
      </div>
    </div>
  );
};

export default Registrations;
