import { PaginationResponseDto } from "@/api/dto/pagination.dto";
import {
  ExamLiveCandidateDtoClient,
  ExamLiveDtoClient,
} from "@/api/live-exam/dto/exam-live.dto";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { cn } from "@/lib/utils";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { PartialDeep } from "type-fest";
import { useDebounce } from "use-debounce";
import { columns } from "./Columns";

interface CandidatesTableProps {
  exam: ExamLiveDtoClient;
  searchText?: string;
  perimeter?: Perimeter;
}

const CandidatesTable = ({
  exam,
  searchText,
  perimeter,
}: CandidatesTableProps) => {
  const navigate = useNavigate();
  const [data, setData] =
    useState<PaginationResponseDto<ExamLiveCandidateDtoClient>>();
  const [pageIndex, setPageIndex] = usePersistent(
    `live-session-candidates-pageIndex-${exam.id}`,
    0
  );
  const [debouncedSearchText] = useDebounce(searchText, 300);

  // query paginated query
  useEffect(() => {
    socket.emit(
      "getExamCandidates",
      {
        pageIndex,
        examId: exam.id,
        perimeter: perimeter?.toDto(),
        searchText: debouncedSearchText,
      },
      (data: PaginationResponseDto<ExamLiveCandidateDtoClient>) => setData(data)
    );
  }, [pageIndex, debouncedSearchText, exam.id, perimeter]);

  // update query event
  useEffect(() => {
    socket.on(
      "examCandidatesPartial",
      (data: Record<number, PartialDeep<ExamLiveCandidateDtoClient>>) => {
        setData((prev) =>
          prev?.data
            ? {
                ...prev,
                data: prev.data.map((candidateData) => {
                  const newInfo = data[candidateData.candidate.id];
                  if (!newInfo) return candidateData;
                  return _.merge(candidateData, newInfo);
                }),
              }
            : undefined
        );
      }
    );

    return () => {
      socket.off("examCandidatesPartial");
    };
  }, []);

  const [rows, is_placeholder] = useTablePlaceholderRows(data);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="whitespace-nowrap"
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.candidate.root(
                            row.original.candidate.id
                          ),
                          {
                            state: { from: location.pathname },
                          }
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={setPageIndex}
        totalPages={data?.totalPages ?? 0}
      />
    </div>
  );
};

export default CandidatesTable;
