import { ENDPOINT } from "@/constants/endpoints";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { CandidateRegistrationDtoClient } from "../candidate/dto/candidate-registration.dto";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { keyFactory } from "../keyFactory";
import { AdminRegistrationDtoClient } from "../registration-dto/dto/admin-registration.dto";
import { ExamBasicDto } from "./dto/exam-basic.dto";
import { ExamPerimetersDto } from "./dto/exam-perimeters.dto";
import { RegistrationPaginationRequestDto } from "./dto/registration-paginated.dto";

// get

const get = async (registrationId: number) =>
  await axios
    .get<CandidateRegistrationDtoClient>(
      ENDPOINT.registration.getInfo(registrationId)
    )
    .then((res) => res.data);

export const useQueryRegistration = (
  registrationId: number,
  props?: Partial<UseQueryOptions<CandidateRegistrationDtoClient>>
) =>
  useQuery({
    queryKey: keyFactory.registration.get(registrationId),
    queryFn: () => get(registrationId),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });

// pre-test status

type PreTestStatusDto = {
  registrationId: number;
  validated: boolean;
};

const preTestStatus = async (body: PreTestStatusDto) => {
  await axios.post<void>(ENDPOINT.registration.postPreTestStatus(), body);
};

export const useMutationRegistrationPreTestStatus = (
  props?: Partial<UseMutationOptions<void, AxiosError, PreTestStatusDto>>
) =>
  useMutation({
    mutationFn: preTestStatus,
    ...props,
  });

// unregister

const unregister = async (registrationId: number) => {
  await axios.post<void>(ENDPOINT.registration.postUnregister(), {
    registrationId,
  });
};

export const useMutationRegistrationUnregister = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) =>
  useMutation({
    mutationFn: unregister,
    ...props,
  });

// report

type ReportDto = {
  registrationId: number;
  newExamId: number;
};

const report = async (body: ReportDto) => {
  await axios.post<void>(ENDPOINT.registration.postReport(), body);
};

export const useMutationRegistrationReport = (
  props?: Partial<UseMutationOptions<void, AxiosError, ReportDto>>
) =>
  useMutation({
    mutationFn: report,
    ...props,
  });

// additional time

type AdditionalTimeDto = {
  registrationId: number;
  additionalTimeMinutes: number;
};

const additionalTime = async (body: AdditionalTimeDto) => {
  await axios.post<void>(ENDPOINT.registration.postSetAdditionalTime(), body);
};

export const useMutationRegistrationAdditionalTime = (
  props?: Partial<UseMutationOptions<void, AxiosError, AdditionalTimeDto>>
) =>
  useMutation({
    mutationFn: additionalTime,
    ...props,
  });

// all paginated

const allPaginated = async (params: RegistrationPaginationRequestDto) =>
  await axios
    .get<PaginationResponseDto<AdminRegistrationDtoClient>>(
      ENDPOINT.registration.getAllPaginated(),
      {
        params,
      }
    )
    .then(({ data }) => data);

export const useQueryRegistrationAllPaginated = (
  params: RegistrationPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<AdminRegistrationDtoClient>>
  >
) =>
  useQuery({
    queryKey: keyFactory.registration.allPaginated(params),
    queryFn: () => allPaginated(params),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    ...props,
  });

// possible exam ids

const possibleExamIds = async () =>
  axios
    .get<ExamBasicDto[]>(ENDPOINT.registration.getPossibleExamIds())
    .then((res) => res.data);

export const useQueryRegistrationPossibleExamIds = (
  props?: Partial<UseQueryOptions<ExamBasicDto[]>>
) =>
  useQuery<ExamBasicDto[]>({
    queryKey: keyFactory.registration.possibleExamIds(),
    queryFn: () => possibleExamIds(),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    ...props,
  });

// exam perimeters

const examPerimeters = async () =>
  axios
    .get<ExamPerimetersDto>(ENDPOINT.registration.getExamPerimeters())
    .then((res) => res.data);

export const useQueryRegistrationExamPerimeters = (
  props?: Partial<UseQueryOptions<ExamPerimetersDto>>
) =>
  useQuery<ExamPerimetersDto>({
    queryKey: keyFactory.registration.examPerimeters(),
    queryFn: () => examPerimeters(),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    ...props,
  });
