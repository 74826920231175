import { TransportMode } from "@/api/enums/transport-mode.enum";
import { Badge } from "@/components/atoms/Badge";

interface TransportModeBadgeProps {
  transportModes: TransportMode[];
}

const TransportModeBadge = ({ transportModes }: TransportModeBadgeProps) => {
  let text = "";
  if (transportModes.length > 1) text = "T";
  else {
    switch (transportModes[0]) {
      case TransportMode.BOAT:
        text = "N";
        break;
      case TransportMode.TRAIN:
        text = "F";
        break;
      case TransportMode.ROAD:
        text = "R";
        break;
    }
  }

  return <Badge variant="white">{text}</Badge>;
};

export default TransportModeBadge;
