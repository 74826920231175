import { FormField, FormItem, FormLabel, FormMessage } from "@atoms/Form";
import DatePicker from "@molecules/DatePicker";
import { useFormContext } from "react-hook-form";
import FieldWithOptionality from "./Formatters/FieldWithOptionality";
import { FieldMandatoryType } from "./Formatters/mandatory-type";
import { Modifiers, PersonalInfoField } from "./PersonalInfoFields";

interface BirthDatePickerProps {
  modifiers: Modifiers;
}

const BirthDatePicker = ({
  modifiers: { disabledFields, hiddenFields },
}: BirthDatePickerProps) => {
  const { control } = useFormContext();

  if (hiddenFields.includes(PersonalInfoField.Birthdate)) return <></>;

  return (
    <FormField
      control={control}
      name="personalInfo.birthdate"
      render={() => (
        <FormItem className="w-full">
          <FormLabel>
            <FieldWithOptionality
              str="Date de naissance"
              fieldMandatoryType={FieldMandatoryType.MANDATORY_ON_CERTIFICATE}
            />
          </FormLabel>
          <DatePicker
            disabled={disabledFields.includes(PersonalInfoField.Birthdate)}
            disabledDates={(date) =>
              date >
              new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)
            }
          />
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default BirthDatePicker;
