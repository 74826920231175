import { ExamRegistrationStatus } from "@/api/enums/exam-registration-status.enum";
import { Badge } from "@/components/atoms/Badge";

interface RegistrationStatusBadgeProps {
  status: ExamRegistrationStatus;
}

const RegistrationStatusBadge = ({ status }: RegistrationStatusBadgeProps) => {
  switch (status) {
    case ExamRegistrationStatus.UNCOMPLETE:
      return <Badge variant="orange">Pré-inscrit</Badge>;
    case ExamRegistrationStatus.COMPLETE:
      return <Badge variant="green">Inscrit</Badge>;
    case ExamRegistrationStatus.WAITING_RESULTS:
      return <Badge variant="red">En attente de résultats</Badge>;
    case ExamRegistrationStatus.DONE:
      return <Badge variant="sky">Validé</Badge>;
  }
};

export default RegistrationStatusBadge;
