import { keyFactory } from "@/api/keyFactory";
import {
  useMutationQcmReplaceQuestions,
  useQueryQcmGetQuestions,
} from "@/api/qcm/qcm";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import { id } from "@/constants/zodTypes";
import { Perimeter } from "@/lib/perimeter";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { AxiosError, HttpStatusCode } from "axios";
import { Plus } from "lucide-react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import QuestionReplaceForm from "./QuestionReplaceForm";

const schema = z
  .object({
    replacements: z.array(
      z.object({
        previousId: id(),
        newNumber: id(),
      })
    ),
  })
  .superRefine(({ replacements }, ctx) => {
    if (!replacements?.length) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Au moins une question doit être remplacée",
        path: ["missing"],
      });
    }
  });

type Schema = z.infer<typeof schema>;

const defaultValue = {
  newNumber: -1,
  previousId: -1,
};

interface ReplaceQuestionDialogProps {
  perimeter: Perimeter;
  date: Date;
}

const ReplaceQuestionDialog = ({
  perimeter,
  date,
}: ReplaceQuestionDialogProps) => {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [numberToId, setNumberToId] = useState<Record<number, number>>({});

  const replaceMutation = useMutationQcmReplaceQuestions();
  const questionsQuery = useQueryQcmGetQuestions(
    {
      date,
      perimeter: perimeter.toDto(),
    },
    {
      enabled: false,
    }
  );

  const errorsAnswers = (error: AxiosError): string | undefined => {
    const questionId = Number((error.response?.data as any).message);
    const questionNumber = Object.entries(numberToId).find(
      ([, id]) => id === questionId
    )?.[0];

    switch (error.response?.status) {
      case HttpStatusCode.Conflict:
        return `La question ${questionNumber} est déjà dans le QCM.`;
      case HttpStatusCode.NotFound:
        return `La question ${questionNumber} n'a pas été trouvée.`;
      default:
        return undefined;
    }
  };

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: { replacements: [defaultValue] },
  });

  const onSubmit = (data: Schema) => {
    const replacements = [];

    for (const { previousId, newNumber } of data.replacements) {
      const newId = numberToId[newNumber];
      if (!newId) {
        toast.error(`Aucune question avec le numéro ${newNumber}`);
        return;
      }

      replacements.push({ previousId, newId });
    }

    replaceMutation.mutate(
      {
        replacements,
        date,
        perimeter: perimeter.toDto(),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: keyFactory.qcm.questions({
              date,
              perimeter: perimeter.toDto(),
            }),
          });
          toast.success("Changements effectués");
          setOpen(false);
          form.reset();
        },
        onError: (error) => {
          toast.error("Échec de la requête", {
            description:
              errorsAnswers(error) ||
              "Les questions n'ont pas pu être changées.",
          });
        },
      }
    );
  };

  const { fields, append, remove } = useFieldArray({
    control: form.control,
    name: "replacements",
  });

  const missingMessage = (form.formState.errors as unknown as any)?.missing
    ?.message;

  return (
    <Dialog
      open={open}
      onOpenChange={(e) => {
        if (e) questionsQuery.refetch();
        setOpen(e);
      }}
    >
      <DialogTrigger className="text-brand-800 font-semibold hover:text-brand-600">
        Remplacer des questions
      </DialogTrigger>
      <DialogContent
        onClose={() => setOpen(false)}
        // onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-4 p-4"
      >
        <DialogHeader>
          <DialogTitle>Remplacer des questions</DialogTitle>
          <DialogDescription hidden={true}>
            Remplacer des questions
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <Badge variant="white">{Perimeter.toCode(perimeter)}</Badge>
            {missingMessage && (
              <span className="text-red-600">{missingMessage}</span>
            )}
            {fields.map((field, i) => (
              <QuestionReplaceForm
                index={i}
                remove={() => remove(i)}
                key={field.id}
                qcmQuestions={questionsQuery.data ?? []}
                addNumberToId={(number, id) =>
                  setNumberToId({ ...numberToId, [number]: id })
                }
              />
            ))}
            <Button
              variant="ghostWithBorders"
              className="items-center gap-1.5"
              onClick={() => append(defaultValue)}
              type="button"
            >
              <Plus className="w-5 h-5" />
              Ajouter
            </Button>
            <Button disabled={replaceMutation.isPending}>
              {replaceMutation.isPending ? "En cours..." : "Remplacer"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ReplaceQuestionDialog;
