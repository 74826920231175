import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { Input } from "@atoms/Input";
import { useFormContext } from "react-hook-form";
import { formatFieldCapital } from "./Formatters/field-capitals";
import FieldWithOptionality from "./Formatters/FieldWithOptionality";
import { FieldMandatoryType } from "./Formatters/mandatory-type";
import { Modifiers, PersonalInfoField } from "./PersonalInfoFields";
import UnmodifiableHoverCard from "./UnmodifiableHoverCard";

interface TextInputFieldProps {
  title: string;
  type: PersonalInfoField;
  modifiers: Modifiers;
  fieldMandatoryType: FieldMandatoryType;
  placeholder?: string;
  formattingFunction?: (str: string) => string;
  isPassword?: boolean;
}

const TextInputField = ({
  title: name,
  formattingFunction,
  modifiers: { disabledFields, hiddenFields, unmodifiableToolTipFields },
  fieldMandatoryType,
  type,
  isPassword = false,
  placeholder = isPassword ? "*****" : "-",
}: TextInputFieldProps) => {
  const { control } = useFormContext();

  if (hiddenFields.includes(type)) return <></>;

  return (
    <FormField
      control={control}
      name={type}
      render={({ field }) => {
        const fieldId = `input-${type}`;

        return (
          <FormItem className="w-full relative">
            <FormLabel htmlFor={fieldId}>
              <FieldWithOptionality
                str={name}
                fieldMandatoryType={fieldMandatoryType}
              />
            </FormLabel>
            <FormControl>
              <div className="relative">
                <Input
                  autoComplete={isPassword ? "new-password" : undefined}
                  type={isPassword ? "password" : "text"}
                  placeholder={placeholder}
                  {...field}
                  id={fieldId}
                  onChange={
                    formattingFunction
                      ? (e) =>
                          field.onChange(formatFieldCapital(e.target.value))
                      : field.onChange
                  }
                  disabled={disabledFields.includes(type)}
                />
                {unmodifiableToolTipFields.includes(type) && (
                  <UnmodifiableHoverCard />
                )}
              </div>
            </FormControl>
            <FormMessage />
          </FormItem>
        );
      }}
    />
  );
};

export default TextInputField;
