import {
  FormControl,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input, InputProps } from "@/components/atoms/Input";
import { forwardRef } from "react";

interface RoundedInputProps {
  title: string;
  gradeTotal: number;
}

const RoundedInput = forwardRef<
  HTMLInputElement,
  RoundedInputProps & InputProps
>(({ gradeTotal, title, ...props }, ref) => (
  <FormItem>
    <FormLabel>{title}</FormLabel>{" "}
    <div className="flex flex-row items-center border border-gray-300 rounded-lg pr-2">
      <span className="whitespace-nowrap text-gray-900 border-r border-gray-300 py-2 px-3">
        /{gradeTotal}
      </span>
      <FormControl>
        <Input
          type="number"
          className="border-none shadow-none rounded-l-none border-l-0 h-full p-3 bg-none text-gray-500"
          ref={ref}
          {...props}
        />
      </FormControl>
    </div>
    <FormMessage />
  </FormItem>
));

export default RoundedInput;
