import { ExamLiveDtoClient } from "@/api/live-exam/dto/exam-live.dto";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import { cn } from "@/lib/utils";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { columns } from "./Columns";

const LiveSessionsTable = () => {
  const [exams, setExams] = useState<ExamLiveDtoClient[]>([]);
  const navigate = useNavigate();

  // listen for exams updates
  useEffect(() => {
    socket.on("examsPartial", (data: Partial<ExamLiveDtoClient>[]) => {
      setExams((prev) =>
        prev.map((exam) => ({
          ...exam,
          ...(data.find(({ id }) => id === exam.id) ?? {}),
        }))
      );
    });

    socket.on("exams", (data: ExamLiveDtoClient[]) => {
      setExams(data);
    });

    return () => {
      socket.off("examsPartial");
      socket.off("exams");
    };
  }, []);

  // query data
  useEffect(() => {
    socket.emit("getExams", {}, (data: ExamLiveDtoClient[]) => setExams(data));
  }, []);

  const table = useReactTable({
    data: exams,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-auto border border-gray-200 w-full">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                  className="whitespace-nowrap"
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn("cursor-pointer text-gray-900", {
                  "bg-gray-50": i % 2,
                })}
                onClick={() =>
                  navigate(ROUTE.admin.dashboard.live.session(row.original.id))
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default LiveSessionsTable;
