import { Perimeter } from "@/lib/perimeter";

enum PossiblePerimeter {
  RC = "RC",
  FC = "FC",
  RFC = "RFC",
  RFNC = "RFNC",
  RNC = "RNC",
  NC = "NC",
  FNC = "FNC",
  R2C = "R2C",
  F2C = "F2C",
  RF2C = "RF2C",
  RFN2C = "RFN2C",
  RN2C = "RN2C",
  N2C = "N2C",
  FN2C = "FN2C",
  R7 = "R7",
  F7 = "F7",
  RF7 = "RF7",
  RFN7 = "RFN7",
  RN7 = "RN7",
  N7 = "N7",
  FN7 = "FN7",
  R1 = "R1",
  F1 = "F1",
  RF1 = "RF1",
  RFN1 = "RFN1",
  RN1 = "RN1",
  N1 = "N1",
  FN1 = "FN1",
  R2 = "R2",
  F2 = "F2",
  RF2 = "RF2",
  RFN2 = "RFN2",
  RN2 = "RN2",
  N2 = "N2",
  FN2 = "FN2",
  R1C = "R1C",
  F1C = "F1C",
  RF1C = "RF1C",
  RFN1C = "RFN1C",
  RN1C = "RN1C",
  N1C = "N1C",
  FN1C = "FN1C",
  R7C = "R7C",
  F7C = "F7C",
  RF7C = "RF7C",
  RFN7C = "RFN7C",
  RN7C = "RN7C",
  N7C = "N7C",
  FN7C = "FN7C",
  R12C = "R12C",
  F12C = "F12C",
  RF12C = "RF12C",
  RFN12C = "RFN12C",
  RN12C = "RN12C",
  N12C = "N12C",
  FN12C = "FN12C",
  R27C = "R27C",
  F27C = "F27C",
  RF27C = "RF27C",
  RFN27C = "RFN27C",
  RN27C = "RN27C",
  N27C = "N27C",
  FN27C = "FN27C",
  R12 = "R12",
  F12 = "F12",
  RF12 = "RF12",
  RFN12 = "RFN12",
  RN12 = "RN12",
  N12 = "N12",
  FN12 = "FN12",
  R27 = "R27",
  F27 = "F27",
  RF27 = "RF27",
  RFN27 = "RFN27",
  RN27 = "RN27",
  N27 = "N27",
  FN27 = "FN27",
  R17 = "R17",
  F17 = "F17",
  RF17 = "RF17",
  RFN17 = "RFN17",
  RN17 = "RN17",
  N17 = "N17",
  FN17 = "FN17",
  R17C = "R17C",
  F17C = "F17C",
  RF17C = "RF17C",
  RFN17C = "RFN17C",
  RN17C = "RN17C",
  N17C = "N17C",
  FN17C = "FN17C",
  R127C = "R127C",
  F127C = "F127C",
  RF127C = "RF127C",
  RFN127C = "RFN127C",
  RN127C = "RN127C",
  N127C = "N127C",
  FN127C = "FN127C",
  R127 = "R127",
  F127 = "F127",
  RF127 = "RF127",
  RFN127 = "RFN127",
  RN127 = "RN127",
  N127 = "N127",
  FN127 = "FN127",
  RH = "RH",
}

const QCMSizePerimeters = {
  [50]: [
    PossiblePerimeter.RC,
    PossiblePerimeter.FC,
    PossiblePerimeter.RFC,
    PossiblePerimeter.RFNC,
    PossiblePerimeter.RNC,
    PossiblePerimeter.NC,
    PossiblePerimeter.FNC,
    PossiblePerimeter.R2C,
    PossiblePerimeter.F2C,
    PossiblePerimeter.RF2C,
    PossiblePerimeter.RFN2C,
    PossiblePerimeter.RN2C,
    PossiblePerimeter.N2C,
    PossiblePerimeter.FN2C,
    PossiblePerimeter.R7,
    PossiblePerimeter.F7,
    PossiblePerimeter.RF7,
    PossiblePerimeter.RFN7,
    PossiblePerimeter.RN7,
    PossiblePerimeter.N7,
    PossiblePerimeter.FN7,
    PossiblePerimeter.R1,
    PossiblePerimeter.F1,
    PossiblePerimeter.RF1,
    PossiblePerimeter.RFN1,
    PossiblePerimeter.RN1,
    PossiblePerimeter.N1,
    PossiblePerimeter.FN1,
    PossiblePerimeter.R2,
    PossiblePerimeter.F2,
    PossiblePerimeter.RF2,
    PossiblePerimeter.RFN2,
    PossiblePerimeter.RN2,
    PossiblePerimeter.N2,
    PossiblePerimeter.FN2,
    PossiblePerimeter.RH,
  ],
  [65]: [
    PossiblePerimeter.R1C,
    PossiblePerimeter.F1C,
    PossiblePerimeter.RF1C,
    PossiblePerimeter.RFN1C,
    PossiblePerimeter.RN1C,
    PossiblePerimeter.N1C,
    PossiblePerimeter.FN1C,
    PossiblePerimeter.R7C,
    PossiblePerimeter.F7C,
    PossiblePerimeter.RF7C,
    PossiblePerimeter.RFN7C,
    PossiblePerimeter.RN7C,
    PossiblePerimeter.N7C,
    PossiblePerimeter.FN7C,
    PossiblePerimeter.R12C,
    PossiblePerimeter.F12C,
    PossiblePerimeter.RF12C,
    PossiblePerimeter.RFN12C,
    PossiblePerimeter.RN12C,
    PossiblePerimeter.N12C,
    PossiblePerimeter.FN12C,
    PossiblePerimeter.R27C,
    PossiblePerimeter.F27C,
    PossiblePerimeter.RF27C,
    PossiblePerimeter.RFN27C,
    PossiblePerimeter.RN27C,
    PossiblePerimeter.N27C,
    PossiblePerimeter.FN27C,
    PossiblePerimeter.R12,
    PossiblePerimeter.F12,
    PossiblePerimeter.RF12,
    PossiblePerimeter.RFN12,
    PossiblePerimeter.RN12,
    PossiblePerimeter.N12,
    PossiblePerimeter.FN12,
    PossiblePerimeter.R27,
    PossiblePerimeter.F27,
    PossiblePerimeter.RF27,
    PossiblePerimeter.RFN27,
    PossiblePerimeter.RN27,
    PossiblePerimeter.N27,
    PossiblePerimeter.FN27,
    PossiblePerimeter.R17,
    PossiblePerimeter.F17,
    PossiblePerimeter.RF17,
    PossiblePerimeter.RFN17,
    PossiblePerimeter.RN17,
    PossiblePerimeter.N17,
    PossiblePerimeter.FN17,
  ],
  [80]: [
    PossiblePerimeter.R17C,
    PossiblePerimeter.F17C,
    PossiblePerimeter.RF17C,
    PossiblePerimeter.RFN17C,
    PossiblePerimeter.RN17C,
    PossiblePerimeter.N17C,
    PossiblePerimeter.FN17C,
    PossiblePerimeter.R127C,
    PossiblePerimeter.F127C,
    PossiblePerimeter.RF127C,
    PossiblePerimeter.RFN127C,
    PossiblePerimeter.RN127C,
    PossiblePerimeter.N127C,
    PossiblePerimeter.FN127C,
    PossiblePerimeter.R127,
    PossiblePerimeter.F127,
    PossiblePerimeter.RF127,
    PossiblePerimeter.RFN127,
    PossiblePerimeter.RN127,
    PossiblePerimeter.N127,
    PossiblePerimeter.FN127,
  ],
};

type PerimeterSizeT = {
  [key: string]: number;
};

const PerimeterSize: PerimeterSizeT = Object.entries(QCMSizePerimeters).reduce(
  (acc, [size, perimeters]) => {
    for (const possiblePerimeter of perimeters) {
      const perimeter = Perimeter.from(possiblePerimeter);
      acc[Perimeter.toCode(perimeter)] = parseInt(size);
    }

    return acc;
  },
  {} as PerimeterSizeT
);

const perimeterSize = (perimeter: Perimeter | string): number => {
  const code =
    typeof perimeter === "string" ? perimeter : Perimeter.toCode(perimeter);
  const size = PerimeterSize[code];

  if (!size) throw new Error(`invalid perimeter : ${code}`);

  return size ?? 0;
};

export { perimeterSize, PossiblePerimeter, QCMSizePerimeters };
