import { useQueryCandidateRegistrations } from "@/api/candidate/candidate";
import { Input } from "@/components/atoms/Input";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { useState } from "react";
import { useParams } from "react-router-dom";
import SessionDocuments from "./SessionDocuments";

const Documents = () => {
  const { id } = useParams();
  const [searchText, setSearchText] = useState("");

  const registrationsQuery = useQueryCandidateRegistrations(Number(id));
  const filteredRegistrations =
    registrationsQuery.data?.filter((registration) =>
      registration.exam.number.includes(searchText)
    ) ?? [];

  if (!id) return false;

  if (!registrationsQuery.data)
    return (
      <QueryLoadingSpinner
        queries={[registrationsQuery]}
        loadingMessage="Chargement des documents..."
        errorMessage="Erreur lors du chargement des documents"
      />
    );

  return (
    <div className="py-4 px-6 flex flex-col gap-3 bg-white">
      <Input
        className="shadow-none"
        id="shadcn-input"
        searchIcon
        type="text"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Rechercher"
      />
      <div className="flex flex-col gap-0 w-full">
        <div className="flex flex-row items-center bg-gray-50 border border-gray-200 rounded-t-lg text-gray-500">
          <span className="px-4 py-3 w-1/3">Session</span>
          <span className="px-4 py-3 w-1/3">Date</span>
        </div>
        <div className="flex flex-col">
          {filteredRegistrations
            .sort(
              (registration0, registration1) =>
                new Date(registration1.exam.date).getTime() -
                new Date(registration0.exam.date).getTime()
            )
            .map(({ id: registrationId, exam }, i) => (
              <SessionDocuments
                key={registrationId}
                candidateId={Number(id)}
                registrationId={registrationId}
                isLast={i === filteredRegistrations.length - 1}
                exam={exam}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Documents;
