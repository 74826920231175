import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import {
  FormationInstituteDto,
  FormationInstituteDtoClient,
} from "../dto/formation-institutes.dto";
import { keyFactory } from "../keyFactory";

// get all

const formationInstitutes = async () => {
  return axios
    .get<FormationInstituteDtoClient[]>(ENDPOINT.formationInstitute.getAll())
    .then((res) => res.data);
};

export const useFormationInstitutesQuery = () => {
  return useQuery<FormationInstituteDtoClient[]>({
    queryKey: keyFactory.formationInstitutes.all(),
    queryFn: formationInstitutes,
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
  });
};

// create

const create = async (body: FormationInstituteDto) => {
  await axios.post<number>(ENDPOINT.formationInstitute.postCreate(), body);
};

export const useFormationInstituteCreateMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, FormationInstituteDto>>
) => {
  return useMutation({
    mutationFn: create,
    ...props,
  });
};

// delete

const deletion = async (id: number) => {
  await axios.post<number>(ENDPOINT.formationInstitute.postDelete(), {
    id,
  });
};

export const useFormationInstituteDeleteMutation = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) => {
  return useMutation({
    mutationFn: deletion,
    ...props,
  });
};
