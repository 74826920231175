import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DocumentType } from "@/api/dto/document-type.enum";
import { FileDto } from "@/api/dto/file.dto";
import { FormationType } from "@/api/enums/FormationType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import FormationAttestationsForm from "@/components/molecules/FormationAttestationsForm/FormationAttestationsForm";
import {
  formationAttestationsSchema,
  FormationAttestationsSchemaType,
} from "@/components/molecules/FormationAttestationsForm/FormationAttestationsFormSchema";
import useFileUpload from "@/hooks/FileUpload";
import { Perimeter } from "@/lib/perimeter";
import { zodResolver } from "@hookform/resolvers/zod";
import { Info } from "lucide-react";
import { useForm } from "react-hook-form";

interface AttestationsDialogProps {
  registration: CandidateRegistrationDtoClient;
  isOpen: boolean;
  close: () => void;
  onSubmitMutation: (data: {
    allAttestationsProvided: boolean;
    files: Array<{
      formationType: FormationType;
      perimeter: Perimeter;
      certificate: FileDto;
      formationInstituteId?: number;
      status?: ValidationStatus;
    }>;
    registrationId: number;
  }) => void;
  isPending?: boolean;
}

const AttestationsDialog = ({
  close,
  isOpen,
  registration: { allAttestationsProvided, formationCertificates, id, type },
  onSubmitMutation,
  isPending,
}: AttestationsDialogProps) => {
  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.FORMATION_ATTESTATION
  );

  // form

  const form = useForm<FormationAttestationsSchemaType>({
    resolver: zodResolver(formationAttestationsSchema),
    defaultValues: {
      attestations: formationCertificates ?? [],
      allAttestationsProvided: allAttestationsProvided ?? false,
    },
  });

  const onSubmit = async (values: FormationAttestationsSchemaType) => {
    const { data, error, success } =
      formationAttestationsSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting :", error);
      return;
    }

    const uploadedFiles = await ensureUploaded(
      ...data.attestations.map(({ certificate }) => certificate)
    );

    if (uploadedFiles.some((file) => !file)) {
      console.error("error while uploading files");
      return;
    }

    onSubmitMutation({
      allAttestationsProvided:
        data.allAttestationsProvided && data.attestations.length > 0,
      files: data.attestations.map(
        ({ formationType, perimeter, formationInstituteId, status }, i) => ({
          formationType,
          ...(formationType === FormationType.INSTITUTE && {
            formationInstituteId: formationInstituteId as number,
          }),
          perimeter: Perimeter.from(perimeter),
          certificate: uploadedFiles[i] as FileDto,
          status,
        })
      ),
      registrationId: id,
    });
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent
        onClose={close}
        className="max-w-3xl flex flex-col gap-4 overflow-auto max-h-[85%]"
      >
        <DialogHeader>
          <DialogTitle>Attestation(s) de formation</DialogTitle>
          <DialogDescription hidden={true}>
            Formulaire pour déposer une ou plusieurs attestation(s) de
            formation.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
          <Info className="w-5 h-5 flex-shrink-0" />
          <div className="flex flex-col">
            <span className="font-bold">Dépôt obligatoire</span>
            <span className="text-gray-600 font-normal">
              Vous devez déposer votre ou vos attestation(s) de formation dès
              que possible et au plus tard 7 jours avant la date de session
              d'examen. Si ce délai devait être dépassé, vous devez nous
              contacter par mail. Il est recommandé de privilégier un dépôt
              unique, regroupant toutes vos attestations en un seul document
            </span>
          </div>
        </div>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <FormationAttestationsForm
              validationStatus={formationCertificates.map((f) => f.status)}
              forbiddenFormationTypes={
                type === RegistrationType.RENEW ? [] : [FormationType.ON_HONOR]
              }
            />
            <Button type="submit" disabled={uploadIsPending || isPending}>
              {uploadIsPending
                ? "Envoi des fichiers..."
                : isPending
                ? "Envoi des attestations..."
                : "Valider"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AttestationsDialog;
