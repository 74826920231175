import { ENDPOINT } from "@/constants/endpoints";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { NewDelegationDto } from "../registration/dto/new-delegation.dto";

// refuse

const refuse = async (registrationId: number) => {
  await axios.post<void>(ENDPOINT.delegation.postRefuse(), { registrationId });
};

export const useMutationDelegationRefuse = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) =>
  useMutation({
    mutationFn: refuse,
    ...props,
  });

// new

const newDelegation = async (body: NewDelegationDto) => {
  await axios.post<void>(ENDPOINT.delegation.postNew(), body);
};

export const useMutationDelegationNew = (
  props?: Partial<UseMutationOptions<void, AxiosError, NewDelegationDto>>
) =>
  useMutation({
    mutationFn: newDelegation,
    ...props,
  });
