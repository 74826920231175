import { useFiltersQuery } from "@/api/question/question";
import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import FilterSheet from "./FiltersSheet";
import ImportDialog from "./ImportDialog";
import { QuestionsTab } from "./questions-tab.enum";
import QuestionsTable from "./QuestionsTable";

const Questions = () => {
  const [searchText, setSearchText] = usePersistent<string>(
    "questions-searchText",
    ""
  );
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [tab, setTab] = usePersistent<QuestionsTab>(
    "questions-tab",
    QuestionsTab.All
  );
  const [includedPerimeter, setIncludedPerimeter] = useState(Perimeter.empty());
  const [validationYears, setValidationYears] = usePersistent<Date[]>(
    "questions-validationYears",
    []
  );
  const [deactivationYears, setDeactivationYears] = usePersistent<Date[]>(
    "questions-deactivationYears",
    []
  );
  const [activationYears, setActivationYears] = usePersistent<Date[]>(
    "questions-activationYears",
    []
  );

  const active =
    tab === QuestionsTab.Active
      ? true
      : tab === QuestionsTab.Inactive
      ? false
      : undefined;

  const { data: filters } = useFiltersQuery();

  return (
    <div className="flex flex-col w-full bg-white h-screen overflow-auto">
      <div className="flex flex-col w-full border-b border-b-gray-200 px-6 pt-3 relative gap-2">
        <h1>Questions</h1>
        <div className="flex flex-row gap-4 items-center absolute top-2 right-6">
          <FilterSheet
            possibleValidationYears={
              filters?.possibleValidationYears.map((d) => new Date(d)) ?? []
            }
            validationYears={validationYears.map((d) => new Date(d))}
            setValidationYears={setValidationYears}
            possibleDeactivationYears={
              filters?.possibleDeactivationYears.map((d) => new Date(d)) ?? []
            }
            deactivationYears={deactivationYears.map((d) => new Date(d))}
            setDeactivationYears={setDeactivationYears}
            possibleActivationYears={
              filters?.possibleActivationYears.map((d) => new Date(d)) ?? []
            }
            activationYears={activationYears.map((d) => new Date(d))}
            setActivationYears={setActivationYears}
            perimeter={includedPerimeter}
            setPerimeter={setIncludedPerimeter}
          />
          {/* <Button variant="ghostWithBorders" className="text-gray-800">
            Exporter
            </Button> */}
          <ImportDialog />
        </div>
        <Tabs onValueChange={(e) => setTab(e as QuestionsTab)} value={tab}>
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={QuestionsTab.All}>
              Toutes
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={QuestionsTab.Active}>
              Actives
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={QuestionsTab.Inactive}>
              Inactives
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Rechercher"
        />
        <QuestionsTable
          filters={{
            searchText: debouncedSearchText || undefined,
            active,
            activationYears,
            deactivationYears,
            merchandises:
              includedPerimeter.merchandises.length === 0
                ? undefined
                : includedPerimeter.merchandises,
            transportModes:
              includedPerimeter.transportModes.length === 0
                ? undefined
                : includedPerimeter.transportModes,
            validationYears,
          }}
        />
      </div>
    </div>
  );
};

export default Questions;
