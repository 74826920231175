import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { useQueryCertificateAll } from "@/api/certificate/certificate";
import { ExtensionType } from "@/api/enums/ExtensionType";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { RenewingType } from "@/api/enums/RenewingType";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import RegistrationTypeBadge from "@/components/molecules/Badges/RegistrationTypeBadge";
import RadioSelectedItem from "@/components/molecules/RadioSelectedItem";
import { cn } from "@/lib/utils";
import { Calendar } from "lucide-react";
import { useMemo } from "react";
import CertificateCard from "./CertificateCard";
import PerimeterDisplay from "./PerimeterDisplay";
import { Perimeter } from "@/lib/perimeter";

const registeringTypeName = {
  [RegistrationType.INITIAL]: "Examen initial",
  [RegistrationType.EXTENSION]: "Extension",
  [RegistrationType.RENEW]: "Examen de renouvellement",
};

const renewingTypeName = {
  [RenewingType.FUSION]: "Fusion de périmètres",
  [RenewingType.REDUCED]: "Périmètre réduit",
  [RenewingType.SAME]: "Même périmètre",
};

const extensionTypeName = {
  [ExtensionType.MERCHANDISES]: "Classe(s) de marchandises dangereuses",
  [ExtensionType.TRANSPORTS]: "Mode(s) de transport",
};

interface DetailsDialogProps {
  isOpen: boolean;
  close: () => void;
  registration: CandidateRegistrationDtoClient;
}

const DetailsDialog = ({
  registration: {
    type,
    registrationNumber,
    extensionProperties,
    renewingProperties,
    exam: { date, registeringEndDate },
    perimeter,
  },
  close,
  isOpen,
}: DetailsDialogProps) => {
  const certificatesQuery = useQueryCertificateAll();

  const renewedCertificates = useMemo(
    () =>
      renewingProperties
        ? certificatesQuery.data?.filter(
            ({ id }) =>
              id && renewingProperties.renewingCertificatesIDs.includes(id)
          )
        : undefined,
    [certificatesQuery.data, renewingProperties]
  );

  const extendedCertificate = useMemo(
    () =>
      extensionProperties
        ? certificatesQuery.data?.find(
            ({ id }) => id && extensionProperties.certificateId === id
          )
        : undefined,
    [certificatesQuery.data, extensionProperties]
  );

  return (
    <Dialog open={isOpen}>
      <DialogContent onClose={close} className="flex flex-col max-w-2xl gap-6">
        <DialogHeader>
          <DialogTitle>Détails de l'inscription</DialogTitle>
        </DialogHeader>
        <DialogDescription hidden={true}>
          Détails de l'inscription
        </DialogDescription>
        <RegistrationTypeBadge registrationType={type} />
        <div className="flex flex-row w-full gap-6">
          <div className="flex flex-col w-full gap-2">
            <h2>Numéro d'inscription</h2>
            <span
              className={cn("text-gray-500", { italic: !registrationNumber })}
            >
              {registrationNumber || "Pas encore généré"}
            </span>
          </div>
          <div className="flex flex-col w-full gap-2">
            <h2>Type d'examen</h2>
            <RadioSelectedItem text={registeringTypeName[type]} />
          </div>
        </div>
        <div className="flex flex-col w-full gap-2">
          <h2 className="mb-2">Date d'examen</h2>
          <div className="flex flex-row gap-2 items-center">
            <Calendar className="h-4 w-4 text-gray-500" />
            <span>
              Date de l'examen : {new Date(date).toLocaleDateString("fr-FR")}
            </span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <Calendar className="h-4 w-4 text-gray-500" />
            <span>
              Date clôture des inscriptions :{" "}
              {new Date(registeringEndDate).toLocaleDateString("fr-FR")}
            </span>
          </div>
        </div>
        {type === RegistrationType.RENEW && renewingProperties && (
          <>
            <div className="flex flex-col w-full gap-2">
              <h2>Type de Renouvellement</h2>
              <RadioSelectedItem
                text={renewingTypeName[renewingProperties.renewingType]}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <h2>Certificat(s) à renouveler</h2>
              {renewedCertificates &&
                renewedCertificates.map((certificate) => (
                  <CertificateCard
                    key={certificate.id}
                    certificate={certificate}
                  />
                ))}
            </div>
          </>
        )}
        {type === RegistrationType.EXTENSION && extensionProperties && (
          <>
            <div className="flex flex-col w-full gap-2">
              <h2>Type d'extension</h2>
              <RadioSelectedItem
                text={extensionTypeName[extensionProperties.extensionType]}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <h2>Certificat à étendre</h2>
              {extendedCertificate && (
                <CertificateCard certificate={extendedCertificate} />
              )}
            </div>
          </>
        )}
        {perimeter && (
          <PerimeterDisplay perimeter={Perimeter.from(perimeter)} />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DetailsDialog;
