import { useMutationCertificateUpload } from "@/api/certificate/certificate";
import { DocumentType } from "@/api/dto/document-type.enum";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import useFileUpload from "@/hooks/FileUpload";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogTitle } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import CertificateForm from "../AccountCreation/CertificateForm/CertificateForm";
import {
  certificateDefaultValues,
  certificateSchema,
} from "../AccountCreation/CertificateForm/CertificateFormSchema";

interface AddCertificateDialogProps {
  isOpen: boolean;
  close: () => void;
}

const AddCertificateDialog = ({ close, isOpen }: AddCertificateDialogProps) => {
  const addCertificateMutation = useMutationCertificateUpload();
  const queryClient = useQueryClient();
  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.FOREIGN_CERTIFICATE
  );

  const form = useForm<z.infer<typeof certificateSchema>>({
    resolver: zodResolver(certificateSchema),
    defaultValues: certificateDefaultValues,
  });

  const onSubmit = async (values: z.infer<typeof certificateSchema>) => {
    const { data, error, success } = certificateSchema.safeParse(values);

    if (!success || !data) {
      console.error("can't parse form, aborting registration :", error);
      return;
    }

    const { file, ...dto } = data;

    const [fileDto] = await ensureUploaded(file);
    if (!fileDto) {
      toast.error("Erreur lors de l'envoi du fichier");
      return;
    }
    addCertificateMutation.mutate(
      {
        ...dto,
        file: fileDto,
      },
      {
        onSuccess: () => {
          toast.success("Certificat ajouté", {
            description:
              'Vous pouvez le consulter dans la rubrique "Mon profil" > "Mes certificats"',
          });

          queryClient.invalidateQueries({
            queryKey: keyFactory.certificate.base(),
          });
          close();
          form.reset();
        },
        onError: () => toast.error("Erreur lors de l'ajout du certificat"),
      }
    );
  };

  return (
    <Dialog modal={true} open={isOpen}>
      <DialogContent className="max-w-3xl" onClose={close}>
        <DialogTitle className="text-gray-950 text-normal font-semibold text-xl">
          Soumettre un certificat étranger
        </DialogTitle>
        <DialogDescription hidden={true}>
          Formulaire pour déposer un certificat.
        </DialogDescription>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col space-y-8"
          >
            <CertificateForm />
            <Button
              type="submit"
              disabled={addCertificateMutation.isPending || uploadIsPending}
            >
              {uploadIsPending
                ? "Envoi du certificat..."
                : addCertificateMutation.isPending
                ? "Création du certificat..."
                : "Soumettre"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddCertificateDialog;
