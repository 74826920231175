import { Checkbox } from "@/components/atoms/CheckBox";

interface YearsSetterProps {
  title: string;
  possibleYears: Date[];
  years: Date[];
  setYears: (years: Date[]) => void;
}

const YearSetter = ({
  title,
  possibleYears,
  setYears,
  years,
}: YearsSetterProps) => {
  return (
    <div className="flex flex-col gap-2">
      <span>{title}</span>
      <div className="grid grid-cols-3 gap-4">
        {possibleYears
          .sort((year0, year1) => year0.getFullYear() - year1.getFullYear())
          .map((year) => {
            const id = `${title}-${year}`;
            const checked = years.some(
              (y) => y.getFullYear() === year.getFullYear()
            );

            return (
              <div className="flex flex-row gap-2 items-center" key={id}>
                <Checkbox
                  checked={checked}
                  onClick={() =>
                    setYears(
                      (checked
                        ? years.filter(
                            (y) => y.getFullYear() !== year.getFullYear()
                          )
                        : [...years, year]
                      ).sort(
                        (year0, year1) =>
                          year0.getFullYear() - year1.getFullYear()
                      )
                    )
                  }
                  id={id}
                />
                <label htmlFor={id}>{year.getFullYear()}</label>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default YearSetter;
