import { ExamDetailedDtoClient } from "@/api/exam/dto/exam-detailed.dto";
import { useOutletContext } from "react-router-dom";
import { SessionTab } from "./session-tab.enum";

export type SessionContext = {
  exam: ExamDetailedDtoClient;
  tab: SessionTab;
};

export const useSessionContext = () => useOutletContext<SessionContext>();
