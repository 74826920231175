import { useQueryRegistrationPossibleExamIds } from "@/api/registration/registration";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import { Input } from "@/components/atoms/Input";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@/components/atoms/Sheet";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { useState } from "react";

interface SessionFilterSheetProps {
  isOpen?: boolean;
  setOpen: (b: boolean) => void;
  selectedExamIds: number[];
  setSelectedExamIds: (selectedExamIds: number[]) => void;
}

const SessionFilterSheet = ({
  setOpen,
  isOpen,
  setSelectedExamIds,
  selectedExamIds,
}: SessionFilterSheetProps) => {
  const [searchText, setSearchText] = useState("");

  const possibleExamIdsQuery = useQueryRegistrationPossibleExamIds();

  return (
    <Sheet open={isOpen} onOpenChange={(e) => setOpen(e)}>
      <SheetContent className="flex flex-col gap-4 h-full">
        <SheetHeader>
          <SheetTitle>Session</SheetTitle>
          <SheetDescription hidden={true}>Filtre par session</SheetDescription>
        </SheetHeader>
        <div className="flex flex-col justify-between h-[90%] gap-5">
          <div className="flex flex-col gap-3 items-center h-full">
            <Input
              className="shadow-none"
              id="shadcn-input"
              searchIcon
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value as string)}
              placeholder="Rechercher"
            />
            {!possibleExamIdsQuery.data ? (
              <LoadingSpinner
                isLoading={possibleExamIdsQuery.isLoading}
                isError={possibleExamIdsQuery.isError}
                loadingMessage="Chargement des examens..."
                errorMessage="Erreur lors du chargement des examens."
              />
            ) : (
              <div className="flex flex-col gap-4 justify-start w-full pt-2 overflow-auto">
                {possibleExamIdsQuery.data
                  .filter(({ number }) => number.includes(searchText))
                  .map(({ id, number }) => (
                    <div className="flex flex-row items-center gap-2" key={id}>
                      <Checkbox
                        key={id}
                        id={`exam-${id}`}
                        checked={selectedExamIds.includes(id)}
                        onCheckedChange={(checked) =>
                          setSelectedExamIds(
                            checked
                              ? [...selectedExamIds, id]
                              : selectedExamIds.filter(
                                  (otherId) => otherId !== id
                                )
                          )
                        }
                      >
                        {id}
                      </Checkbox>
                      <label htmlFor={`exam-${id}`}>{number}</label>
                    </div>
                  ))}
              </div>
            )}
          </div>
          <div className="flex flex-row w-full items-center justify-end gap-4">
            <Button
              onClick={() => setSelectedExamIds([])}
              variant="ghostWithBorders"
            >
              Réinitialiser
            </Button>
            <Button onClick={() => setOpen(false)}>Filtrer la colonne</Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default SessionFilterSheet;
