import { ENDPOINT } from "@/constants/endpoints";
import { UseMutationOptions, useMutation } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ReducedPriceNewDocumentDto } from "./dto/reduced-price-new-document.dto";

// new document

const newDocument = async (body: ReducedPriceNewDocumentDto) => {
  await axios.post<void>(ENDPOINT.reducedPrice.postNewDocument(), body);
};

export const useMutationReducedPriceNewDocument = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, ReducedPriceNewDocumentDto>
  >
) =>
  useMutation({
    mutationFn: newDocument,
    ...props,
  });

// status

type ReducedPriceStatusDto = {
  registrationId: number;
  validated: boolean;
};

const status = async (body: ReducedPriceStatusDto) => {
  await axios.post<void>(ENDPOINT.reducedPrice.postStatus(), body);
};

export const useMutationReducedPriceStatus = (
  props?: Partial<UseMutationOptions<void, AxiosError, ReducedPriceStatusDto>>
) =>
  useMutation({
    mutationFn: status,
    ...props,
  });
