import { Role } from "@/api/enums/Role";
import logo from "@/assets/logo.png";
import { Button } from "@/components/atoms/Button";
import ButtonMailto from "@/components/atoms/ButtonMailTo";
import DisconnectPrompt from "@/components/molecules/DisconnectPrompt";
import { ROUTE } from "@/constants/routes";
import useRoleBasedRedirect from "@/hooks/RoleBasedRedirect";
import { cn } from "@/lib/utils";
import { FileText, LucideLogOut, User } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { NavLink, Outlet } from "react-router-dom";
import AddCertificateDialog from "./AddCertificateDialog";
import CertificatesAlerts from "./CertificatesAlerts";
import { DashBoardContextType } from "./DashBoardContext";

const DashBoard = () => {
  const [addCertificatesDisplayed, setAddCertificatesDisplayed] =
    useState(false);
  const [disconnectIsOpen, setDisconnectIsOpen] = useState(false);
  const [isScreenSmall, setIsScreenSmall] = useState(false);

  // check if screen is too small
  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth < 768);
    };

    handleResize(); // Call it once to set the initial state

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // context for children pages
  const context: DashBoardContextType = useMemo(
    () => ({
      openAddCertificates: () => setAddCertificatesDisplayed(true),
    }),
    []
  );

  useRoleBasedRedirect(Role.CANDIDATE);

  // don't display dashboard if screen is too small
  if (isScreenSmall)
    return (
      <div className="flex flex-col items-center justify-center h-screen gap-4">
        <p className="text-center text-lg font-semibold">
          Votre écran est trop petit pour naviguer sur le site.
          <br />
          Merci d'utiliser un ordinateur.
        </p>
        <img className="w-16 h-16" src={logo} alt="Description of the image" />
      </div>
    );

  return (
    <>
      <AddCertificateDialog
        isOpen={addCertificatesDisplayed}
        close={() => setAddCertificatesDisplayed(false)}
      />
      <CertificatesAlerts
        openCertificatePopup={() => setAddCertificatesDisplayed(true)}
      />
      <DisconnectPrompt
        isOpen={disconnectIsOpen}
        close={() => setDisconnectIsOpen(false)}
      />
      <div className="flex flex-col w-full">
        <div className="flex py-4 px-20 justify-between items-center self-stretch bg-white">
          <div className="flex flex-row gap-7">
            <img
              className="w-11 h-11"
              src={logo}
              alt="Description of the image"
            />
            <div className="flex flex-row gap-1 self-stretch">
              {[
                {
                  children: (
                    <>
                      <FileText className="w-4 h-4" />
                      Mes examens
                    </>
                  ),
                  to: ROUTE.candidate.dashboard.exams.home(),
                },
                {
                  children: (
                    <>
                      <User className="w-4 h-4" />
                      Mon profil
                    </>
                  ),
                  to: ROUTE.candidate.dashboard.profile(),
                },
              ].map(({ children, to }) => (
                <NavLink
                  key={to}
                  to={to}
                  className={({ isActive }) =>
                    cn(
                      "flex flex-row items-center gap-2.5 rounded-lg py-2 px-4 text-sm font-medium transition-colors duration-200",
                      {
                        "text-brand-700 bg-gray-100 hover:bg-blue-50 hover:text-blue-700":
                          isActive,
                        "text-gray-600 hover:bg-gray-50 hover:text-gray-700":
                          !isActive,
                      }
                    )
                  }
                >
                  {children}
                </NavLink>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-6">
            <div className="text-gray-600">
              <span className="text-sm font-medium">Demande d'aide : </span>
              <span className="font-bold underline">
                <ButtonMailto
                  label="contact@cifmd.fr"
                  email="contact@cifmd.fr"
                />
              </span>
            </div>
            <Button
              className="flex flex-row py-2 px-3 justify-center items-center gap-1 rounded-lg border border-gray-300 bg-white text-gray-800 hover:bg-gray-10"
              onClick={() => setDisconnectIsOpen(true)}
            >
              <LucideLogOut />
              <span className="font-semibold leading-6">Se déconnecter</span>
            </Button>
          </div>
        </div>
        <Outlet context={context} />
      </div>
    </>
  );
};

export default DashBoard;
