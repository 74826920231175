import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { keyFactory } from "@/api/keyFactory";
import { useMutationRegistrationUnregister } from "@/api/registration/registration";
import { Button } from "@/components/atoms/Button";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/atoms/Popover";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import ReportDialog from "@/components/molecules/ReportDialog";
import { useQueryClient } from "@tanstack/react-query";
import { ChevronRight, EllipsisVertical, X } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface ActionsButtonProps {
  registration: CandidateRegistrationDtoClient;
  candidateId: number;
}

const ActionsButton = ({ registration, candidateId }: ActionsButtonProps) => {
  const [unregisterDialogOpen, setUnregisterDialogOpen] = useState(false);
  const queryClient = useQueryClient();

  const unregisterMutation = useMutationRegistrationUnregister();

  return (
    <>
      <ConfirmDialog
        isOpen={unregisterDialogOpen}
        close={() => setUnregisterDialogOpen(false)}
        title="Désinscription"
        message={`Êtes-vous sûr de vouloir désinscrire ce candidat de la session du ${new Date(
          registration.exam.date
        ).toLocaleDateString("fr-FR")} ?`}
        validateStr="Oui, désinscrire"
        validateButtonVariant="red"
        cancelStr="Non, annuler"
        validatePending={unregisterMutation.isPending}
        onResult={(accepted) => {
          if (accepted) {
            unregisterMutation.mutate(registration.id, {
              onSuccess: () => {
                toast.success("Candidat désinscrit");
                queryClient.invalidateQueries({
                  queryKey:
                    keyFactory.candidate.onGoingRegistration(candidateId),
                });
                queryClient.invalidateQueries({
                  queryKey: keyFactory.candidate.basicInfo(candidateId),
                });
                queryClient.invalidateQueries({
                  queryKey: keyFactory.exam.registrations(),
                });
                setUnregisterDialogOpen(false);
              },
              onError: () => {
                toast.error("Échec de la requête", {
                  description: "Votre action n'a pas pu être prise en compte.",
                });
              },
            });
          } else setUnregisterDialogOpen(false);
        }}
      />
      <Popover>
        <PopoverTrigger className="flex flex-row items-center bg-brand-600 hover:bg-brand-800 text-white rounded-lg p-2">
          Actions <EllipsisVertical className="w-5 h-5" />
        </PopoverTrigger>
        <PopoverContent className="flex flex-col p-1" align="end">
          <Button
            variant="ghost"
            className="flex flex-row items-center justify-between w-full pl-2 pr-0"
            onClick={() => setUnregisterDialogOpen(true)}
          >
            <div className="flex flex-row items-center gap-2">
              <X className="w-4 h-4" />
              Désinscrire de la session
            </div>
            <ChevronRight />
          </Button>
          <ReportDialog
            candidateId={candidateId}
            currentExamId={registration.exam.id}
            registrationId={registration.id}
            registrationType={registration.type}
          />
        </PopoverContent>
      </Popover>
    </>
  );
};

export default ActionsButton;
