import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { Badge } from "@/components/atoms/Badge";

interface MerchandiseBadgeProps {
  merchandises: MerchandiseClass[];
}

const MerchandiseBadge = ({ merchandises }: MerchandiseBadgeProps) => {
  let text = "";
  if (merchandises.length > 1) text = "B";
  else {
    switch (merchandises[0]) {
      case MerchandiseClass.EXPLOSIVES:
        text = "1";
        break;
      case MerchandiseClass.GAS:
        text = "2";
        break;
      case MerchandiseClass.RADIOACTIVE:
        text = "7";
        break;
      case MerchandiseClass.CHEMICAL:
        text = "C";
        break;
      case MerchandiseClass.HYDROCARBONS:
        text = "H";
        break;
    }
  }

  return <Badge variant="white">{text}</Badge>;
};

export default MerchandiseBadge;
