import {
  useMutationDraftConfirm,
  useMutationDraftCreate,
  useMutationDraftSetOptions,
  useQueryDraftOptions,
} from "@/api/draft/draft";
import { ExamOptionsDto } from "@/api/draft/dto/exam-options.dto";
import { keyFactory } from "@/api/keyFactory";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { ROUTE } from "@/constants/routes";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@atoms/BreadCrumb";
import ConfirmDialog from "@molecules/ConfirmDialog";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import _ from "lodash";
import { Check, Circle, EllipsisVertical } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { RegisterContext, RegisteringStep } from "./RegisterContext";

const localStorageStepName = "examRegistrationCurrentStep";
const localStorageMaxStepName = "examRegistrationMaxStep";

const saveStepInfo = (name: string, step: RegisteringStep) => {
  localStorage.setItem(name, step.toString());
};

const loadStepInfo = (name: string): RegisteringStep => {
  const step = localStorage.getItem(localStorageStepName);

  if (!step) {
    saveStepInfo(name, RegisteringStep.CHECK_INFO);
    return RegisteringStep.CHECK_INFO;
  }

  return parseInt(step) as RegisteringStep;
};

const stepsPages = {
  [RegisteringStep.ACKNOWLEDGEMENT]:
    ROUTE.candidate.dashboard.exams.register.acknowledgment(),
  [RegisteringStep.CERTIFICATE]:
    ROUTE.candidate.dashboard.exams.register.certificate(),
  [RegisteringStep.CHECK_INFO]:
    ROUTE.candidate.dashboard.exams.register.checkInfo(),
  [RegisteringStep.PAYEMENT]:
    ROUTE.candidate.dashboard.exams.register.payement(),
  [RegisteringStep.PRICING]: ROUTE.candidate.dashboard.exams.register.pricing(),
  [RegisteringStep.REGISTER]:
    ROUTE.candidate.dashboard.exams.register.registerStep(),
};

const Register = () => {
  // changing step with left bar buttons
  const [changePageDialogOpen, setChangePageDialogOpen] = useState(false);
  const [nextStep, setChangeStep] = useState(RegisteringStep.CHECK_INFO);
  // loading or discarding draft
  const [loadDraftDialogOpen, setLoadDraftDialogOpen] = useState(false);
  const [userWasPrompted, setUserWasPrompted] = useState(false);
  // steps handling
  const [step, setStep] = useState(loadStepInfo(localStorageStepName));
  const [maxCurrentStep, setMaxCurrentStep] = useState(
    loadStepInfo(localStorageMaxStepName)
  );
  // used to compare new and old states, and check if changes have been made
  // this permits to do fewer api calls
  const [previousRegistrationDraft, setPreviousRegistrationDraft] =
    useState<ExamOptionsDto | null>(null);
  const queryClient = useQueryClient();
  const [draftWasCreated, setDraftWasCreated] = useState(false);
  const navigate = useNavigate();

  //////////////////
  // api calls
  //////////////////

  const getOptionsQuery = useQueryDraftOptions();
  const createResetDraftMutation = useMutationDraftCreate({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.candidate.registrations(),
      });
    },
  });
  const setOptionsMutation = useMutationDraftSetOptions({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.draft.options(),
      });
    },
  });
  const confirmDraftMutation = useMutationDraftConfirm();

  //////////////////
  // navigation
  //////////////////

  // navigate to change page
  useEffect(() => {
    if (setOptionsMutation.isPending || confirmDraftMutation.isPending) return;
    navigate(stepsPages[step]);
  }, [
    step,
    navigate,
    setOptionsMutation.isPending,
    confirmDraftMutation.isPending,
  ]);

  const changeStep = useCallback(
    (newStep: RegisteringStep, overrideMaxStep: boolean = false) => {
      if (maxCurrentStep < newStep || overrideMaxStep) {
        setMaxCurrentStep(newStep);
        saveStepInfo(localStorageMaxStepName, maxCurrentStep);
      }

      setStep(newStep);
      saveStepInfo(localStorageStepName, newStep);
    },
    [maxCurrentStep]
  );

  // create draft or ask user to continue
  const { mutate: createResetDraftMutate } = createResetDraftMutation;
  useEffect(() => {
    if (!getOptionsQuery.isSuccess) return;

    if (getOptionsQuery.data === "no-draft") {
      if (draftWasCreated) return;
      // there is no draft yet
      setDraftWasCreated(true);
      createResetDraftMutate();
      changeStep(RegisteringStep.CHECK_INFO, true);
      setUserWasPrompted(true);
      return;
    } else if (!userWasPrompted) {
      // there is a draft
      setLoadDraftDialogOpen(true);
      setPreviousRegistrationDraft(getOptionsQuery.data);
    }
  }, [
    getOptionsQuery.data,
    getOptionsQuery.isSuccess,
    createResetDraftMutate,
    userWasPrompted,
    changeStep,
    setDraftWasCreated,
    draftWasCreated,
  ]);

  // loading draft mechanism

  const handleLoadDraftResult = (userWantsToLoadDraft: boolean) => {
    if (!userWantsToLoadDraft) {
      if (!draftWasCreated) {
        createResetDraftMutation.mutate();
        setDraftWasCreated(true);
      }
      changeStep(RegisteringStep.CHECK_INFO, true);
    }

    setUserWasPrompted(true);
    setLoadDraftDialogOpen(false);
  };

  // on page change

  const onNextStep = async (goNext: boolean) => {
    if (step === RegisteringStep.ACKNOWLEDGEMENT && goNext) {
      confirmDraftMutation.mutate(undefined, {
        onSuccess: async () => {
          changeStep(RegisteringStep.CHECK_INFO, true);
          navigate(ROUTE.candidate.dashboard.exams.home());
          queryClient.invalidateQueries({
            queryKey: keyFactory.draft.options(),
          });

          queryClient.invalidateQueries({
            queryKey: keyFactory.candidate.registrations(),
          });

          toast.success("Inscription réussie", {
            description:
              'Vous pouvez consulter le statut de votre inscription depuis l\'onglet "Mes examens"',
          });
        },
      });

      return;
    }

    changeStep(step + (goNext ? 1 : -1));
  };

  //////////////////
  // step box component
  //////////////////

  interface StepBoxProps {
    label: string;
    boxStep: RegisteringStep;
  }

  const Stepbox = ({ boxStep, label }: StepBoxProps) => {
    return (
      <button
        onClick={() => {
          setChangeStep(boxStep);
          setChangePageDialogOpen(true);
        }}
        className="flex items-start gap-3 self-stretch hover:text-opacity-50 hover:bg-red"
        disabled={boxStep > maxCurrentStep || boxStep === step}
      >
        <div className="flex flex-col items-center justify-center">
          <div className="relative flex items-center justify-center w-7 h-7">
            {boxStep < step ? (
              <Check className="absolute z-10 w-6 h-6 p-1 stroke-white stroke-[3px] bg-brand-600 rounded-full border-0" />
            ) : boxStep <= maxCurrentStep ? (
              <>
                {boxStep === step && (
                  <Circle className="absolute w-8 h-8 bg-brand-200 stroke-brand-200 rounded-full" />
                )}
                <Circle className="absolute z-10 w-6 h-6 bg-brand-600 stroke-brand-600 stroke-2 rounded-full" />
                <Circle className="absolute z-20 w-2 h-2 bg-white stroke-white rounded-full" />
              </>
            ) : (
              <>
                <Circle className="absolute w-6 h-6 stroke-gray-200 stroke-2" />
                <Circle className="absolute z-10 w-2 h-2 bg-gray-200 stroke-gray-200 rounded-full" />
              </>
            )}
          </div>

          {boxStep !== RegisteringStep.ACKNOWLEDGEMENT && (
            <EllipsisVertical
              className={classNames("w-6 h-6 stroke-2", {
                "stroke-gray-200": boxStep >= maxCurrentStep,
                "stroke-brand-600": boxStep < maxCurrentStep,
              })}
            />
          )}
        </div>
        {label}
      </button>
    );
  };

  const handleStepNotSavedResult = (userWantsToChangePage: boolean) => {
    if (userWantsToChangePage) changeStep(nextStep);
    setChangePageDialogOpen(false);
  };

  //////////////////
  // progress bar
  //////////////////

  const ProgressBar = () => {
    return (
      <div className="flex flex-col justify-start items-start w-full max-w-[300px]">
        <Stepbox
          label="Vérifiez vos informations"
          boxStep={RegisteringStep.CHECK_INFO}
        />
        <Stepbox
          label="Inscription à l'examen"
          boxStep={RegisteringStep.REGISTER}
        />
        <Stepbox label="Tarif" boxStep={RegisteringStep.PRICING} />
        <Stepbox
          label="Délégation et facturation"
          boxStep={RegisteringStep.PAYEMENT}
        />
        <Stepbox
          label="Attestation de formation"
          boxStep={RegisteringStep.CERTIFICATE}
        />
        <Stepbox
          label="Acceptation des CGU et CGV"
          boxStep={RegisteringStep.ACKNOWLEDGEMENT}
        />
      </div>
    );
  };

  //////////////////
  // context
  //////////////////

  // mimnum api calls to change draft
  const changeDraft = (newOptions: ExamOptionsDto) => {
    const newDraft = {
      ...previousRegistrationDraft,
      ...newOptions,
    };

    if (_.isEqual(newDraft, previousRegistrationDraft)) {
      onNextStep(true);
      return;
    }

    setOptionsMutation.mutate(newOptions, {
      onSuccess: () => {
        onNextStep(true);
      },
      onError: () => {
        toast.error(
          "Une erreur est survenue lors de la modification du brouillon"
        );
      },
    });
  };

  const context: RegisterContext = {
    registrationDraft:
      getOptionsQuery.data === "no-draft" ? {} : getOptionsQuery.data || {},
    changeDraft,
    onNextStep,
    pending: setOptionsMutation.isPending || confirmDraftMutation.isPending,
  };

  //////////////////
  // component
  //////////////////

  if (getOptionsQuery.isLoading || getOptionsQuery.isError)
    return (
      <LoadingSpinner
        isLoading={getOptionsQuery.isLoading}
        loadingMessage="Chargement du brouillon..."
        isError={getOptionsQuery.isError}
        errorMessage="Erreur lors du chargement du brouillon."
      />
    );

  return (
    <>
      <ConfirmDialog
        isOpen={changePageDialogOpen}
        onResult={handleStepNotSavedResult}
        title="Attention"
        message="Si vous changez de page, les changements que vous avez effectués seront perdus."
        validateStr="Changer de page"
        cancelStr="Rester sur cette page"
      />
      <ConfirmDialog
        isOpen={loadDraftDialogOpen}
        onResult={handleLoadDraftResult}
        title="Inscription en cours"
        message="Vous avez déjà une inscription en cours."
        validateStr="Continuer mon inscription"
        cancelStr="Supprimer et recommencer"
      />
      <div className="flex flex-row pt-8 px-28 pb-2  items-start gap-6 self-stretch">
        <div className="flex flex-col items-start gap-1 self-stretch min-w-fit">
          <Breadcrumb>
            <BreadcrumbList>
              <BreadcrumbItem>
                <BreadcrumbPage className="text-sm font-medium text-gray-600">
                  <Link to={ROUTE.candidate.dashboard.exams.home()}>
                    Mes examens
                  </Link>
                </BreadcrumbPage>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage className="font-semibold text-sm text-brand-700">
                  S'inscrire à un examen
                </BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>
          <h1 className="text-2xl">S'inscrire à un examen</h1>
          <ProgressBar />
        </div>
        <div className="flex items-start gap-6 w-full">
          {getOptionsQuery.isSuccess && !loadDraftDialogOpen && (
            <Outlet context={context} />
          )}
        </div>
      </div>
    </>
  );
};

export default Register;
