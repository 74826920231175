import { ExamStatus } from "@/api/enums/exam-status.enum";
import { ExamQCMsDtoClient } from "@/api/qcm/dto/exam-qcms.dto";
import { Badge } from "@/components/atoms/Badge";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/components/atoms/Collapsible";
import GreenCheck from "@/components/atoms/GreenCheck";
import GenerateQcmButton from "@/components/molecules/GenerateQcmButton";
import { ROUTE } from "@/constants/routes";
import { cn } from "@/lib/utils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const GeneratedBadge = () => (
  <Badge variant="green" className="gap-1">
    <GreenCheck className="w-3.5 h-3.5" /> Généré
  </Badge>
);

interface ExamQCMsProps {
  index: number;
  total: number;
  data: ExamQCMsDtoClient;
}

const ExamQCMs = ({ index, total, data: { date, exams } }: ExamQCMsProps) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const totalQCMsCount = exams.reduce(
    (acc, { qcmsCount }) => acc + qcmsCount,
    0
  );
  const allAreGenerated = exams.every(
    ({ exam }) => exam.status !== ExamStatus.QCMS_NOT_GENERATED
  );
  const showGenerateButton = totalQCMsCount > 0 && !allAreGenerated;

  return (
    <Collapsible open={open} onOpenChange={(open) => setOpen(open)}>
      <CollapsibleTrigger
        className={cn(
          "flex flex-row items-center border border-gray-200 w-full text-gray-900",
          {
            "rounded-b-lg": index === total - 1 && !open,
            "rounded-t-lg": index === 0,
            "mt-3": open,
            "border-t-0": index !== 0 && !open,
            "bg-gray-50": !(index % 2),
            "bg-white": index % 2,
          }
        )}
      >
        <div className="flex flex-row items-center justify-between w-full px-4 py-3">
          <span className="flex w-1/3">{date.toLocaleDateString("fr-FR")}</span>
          <div className="w-1/3">
            {!allAreGenerated ? (
              <Badge variant="orange">À générer</Badge>
            ) : (
              <GeneratedBadge />
            )}
          </div>
          <div className="w-1/3">
            {showGenerateButton && <GenerateQcmButton date={date} />}
          </div>
          <div className="">{!open ? <ChevronDown /> : <ChevronUp />}</div>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent
        className={cn("flex flex-col w-full cursor-pointer", {
          "mb-3 border-b": open,
        })}
        onClick={() => navigate(ROUTE.admin.dashboard.qcm(date))}
      >
        <div
          className={cn(
            "flex flex-row items-center w-full border-b border-x border-gray-200 text-gray-500 py-3 px-4",
            {
              "bg-gray-50": index % 2,
            }
          )}
        >
          <span className="flex w-1/3">Session</span>
          <span className="flex w-1/3">Nombre de sujets</span>
          <span className="flex w-1/3">Génération</span>
        </div>
        {exams.map(({ exam, qcmsCount }, i) => (
          <div
            key={i}
            className={cn(
              "flex flex-row items-center w-full border-b border-x border-gray-200 py-3 px-4",
              {
                "border-b-0": i === exams.length - 1,
                "bg-gray-50": !((i + index) % 2),
              }
            )}
          >
            <span className="w-1/3">{exam.number}</span>
            <span className="w-1/3">{qcmsCount}</span>
            {exam.status !== ExamStatus.QCMS_NOT_GENERATED ? (
              <GeneratedBadge />
            ) : (
              <Badge variant="white">Non généré</Badge>
            )}
          </div>
        ))}
      </CollapsibleContent>
    </Collapsible>
  );
};

export default ExamQCMs;
