import { keyFactory } from "@/api/keyFactory";
import { useMutationQcmGenerate } from "@/api/qcm/qcm";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { toast } from "sonner";
import ConfirmDialog from "./ConfirmDialog";

interface GenerateQcmButtonProps {
  date: Date;
  regenerating?: boolean;
}

const GenerateQcmButton = ({ date, regenerating }: GenerateQcmButtonProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const mutation = useMutationQcmGenerate({
    onSuccess: () => {
      toast.success("Questions générées");
      queryClient.invalidateQueries({
        queryKey: keyFactory.qcm.base(),
      });
      setOpen(false);
    },
    onError: () => {
      toast.error("Échec de la requête", {
        description: "Les questions n'ont pas pu être générées",
      });
    },
  });

  return (
    <>
      <ConfirmDialog
        isOpen={open}
        onResult={(accepted) => {
          if (accepted) mutation.mutate({ date });
          else setOpen(false);
        }}
        title={`${regenerating ? "Re-générer" : "Générer"} les questions`}
        validateStr="Générer"
        cancelStr="Annuler"
        close={() => setOpen(false)}
        validatePending={mutation.isPending}
      />
      <span
        className="text-brand-800 font-semibold hover:text-brand-600 hover:cursor-pointer"
        onClick={(e) => {
          setOpen(true);
          e.stopPropagation();
        }}
      >
        {regenerating ? "Re-générer" : "Générer"} les questions
      </span>
    </>
  );
};

export default GenerateQcmButton;
