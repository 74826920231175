import {
  email,
  mandatoryString,
  optionalPhone,
  optionalString,
} from "@/constants/zodTypes";
import { z } from "zod";

const delegationSchema = z.object({
  name: optionalString(),
  surname: optionalString(),
  email: email(),
  phoneNumber: optionalPhone(),
  companyName: mandatoryString(),
});

const delegationDefaultValues = {
  name: "",
  surname: "",
  email: "",
  phoneNumber: "",
  companyName: "",
};

export { delegationDefaultValues, delegationSchema };
