import {
  useConstantQuery,
  useConstantSetMutation,
} from "@/api/constant/constant";
import { ConstantKey, ConstantZodSchema } from "@/api/constant/constants";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import { Form } from "@/components/atoms/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";
import RoundedInput from "./RoundedInput";

const schema = ConstantZodSchema[ConstantKey.AdmissionNotes];
type Schema = z.infer<typeof schema>;

const AdmissionNotes = () => {
  const [modifiable, setModifiable] = useState(false);
  const queryClient = useQueryClient();

  const query = useConstantQuery(ConstantKey.AdmissionNotes);
  const changeMutation = useConstantSetMutation();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      qcmShort: 0,
      qcmMedium: 0,
      qcmLong: 0,
      edcWithoutSpecialty: 0,
      edcSpecialty1: 0,
      edcSpecialty2: 0,
    },
  });

  useEffect(() => {
    if (!query.data) return;
    form.reset(query.data);
  }, [query.data, form, modifiable]);

  const onSubmit = (data: Schema) => {
    changeMutation.mutate(
      {
        key: ConstantKey.AdmissionNotes,
        value: data,
      },
      {
        onError: () => {
          toast.error("Échec de la requête", {
            description: "Les notes  d'admission n'ont pas pu être modifiées",
          });
        },
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: keyFactory.constant.byKey(ConstantKey.AdmissionNotes),
          });
          toast.success("Notes d'admission modifiées");
          setModifiable(false);
        },
      }
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between items-center">
        <h2>Notes d'admission</h2>

        <Button
          onClick={() => setModifiable(!modifiable)}
          className="flex w-fit"
          variant="ghostWithBorders"
          disabled={!query.data}
        >
          {!modifiable ? "Modifier" : "Annuler"}
        </Button>
      </div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex flex-col gap-3"
        >
          <div className="flex flex-row gap-3 w-full">
            <RoundedInput
              modifiable={modifiable}
              name="qcmShort"
              title="QCM court"
              total={50}
            />
            <RoundedInput
              modifiable={modifiable}
              name="qcmMedium"
              title="QCM intermédiaire"
              total={65}
            />
            <RoundedInput
              modifiable={modifiable}
              name="qcmLong"
              title="QCM long"
              total={80}
            />
          </div>
          <RoundedInput
            modifiable={modifiable}
            name="edcWithoutSpecialty"
            title="EDC Sans spécialité"
          />
          <span>EDC avec spécialité</span>
          <div className="flex flex-row gap-3 w-full">
            <RoundedInput
              modifiable={modifiable}
              name="edcSpecialty1"
              title="Spécialité 1"
            />
            <RoundedInput
              modifiable={modifiable}
              name="edcSpecialty2"
              title="Spécialité 2"
            />
          </div>
          {modifiable && (
            <Button type="submit" disabled={changeMutation.isPending}>
              {changeMutation.isPending
                ? "Modification..."
                : "Sauvegarder les modifications"}
            </Button>
          )}
        </form>
      </Form>
    </div>
  );
};

export default AdmissionNotes;
