import { Button } from "@/components/atoms/Button";
import { Plus } from "lucide-react";
import { useState } from "react";
import FormationInstitutesTable from "./FormationInstitutesTable";
import AddInstituteDialog from "./AddInstituteDialog";

const FormationInstitutes = () => {
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  return (
    <div className="flex flex-col gap-6 h-[95%]">
      <div className="flex flex-row justify-between">
        <h2>Organismes de formation</h2>
        <Button className="flex w-fit" onClick={() => setAddDialogOpen(true)}>
          <Plus />
          Ajouter
        </Button>
      </div>
      <FormationInstitutesTable />
      <AddInstituteDialog
        isOpen={addDialogOpen}
        close={() => setAddDialogOpen(false)}
      />
    </div>
  );
};

export default FormationInstitutes;
