import { mandatoryAddressSchema } from "@/components/molecules/AddressForm/AddressFormSchema";
import {
  email,
  mandatoryString,
  optionalPhone,
  optionalString,
} from "@/constants/zodTypes";
import { z } from "zod";

const companyModificationSchema = z.object({
  address: mandatoryAddressSchema,
  personName: optionalString(),
  personSurname: optionalString(),
  phoneNumber: optionalPhone(),
  email: email(),
  name: mandatoryString(),
});

type CompanyModification = z.infer<typeof companyModificationSchema>;

export { companyModificationSchema };
export type { CompanyModification };
