import { RegistrationType } from "@/api/enums/RegistrationType";
import { keyFactory } from "@/api/keyFactory";
import { useMutationRegistrationReport } from "@/api/registration/registration";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import ExamDateCombobox from "@/components/molecules/ExamDateCombobox";
import { id } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { Calendar } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const reportSchema = z.object({
  newExamId: id(),
});

type ReportSchema = z.infer<typeof reportSchema>;

interface ReportDialogProps {
  registrationId: number;
  registrationType: RegistrationType;
  currentExamId: number;
  candidateId: number;
}

const ReportDialog = ({
  registrationId,
  registrationType,
  currentExamId,
  candidateId,
}: ReportDialogProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();

  const reportMutation = useMutationRegistrationReport();

  const form = useForm<ReportSchema>({
    resolver: zodResolver(reportSchema),
    defaultValues: {
      newExamId: -1,
    },
  });

  const onSubmit = (data: ReportSchema) => {
    reportMutation.mutate(
      {
        registrationId,
        ...data,
      },
      {
        onSuccess: () => {
          toast.success("Inscription reportée");
          queryClient.invalidateQueries({
            queryKey: keyFactory.candidate.info(candidateId),
          });
          queryClient.invalidateQueries({
            queryKey: keyFactory.exam.registrations(),
          });
          setOpen(false);
        },
        onError: () => {
          toast.error("Échec de la requête", {
            description: "Votre action n'a pas pu être prise en compte.",
          });
        },
      }
    );
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger
        className="flex flex-row items-center p-2 gap-2"
        onClick={(e) => e.stopPropagation()}
      >
        <Calendar className="w-4 h-4" />
        Reporter à une autre session
      </DialogTrigger>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>Report de l'inscription</DialogTitle>
          <DialogDescription hidden={true}>
            Report de l'inscription
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-2"
          >
            <ExamDateCombobox
              registrationType={registrationType}
              fieldName="newExamId"
              forbiddenExamIds={new Set([currentExamId])}
            />
            <div className="flex flex-row gap-2">
              <Button
                variant="ghostWithBorders"
                type="button"
                onClick={() => setOpen(false)}
                className="w-full"
              >
                Annuler
              </Button>
              <Button
                type="submit"
                className="w-full"
                disabled={reportMutation.isPending}
              >
                {reportMutation.isPending
                  ? "En cours..."
                  : "Reporter l'inscription"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ReportDialog;
