import { LoginResponse } from "@/api/auth/dto/login.dto";
import { QueryClient } from "@tanstack/react-query";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { Role } from "../api/enums/Role";

interface Session {
  email: string;
  role: Role;
}

interface AuthState {
  session?: Session;
}

interface AuthActions {
  logout: (queryClient?: QueryClient) => void;
  login: (email: string, loginResponse: LoginResponse) => void;
  setNewEmail: (newEmail: string) => void;
}

export const useAuthStore = create(
  persist<AuthState & AuthActions>(
    (set) => ({
      session: undefined,
      logout: (queryClient?: QueryClient) => {
        if (queryClient) queryClient.clear();

        set(() => ({
          session: undefined,
        }));
      },
      login: (email, { role }) => {
        set(() => ({
          session: {
            role,
            email,
          },
        }));
      },
      setNewEmail: (newEmail: string) =>
        set(({ session }) => ({
          session: session ? { ...session, email: newEmail } : undefined,
        })),
    }),
    {
      // storing zustand in local storage for persistence
      name: "auth-storage",
    }
  )
);
