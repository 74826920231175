import { AxiosError, HttpStatusCode } from "axios";
import { z } from "zod";
import { email, mandatoryString } from "../../../constants/zodTypes";

const connectionSchema = z.object({
  email: email(),
  key: mandatoryString(),
});

const onErrorConnect = (error: AxiosError): string => {
  switch (error.status) {
    case HttpStatusCode.Unauthorized:
      return "Identifiants incorrects.";

    case HttpStatusCode.InternalServerError:
      return "Le serveur a rencontré une erreur lors de la connexion.";

    default:
      return "Une erreur est survenue lors de la connexion.";
  }
};

type ConnectionSchema = z.infer<typeof connectionSchema>;

const connectionSchemaDefaultValues: ConnectionSchema = {
  email: "",
  key: "",
};

export { connectionSchema, connectionSchemaDefaultValues, onErrorConnect };
export type { ConnectionSchema };
