import { replaceNullsWith } from "@/api/app";
import {
  useMutationCandidateChangeInfo,
  useQueryCandidateInfo,
} from "@/api/candidate/candidate";
import { keyFactory } from "@/api/keyFactory";
import { Form } from "@/components/atoms/Form";
import { AddressFields } from "@/components/molecules/AddressForm/AddressFields";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  optionalAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import { QueryLoadingSpinner } from "@/components/molecules/LoadingSpinner";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { PersonalInfoField } from "@/components/molecules/PersonalInfoForm/PersonalInfoFields";
import PersonalInformationForm from "@/components/molecules/PersonalInfoForm/PersonalInfoForm";
import {
  personalInfoDefaultValues,
  personalInfoSchema,
} from "@/components/molecules/PersonalInfoForm/PersonalInfoFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import _ from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const accountModificationSchema = z.object({
  address: optionalAddressSchema,
  personalInfo: personalInfoSchema,
});

type AccountModificationSchema = z.infer<typeof accountModificationSchema>;

const PersonalInformation = () => {
  const { id: idStr } = useParams();
  const candidateId = Number(idStr);
  const queryClient = useQueryClient();
  const [PIIsModifiable, setPIIsModifiable] = useState(false);
  const [addressIsModifiable, setAddressIsModifiable] = useState(false);

  /////////////
  // api calls
  /////////////

  const getCandidateQuery = useQueryCandidateInfo(candidateId);

  const changeCandidateInfoMutation = useMutationCandidateChangeInfo(
    candidateId,
    {
      onSuccess: async () => {
        toast.success("Informations modifiées avec succès");

        queryClient.invalidateQueries({
          queryKey: keyFactory.candidate.info(candidateId),
        });
        queryClient.invalidateQueries({
          queryKey: keyFactory.candidate.basicInfo(candidateId),
        });

        setAddressIsModifiable(false);
        setPIIsModifiable(false);
      },
      onError: () => {
        toast.error("Erreur", {
          description:
            "Les informations du candidat n'ont pas pu être modifiées",
        });
      },
    }
  );

  /////////////
  // form
  /////////////

  const form = useForm<AccountModificationSchema>({
    resolver: zodResolver(accountModificationSchema),
    defaultValues: {
      address: addressDefaultValues,
      personalInfo: personalInfoDefaultValues,
    },
  });

  const { data: candidateInfo } = getCandidateQuery;
  const { reset, watch } = form;

  const resetForm = useCallback(() => {
    const newState = watch();

    if (candidateInfo) {
      newState.address = _.merge(
        addressDefaultValues,
        replaceNullsWith(candidateInfo.address, "")
      );
      newState.personalInfo = _.merge(
        personalInfoDefaultValues,
        replaceNullsWith(candidateInfo.personalInfo, "")
      );
    }

    reset(newState);
  }, [candidateInfo, reset, watch]);

  useEffect(resetForm, [resetForm]);

  const onSubmit = (data: AccountModificationSchema) => {
    changeCandidateInfoMutation.mutate(data);
  };

  if (getCandidateQuery.isLoading || getCandidateQuery.isError)
    return (
      <QueryLoadingSpinner
        errorMessage="Erreur lors du chargement du profil candidat"
        loadingMessage="Chargement du profil candidat..."
        queries={[getCandidateQuery]}
      />
    );

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col gap-8 p-4"
      >
        <ModifiableForm
          setModifiable={(b: boolean) => {
            setAddressIsModifiable(false);
            resetForm();
            setPIIsModifiable(b);
          }}
          isModifiable={PIIsModifiable}
          onCancel={resetForm}
          modifyText="Modifier"
          saveText="Sauvegarder"
        >
          <PersonalInformationForm
            hiddenFields={[
              PersonalInfoField.Password,
              PersonalInfoField.PasswordConfirmation,
              PersonalInfoField.Email,
              PersonalInfoField.EmailConfirmation,
            ]}
            disabledFields={
              PIIsModifiable ? [] : Object.values(PersonalInfoField)
            }
          />
        </ModifiableForm>
        <ModifiableForm
          setModifiable={(b: boolean) => {
            setPIIsModifiable(false);
            resetForm();
            setAddressIsModifiable(b);
          }}
          isModifiable={addressIsModifiable}
          onCancel={resetForm}
          modifyText="Modifier"
          saveText="Sauvegarder"
        >
          <AddressForm
            fieldName="address"
            disabledFields={
              addressIsModifiable ? [] : Object.values(AddressFields)
            }
          />
        </ModifiableForm>
      </form>
    </Form>
  );
};

export default PersonalInformation;
