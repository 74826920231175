import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Badge } from "@/components/atoms/Badge";

interface PreTestStatusBadgeProps {
  status: ValidationStatus;
}

const PreTestStatusBadge = ({ status }: PreTestStatusBadgeProps) => {
  switch (status) {
    case ValidationStatus.UNAVAILABLE:
      return <Badge variant="white">Non disponible</Badge>;
    case ValidationStatus.PENDING:
      return <Badge variant="orange">En attente de validation</Badge>;
    case ValidationStatus.VALIDATED:
      return <Badge variant="green">Validé</Badge>;
  }
};

export default PreTestStatusBadge;
