import { useMutationExamResultCancelQuestion } from "@/api/exam-result/exam-result";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Input } from "@/components/atoms/Input";
import ConfirmDialog from "@/components/molecules/ConfirmDialog";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import { MessageCircleQuestion } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface CancelQuestionDialogProps {
  examId: number;
}

const CancelQuestionDialog = ({ examId }: CancelQuestionDialogProps) => {
  const [open, setOpen] = useState(false);
  const queryClient = useQueryClient();
  const [questionNumber, setQuestionNumber] = useState<number | undefined>(
    undefined
  );
  const [confirmOpen, setConfirmOpen] = useState(false);

  const { isPending, mutate } = useMutationExamResultCancelQuestion({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: keyFactory.exam.base(),
      });

      queryClient.invalidateQueries({
        queryKey: keyFactory.registration.base(),
      });

      setOpen(false);
      toast.success(`Question ${questionNumber} annulée`, {
        description: "Les documents affectés ont été mis à jour",
      });
    },
    onError: (e) => {
      switch (e.response?.status) {
        case HttpStatusCode.NotFound:
          toast.error("Question non trouvée", {
            description: `Aucune question avec le numéro ${questionNumber} n'a été trouvée.`,
          });
          return;

        case HttpStatusCode.Conflict:
          toast.error("Question déjà annulée", {
            description: `La question ${questionNumber} a déjà été annulée pour cet examen.`,
          });
          return;
      }

      toast.error("Erreur", {
        description: "La question n'a pas pu être annulée",
      });
    },
  });

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button
          variant="ghostWithBorders"
          className="flex flex-row items-center gap-2"
        >
          <MessageCircleQuestion className="w-5 h-5" />
          <span className="text-gray-800 font-semibold">Annuler Question</span>
        </Button>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-6 p-4"
      >
        <DialogHeader>
          <DialogTitle>Annuler une question</DialogTitle>
          <DialogDescription hidden={true}>
            Annuler une question
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <label htmlFor="questionNumberInput">Numéro de question</label>
          <Input
            value={questionNumber ?? "-"}
            onChange={(e) => setQuestionNumber(Number(e.target.value))}
            placeholder="Numéro de question"
            type="number"
            id="questionNumberInput"
          />
        </div>
        <div className="flex flex-row gap-2 w-full">
          <Button
            disabled={isPending}
            variant="ghostWithBorders"
            onClick={() => setOpen(false)}
            className="w-1/2"
          >
            Annuler
          </Button>
          <Button
            onClick={() => {
              if (questionNumber !== undefined) setConfirmOpen(true);
              else toast.warning("Veuillez saisir un numéro de question");
            }}
            disabled={isPending}
            className="w-1/2"
            variant="red"
          >
            {isPending ? "En cours..." : "Confirmer l'annulation"}
          </Button>
        </div>
        <ConfirmDialog
          isOpen={confirmOpen}
          close={() => setConfirmOpen(false)}
          title="Confirmer"
          message={`Annuler la question ${questionNumber} ?`}
          validateStr="Oui"
          validateButtonVariant="red"
          cancelStr="Non"
          validatePending={isPending}
          onResult={(confirmed) => {
            if (questionNumber !== undefined && confirmed)
              mutate({ examId, questionNumber });
            setConfirmOpen(false);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default CancelQuestionDialog;
