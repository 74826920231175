import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
} from "@tanstack/react-query";
import axios from "axios";
import { keyFactory } from "../keyFactory";
import { ConstantKey, ConstantType, ConstantZodSchema } from "./constants";

// get

const get = async <K extends ConstantKey>(key: K): Promise<ConstantType[K]> => {
  return await axios
    .get<ConstantType[K]>(ENDPOINT.constant.get(key))
    .then((res) => res.data);
};

export const useConstantQuery = <K extends ConstantKey>(key: K) => {
  return useQuery<ConstantType[K]>({
    queryKey: keyFactory.constant.byKey(key),
    queryFn: () => get(key),
    staleTime: Infinity,
    gcTime: Infinity,
  });
};

// set

const set = async (key: ConstantKey, value: ConstantType[typeof key]) => {
  try {
    const schema = ConstantZodSchema[key];
    schema.parse(value);
  } catch (e) {
    console.error(e);
    throw new Error(`Invalid constant format : ${e}`);
  }

  await axios.post(ENDPOINT.constant.post(key), value);
};

export const useConstantSetMutation = (
  props?: Partial<
    UseMutationOptions<
      void,
      Error,
      { key: ConstantKey; value: ConstantType[ConstantKey] }
    >
  >
) => {
  return useMutation<
    void,
    Error,
    { key: ConstantKey; value: ConstantType[ConstantKey] }
  >({
    mutationFn: ({ key, value }) => set(key, value),
    ...props,
  });
};
