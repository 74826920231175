import { io } from "socket.io-client";

const API_URL = import.meta.env.VITE_API_URL;
const API_PORT = import.meta.env.VITE_API_PORT;

const socket = io(`${API_URL}:${API_PORT}`, {
  withCredentials: true,
  autoConnect: false,
});

socket.on("connect", () => {
  console.log("Connected to WebSocket server");
});

socket.on("connect_error", (error: any) => {
  console.error("Error connecting to WebSocket server", error);
});

socket.on("disconnect", () => {
  console.log("Disconnected from WebSocket server");
});

socket.on("message", (data: string) => {
  console.log("Received message:", data);
});

socket.on("exception", (err) => {
  console.error("Websocket exception:", err);
});

export { socket };
