import { ExamStatus } from "@/api/enums/exam-status.enum";
import { CheckCircle2, User2, X } from "lucide-react";
import { useSessionContext } from "../session-context";

export const InfoCards = () => {
  const {
    exam: { registeredCount, successCount, status, preRegisteredCount },
  } = useSessionContext();

  const isDone = [
    ExamStatus.CERTIFICATES_GENERATED,
    ExamStatus.CORRECTED,
    ExamStatus.NOTES_GENERATED,
  ].includes(status);

  return isDone ? (
    <div className="flex flex-row gap-4">
      <div className="flex flex-row justify-between gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full items-center">
        <span className="text-gray-600">Taux de réussite</span>
        <span className="text-gray-950 font-semibold text-2xl">
          {registeredCount
            ? ((successCount / registeredCount) * 100).toFixed(1)
            : "-"}
          %
        </span>
      </div>
      <div className="flex flex-row justify-between gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full items-center">
        <span className="text-gray-600">Candidats admis</span>
        <span className="text-gray-950 font-semibold text-2xl">
          {successCount}
        </span>
      </div>
    </div>
  ) : (
    <div className="flex flex-row gap-4">
      <div className="flex flex-col gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full">
        <div className="flex flex-row justify-between">
          <span className="text-gray-600 font-medium">Nb candidats</span>
          <User2 className="text-brand-600" />
        </div>
        <span className="text-xl font-semibold text-gray-950">
          {preRegisteredCount}
        </span>
      </div>
      <div className="flex flex-col gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full">
        <div className="flex flex-row justify-between">
          <span className="text-gray-600 font-medium">Candidats validés</span>
          <CheckCircle2 className="text-brand-600" />
        </div>
        <span className="text-xl font-semibold text-gray-950">
          {registeredCount}
        </span>
      </div>
      <div className="flex flex-col gap-2 py-4 px-6 border border-gray-200 rounded-lg w-full">
        <div className="flex flex-row justify-between">
          <span className="text-gray-600 font-medium">
            Candidats non validés
          </span>
          <X className="text-brand-600" />
        </div>
        <span className="text-xl font-semibold text-gray-950">
          {preRegisteredCount - registeredCount}
        </span>
      </div>
    </div>
  );
};

export default InfoCards;
