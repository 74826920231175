import { DocumentType } from "@/api/dto/document-type.enum";
import { FileDto } from "@/api/dto/file.dto";
import { FormationType } from "@/api/enums/FormationType";
import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { Form } from "@/components/atoms/Form";
import FormationAttestationsForm from "@/components/molecules/FormationAttestationsForm/FormationAttestationsForm";
import { formationAttestationsSchema } from "@/components/molecules/FormationAttestationsForm/FormationAttestationsFormSchema";
import useFileUpload from "@/hooks/FileUpload";
import { Perimeter } from "@/lib/perimeter";
import { zodResolver } from "@hookform/resolvers/zod";
import { Info } from "lucide-react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import NextStepButton from "../NextStepButtons";
import { useRegisterContext } from "../RegisterContext";

const Certificate = () => {
  const { changeDraft, onNextStep, registrationDraft, pending } =
    useRegisterContext();

  const { ensureUploaded, uploadIsPending } = useFileUpload(
    DocumentType.FORMATION_ATTESTATION
  );

  // form

  const form = useForm<z.infer<typeof formationAttestationsSchema>>({
    resolver: zodResolver(formationAttestationsSchema),
    defaultValues: {
      attestations: registrationDraft.formationCertificates ?? [],
      allAttestationsProvided:
        registrationDraft.allAttestationsProvided ?? false,
    },
  });

  const onSubmit = async ({
    attestations: data,
    allAttestationsProvided: allAttestationsProvided,
  }: z.infer<typeof formationAttestationsSchema>) => {
    const uploadedFiles = await ensureUploaded(
      ...data.map(({ certificate }) => certificate)
    );

    if (uploadedFiles.some((file) => !file)) {
      console.error("error while uploading files");
      return;
    }

    changeDraft({
      allAttestationsProvided: allAttestationsProvided && data.length > 0, // can't say all certificates provided if no certificate provided
      formationCertificates: data.map(
        ({ formationType, perimeter, formationInstituteId }, i) => ({
          formationType,
          ...(formationType === FormationType.INSTITUTE && {
            formationInstituteId: formationInstituteId as number,
          }),
          perimeter,
          certificate: uploadedFiles[i] as FileDto,
        })
      ),
    });
  };

  // component

  return (
    <div className="flex flex-col gap-8 items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Attestation de formation</h1>
            <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
              <Info className="w-5 h-5 flex-shrink-0" />
              <div className="flex flex-col">
                <span className="font-bold">Dépôt obligatoire</span>
                <span className="text-gray-600 font-normal">
                  Vous devez déposer votre ou vos attestation(s) de formation
                  dès que possible et au plus tard 7 jours avant la date de
                  session d'examen. Si ce délai devait être dépassé, vous devez
                  nous contacter par mail. Il est recommandé de privilégier un
                  dépôt unique, regroupant toutes vos attestations en un seul
                  document
                </span>
              </div>
            </div>
            <FormationAttestationsForm
              forbiddenFormationTypes={
                registrationDraft.type === RegistrationType.RENEW
                  ? []
                  : [FormationType.ON_HONOR]
              }
              hiddenPerimeter={
                registrationDraft.type === RegistrationType.INITIAL
                  ? new Perimeter([], [MerchandiseClass.HYDROCARBONS])
                  : undefined
              }
            />
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data))}
            onPrevious={() => onNextStep(false)}
            pending={pending || uploadIsPending}
          />
        </form>
      </Form>
    </div>
  );
};

export default Certificate;
