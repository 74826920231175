import { removeNullsDeep } from "@/api/app";
import {
  useCompanyPrefilledInfoQuery,
  useCompanyRegisterMutation,
} from "@/api/auth/auth";
import {
  CompanyPreFilledInfo,
  CompanyPreFilledInfoData,
} from "@/api/auth/dto/register-company.dto";
import { Button } from "@/components/atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  mandatoryAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import ErrorDisplay from "@/components/molecules/ErrorDisplay";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { PhoneInput } from "@/components/molecules/PhoneInput";
import HelloPageLayout from "@/components/templates/hello-page";
import { ROUTE } from "@/constants/routes";
import {
  email,
  mandatoryString,
  optionalPhone,
  optionalString,
} from "@/constants/zodTypes";
import { useAuthStore } from "@/hooks/AuthStore";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { HttpStatusCode } from "axios";
import _ from "lodash";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const companyInfoSchema = z.object({
  address: mandatoryAddressSchema,
  personName: optionalString(),
  personSurname: optionalString(),
  phoneNumber: optionalPhone(),
  email: email(),
});

const keyFormSchema = z
  .object({
    key: mandatoryString(),
    keyConfirmation: mandatoryString(),
  })
  .superRefine(({ key, keyConfirmation }, ctx) => {
    if (key != keyConfirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Les mots de passe ne correspondent pas",
        path: ["keyConfirmation"],
      });
    }
  });

enum FormPage {
  Key,
  Info,
}

interface AccountAlreadyCreatedProps {
  email: string;
}

const AccountAlreadyCreated = ({ email }: AccountAlreadyCreatedProps) => (
  <span>
    Un compte associé à l'adresse email{" "}
    <span className="font-bold">{email}</span> a déjà été créé.
  </span>
);

interface KeyFormProps {
  prefilledData: CompanyPreFilledInfo;
  onNext: (key: string) => void;
}

const KeyForm = ({ onNext, prefilledData: { data, email } }: KeyFormProps) => {
  const navigate = useNavigate();

  ///////////////////////////////
  // form
  ///////////////////////////////

  const keyForm = useForm<z.infer<typeof keyFormSchema>>({
    resolver: zodResolver(keyFormSchema),
    defaultValues: {
      key: "",
      keyConfirmation: "",
    },
  });

  const { control } = keyForm;

  const onSubmit = ({ key }: z.infer<typeof keyFormSchema>) => onNext(key);

  ///////////////////////////////
  // component
  ///////////////////////////////

  if (data === "already-created") return <></>;

  return (
    <Form {...keyForm}>
      <form
        onSubmit={keyForm.handleSubmit(onSubmit)}
        className="flex w-full flex-col space-y-6"
      >
        <div className="text-center">
          <h1 className="text-2xl mb-3">Première connexion</h1>
          <span className="text-gray-900">
            Vous avez été désigné comme le réprésentant de l’entreprise{" "}
            <span className="font-bold">{data.companyName}</span> avec l’adresse{" "}
            <span className="font-bold">{email}</span>
          </span>
        </div>
        <FormField
          control={control}
          name="key"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Créez un mot de passe</FormLabel>
              <FormControl>
                <Input
                  autoComplete="password"
                  type="password"
                  placeholder={"*****"}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="keyConfirmation"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Confirmez le mot de passe</FormLabel>
              <FormControl>
                <Input
                  autoComplete="password"
                  type="password"
                  placeholder={"*****"}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button
          variant="default"
          className="font-semibold text-base"
          type="submit"
        >
          Créez votre compte
        </Button>
        <Button
          variant="blueLink"
          className="font-semibold text-base"
          type="button"
          onClick={() => navigate(ROUTE.connectionHome())}
        >
          J'ai déjà un compte
        </Button>
      </form>
    </Form>
  );
};

interface InfoFormProps {
  email: string;
  prefilledData: CompanyPreFilledInfoData;
  onNext: (info: z.infer<typeof companyInfoSchema>) => void;
}

const InfoForm = ({ email, prefilledData, onNext }: InfoFormProps) => {
  const { name, phoneNumber, surname } = prefilledData;

  const infoForm = useForm<z.infer<typeof companyInfoSchema>>({
    resolver: zodResolver(companyInfoSchema),
    defaultValues: _.merge(
      {
        phoneNumber: "",
        email: "",
        address: addressDefaultValues,
        personName: "",
        personSurname: "",
      },
      removeNullsDeep({
        personName: name,
        personSurname: surname,
        phoneNumber,
        email,
      })
    ),
  });

  const { control } = infoForm;

  const onSubmit = (data: z.infer<typeof companyInfoSchema>) => onNext(data);

  return (
    <>
      {" "}
      <Form {...infoForm}>
        <form
          onSubmit={infoForm.handleSubmit(onSubmit)}
          className="flex w-full flex-col space-y-6"
        >
          <div className="text-center">
            <h1 className="text-2xl mb-8">Entrez vos informations</h1>
            <div className="flex flex-row text-left gap-8">
              <div className="flex flex-col w-1/2">
                <h2>Informations</h2>
                <div className="flex flex-col gap-1">
                  <FormField
                    control={control}
                    name="personName"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Nom</FormLabel>
                        <FormControl>
                          <Input placeholder={"-"} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="personSurname"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Prénom</FormLabel>
                        <FormControl>
                          <Input placeholder={"-"} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Mobile</FormLabel>
                        <FormControl>
                          <PhoneInput
                            defaultCountry="FR"
                            placeholder={"01 XX XX XX XX"}
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={control}
                    name="email"
                    render={({ field }) => (
                      <FormItem className="w-full">
                        <FormLabel>Adresse email</FormLabel>
                        <FormControl>
                          <Input placeholder={"-"} {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className="w-1/2">
                <AddressForm fieldName="address" title="Adresse facturation" />
              </div>
            </div>
          </div>
          <Button
            variant="default"
            className="font-semibold text-base"
            type="submit"
          >
            Suivant
          </Button>
        </form>
      </Form>
    </>
  );
};

const AccountCreation = () => {
  const [searchParams] = useSearchParams();
  const [errorMessage, setErrorMessage] = useState("");
  const [currentForm, setCurrentPage] = useState<FormPage>(FormPage.Key);
  const [key, setKey] = useState("");
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { logout } = useAuthStore();

  const token = searchParams.get("token");
  if (!token) setErrorMessage("Invalid link : missing token");

  ///////////////////////////////
  // api calls
  ///////////////////////////////

  const prefilledInfoQuery = useCompanyPrefilledInfoQuery(token || "");
  const { data: prefilledData } = prefilledInfoQuery;
  const registerMutation = useCompanyRegisterMutation();

  ///////////////////////////////
  // component
  ///////////////////////////////

  // query waiting
  if (!prefilledData)
    return (
      <LoadingSpinner
        isError={prefilledInfoQuery.isError}
        isLoading={prefilledInfoQuery.isLoading}
        loadingMessage="Chargement des informations..."
        errorMessage="Erreur lors du chargement des informations."
      />
    );

  // account with that email was already created
  if (prefilledData.data === "already-created")
    return <AccountAlreadyCreated email={prefilledData.email} />;

  const forms = {
    [FormPage.Key]: (
      <KeyForm
        onNext={(key) => {
          setKey(key);
          setCurrentPage(FormPage.Info);
        }}
        prefilledData={prefilledData}
      />
    ),
    [FormPage.Info]: (
      <InfoForm
        email={prefilledData.email}
        prefilledData={prefilledData.data}
        onNext={(info) => {
          if (prefilledData.data === "already-created") {
            console.error("invalid state");
            return;
          }

          registerMutation.mutate(
            {
              token: token as string,
              data: {
                userInfo: {
                  key: key,
                  email: info.email,
                },
                address: info.address,
                personName: info.personName,
                personSurname: info.personSurname,
                phoneNumber: info.phoneNumber,
                name: prefilledData.data.companyName || "",
              },
            },
            {
              onSuccess: () => {
                toast.success("Compte créé", {
                  description: "Vous pouvez y accéder en vous connectant",
                });

                logout(queryClient);
                navigate(ROUTE.connectionHome());
              },
              onError: ({ status }) => {
                switch (status) {
                  case HttpStatusCode.Conflict:
                    setErrorMessage(
                      "Un compte associé à cet email existe déjà."
                    );
                    break;

                  case HttpStatusCode.InternalServerError:
                    setErrorMessage(
                      "Le serveur a rencontré une erreur lors de la création de votre compte."
                    );
                    break;

                  default:
                    setErrorMessage(
                      "Erreur lors de la création de votre compte."
                    );
                    break;
                }
              },
            }
          );
        }}
      />
    ),
  };

  return (
    <HelloPageLayout
      className={cn({
        "max-w-md": currentForm === FormPage.Key,
        "max-w-3xl": currentForm === FormPage.Info,
      })}
      useBackButton={false}
    >
      {forms[currentForm]}
      {registerMutation.isError && <ErrorDisplay msg={errorMessage} />}
    </HelloPageLayout>
  );
};

export default AccountCreation;
