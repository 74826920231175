import ExportCopyDialog from "./ExportCopyDialog";

const Export = () => {
  return (
    <div className="flex flex-col gap-4">
      <h2>Exports</h2>
      <div className="flex flex-col rounded-lg border border-gray-200 overflow-auto">
        <div className="px-4 py-3 bg-gray-50">
          <span className="text-gray-500">QCM</span>
        </div>
        <div className="px-4 py-3 border-t border-gray-200 flex flex-row justify-between items-center  text-gray-900">
          <span>Export des copies complètes et partielles</span>
          <ExportCopyDialog />
        </div>
      </div>
    </div>
  );
};

export default Export;
