import { useConstantQuery } from "@/api/constant/constant";
import { ConstantKey } from "@/api/constant/constants";
import { AddressDto } from "@/api/dto/address.dto";
import { ExamType } from "@/api/enums/ExamType";
import { PriceType } from "@/api/enums/PriceType";
import { Role } from "@/api/enums/Role";
import { keyFactory } from "@/api/keyFactory";
import { useMutationPayementTransferDone } from "@/api/payement/payement";
import { Button } from "@/components/atoms/Button";
import { Form } from "@/components/atoms/Form";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@atoms/Dialog";
import { zodResolver } from "@hookform/resolvers/zod";
import { DialogDescription } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { Check } from "lucide-react";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import AddressForm from "../AddressForm/AddressForm";
import {
  addressDefaultValues,
  mandatoryAddressSchema,
  MandatoryAddressSchema,
} from "../AddressForm/AddressFormSchema";
import ConfirmDialog from "../ConfirmDialog";
import CandidateDisplay from "./CandidateDisplay";
import TransferInfo from "./TransferInfo";

interface PayingDialogProps {
  isOpen: boolean;
  close: () => void;
  candidateFirstName: string;
  candidateLastName: string;
  registrationId: number;
  examDate: Date;
  examType: ExamType;
  priceType: PriceType;
  registrationEndDate: Date;
  // is this for a company or a candidate ?
  role: Role;
}

const PayingDialog = ({
  isOpen,
  close,
  candidateFirstName,
  candidateLastName,
  examDate,
  examType,
  priceType,
  registrationEndDate,
  registrationId,
  role,
}: PayingDialogProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const queryClient = useQueryClient();
  const [billingAddress, setBillingAddress] = useState<AddressDto | null>(null);

  const { data: prices } = useConstantQuery(ConstantKey.Prices);

  const price = useMemo(() => {
    if (!prices) return undefined;

    if (priceType === PriceType.FULL) {
      if (examType === ExamType.RENEW) return prices.renewing.full;
      else return prices.initial.full;
    } else {
      if (examType === ExamType.RENEW) return prices.renewing.reduced;
      else return prices.initial.reduced;
    }
  }, [priceType, examType, prices]);

  // form

  const form = useForm<MandatoryAddressSchema>({
    resolver: zodResolver(mandatoryAddressSchema),
    defaultValues: addressDefaultValues,
  });

  const onSubmit = (data: MandatoryAddressSchema) => {
    setBillingAddress(data);
    setConfirmDialogOpen(true);
  };

  // api calls

  const onSuccess = async () => {
    toast.success("Virement pris en compte", {
      description: "Le CIFMD vous confirmera bonne réception de celui-ci.",
    });

    queryClient.invalidateQueries({
      queryKey: keyFactory.company.delegations(),
    });

    queryClient.invalidateQueries({
      queryKey: keyFactory.candidate.registrations(),
    });

    close();
  };

  const onError = () => {
    toast.error("Échec de la requête", {
      description: "Votre action n'a pas pu être prise en compte.",
    });
  };

  const transferDoneMutation = useMutationPayementTransferDone({
    onSuccess,
    onError,
  });

  return (
    <Dialog open={isOpen}>
      <DialogContent
        className="max-w-lg max-h-[85%] overflow-auto"
        showCloseButton={!!close}
        onClose={close}
      >
        <ConfirmDialog
          isOpen={confirmDialogOpen}
          cancelStr="Annuler"
          cancelButtonVariant="ghostWithBorders"
          validateStr="Confirmer"
          onResult={(accepted) => {
            if (accepted)
              transferDoneMutation.mutate({
                registrationId,
                billingAddress: billingAddress ?? undefined,
              });
            setConfirmDialogOpen(false);
          }}
          title="Marquer le virement comme effectué"
          close={() => setConfirmDialogOpen(false)}
        />
        <DialogDescription>Paying dialog</DialogDescription>
        <DialogHeader>
          <DialogTitle>Paiement de l'examen</DialogTitle>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4 w-full"
          >
            <CandidateDisplay
              candidateFirstName={candidateFirstName}
              candidateLastName={candidateLastName}
              examType={examType}
              examDate={examDate}
              registrationEndDate={registrationEndDate}
            />
            {role === Role.CANDIDATE && (
              <AddressForm isMandatory={true} title="Adresse de facturation" />
            )}
            <Button className="font-medium" type="button" disabled={true}>
              {price ? `Régler ${price} € par carte bancaire` : "chargement..."}
            </Button>
            <TransferInfo
              isOpen={isExpanded}
              setOpen={setIsExpanded}
              registrationEndDate={registrationEndDate}
              price={price}
              candidateFirstName={candidateFirstName}
              candidateLastName={candidateLastName}
            />
            {isExpanded && (
              <Button
                variant="ghostWithBorders"
                className="w-full gap-2"
                onClick={() => {
                  if (role === Role.CANDIDATE) form.handleSubmit(onSubmit)();
                  else if (role === Role.COMPANY) setConfirmDialogOpen(true);
                  else throw new Error("Invalid role : " + role);
                }}
                disabled={transferDoneMutation.isPending}
                type="button"
              >
                {transferDoneMutation.isPending ? (
                  <LoadingSpinner />
                ) : (
                  <>
                    <Check className="h-5 w-5" />
                    Marquer le virement comme effectué
                  </>
                )}
              </Button>
            )}
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default PayingDialog;
