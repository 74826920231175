import { ENDPOINT } from "@/constants/endpoints";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { FormationCertificatesNewDocumentsDto } from "./dto/formation-attestations-new-documents.dto";

// new documents

const newDocuments = async (body: FormationCertificatesNewDocumentsDto) => {
  await axios.post<void>(
    ENDPOINT.formationAttestation.postNewDocuments(),
    body
  );
};

export const useMutationFormationAttestationNewDocuments = (
  props?: Partial<
    UseMutationOptions<void, AxiosError, FormationCertificatesNewDocumentsDto>
  >
) =>
  useMutation({
    mutationFn: newDocuments,
    ...props,
  });

// all provided

type AllProvidedDto = {
  registrationId: number;
  allAttestationsProvided: boolean;
};

const allProvided = async (body: AllProvidedDto) => {
  await axios.post<void>(ENDPOINT.formationAttestation.postAllProvided(), body);
};

export const useMutationFormationAttestationAllProvided = (
  props?: Partial<UseMutationOptions<void, AxiosError, AllProvidedDto>>
) =>
  useMutation({
    mutationFn: allProvided,
    ...props,
  });

// status

type StatusDto = {
  registrationId: number;
  attestationId: number;
  validated: boolean;
};

const status = async (body: StatusDto) => {
  await axios.post<void>(ENDPOINT.formationAttestation.postStatus(), body);
};

export const useMutationFormationAttestationStatus = (
  props?: Partial<UseMutationOptions<void, AxiosError, StatusDto>>
) =>
  useMutation({
    mutationFn: status,
    ...props,
  });

// global status

type GlobalStatusDto = {
  registrationId: number;
  validated: boolean;
};

const globalStatus = async (body: GlobalStatusDto) => {
  await axios.post<void>(
    ENDPOINT.formationAttestation.postGlobalStatus(),
    body
  );
};

export const useMutationFormationAttestationGlobalStatus = (
  props?: Partial<UseMutationOptions<void, AxiosError, GlobalStatusDto>>
) =>
  useMutation({
    mutationFn: globalStatus,
    ...props,
  });
