import { Input } from "@/components/atoms/Input";
import usePersistent from "@/hooks/use-persistent";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import ExamPerimetersSheet from "./ExamPerimetersSheet";
import RegistrationsTable from "./RegistrationsTable/RegistrationsTable";
import RegistrationsTabs from "./RegistrationsTabs";
import SessionFilterSheet from "./SessionFilterSheet";

const Registrations = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [examFilterSheetOpen, setExamFilterSheetOpen] = useState(false);
  const [examIds, setExamIds] = usePersistent<number[]>(
    "admin-registrations-examIds",
    []
  );
  const [complete, setComplete] = useState<boolean | undefined>(undefined);

  return (
    <div className="flex flex-col bg-white h-screen">
      <SessionFilterSheet
        isOpen={examFilterSheetOpen}
        setOpen={setExamFilterSheetOpen}
        setSelectedExamIds={setExamIds}
        selectedExamIds={examIds}
      />
      <div className="flex flex-col border-b border-b-gray-200 px-6 pt-3 gap-2 bg-white relative">
        <h1>Inscriptions</h1>
        <RegistrationsTabs setComplete={setComplete} />
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <div className="flex flex-row gap-3 items-center">
          <Input
            className="shadow-none"
            id="shadcn-input"
            searchIcon
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value as string)}
            placeholder="Rechercher"
          />
          <ExamPerimetersSheet />
        </div>
        <RegistrationsTable
          openExamFilter={() => setExamFilterSheetOpen(true)}
          complete={complete}
          searchText={debouncedSearchText}
          examIds={examIds}
        />
      </div>
    </div>
  );
};

export default Registrations;
