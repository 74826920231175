import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import React from "react";
import { RegistrationsTab } from "./registrations-tab.enum";

const tabsToComplete = {
  [RegistrationsTab.All]: undefined,
  [RegistrationsTab.INCOMPLETE]: false,
  [RegistrationsTab.COMPLETE]: true,
};

interface RegistrationsTabsProps {
  setComplete: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const RegistrationsTabs = ({ setComplete }: RegistrationsTabsProps) => {
  const [tab, setTab] = usePersistent<RegistrationsTab>(
    "admin-dashboard-registrations-tab",
    RegistrationsTab.All
  );

  return (
    <Tabs
      onValueChange={(e) => {
        setTab(e as RegistrationsTab);
        setComplete(tabsToComplete[e as RegistrationsTab]);
      }}
      value={tab}
    >
      <TabsList variant="transparent" className="pb-0 mb-0">
        <TabsTrigger variant="underlineBlue" value={RegistrationsTab.All}>
          Toutes
        </TabsTrigger>
        <TabsTrigger
          variant="underlineBlue"
          value={RegistrationsTab.INCOMPLETE}
        >
          En cours
        </TabsTrigger>
        <TabsTrigger variant="underlineBlue" value={RegistrationsTab.COMPLETE}>
          Finalisées
        </TabsTrigger>
      </TabsList>
    </Tabs>
  );
};

export default RegistrationsTabs;
