import {
  ConnectionSchema,
  connectionSchema,
  connectionSchemaDefaultValues,
} from "@/components/templates/ConnectPage/ConnectionForm";
import { ROUTE } from "@/constants/routes";
import { Button } from "@atoms/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@atoms/Form";
import { Input } from "@atoms/Input";
import { zodResolver } from "@hookform/resolvers/zod";
import ErrorDisplay from "@molecules/ErrorDisplay";
import HelloPageLayout from "@templates/hello-page";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

interface ConnectionPageProps {
  title?: string;
  // return error message if any
  onSubmit: (values: ConnectionSchema) => void;
  pending: boolean;
  errorMsg?: string;
}

const ConnectionPage = ({
  errorMsg,
  onSubmit,
  pending,
  title = "Se connecter",
}: ConnectionPageProps) => {
  const navigate = useNavigate();

  const form = useForm<ConnectionSchema>({
    resolver: zodResolver(connectionSchema),
    defaultValues: connectionSchemaDefaultValues,
  });

  const control = form.control;

  const onSubmitForm = async (values: ConnectionSchema) => {
    const { data, error, success } = connectionSchema.safeParse(values);

    if (!success) {
      console.error("can't parse form, aborting login :", error);
      return;
    }

    onSubmit(data);
  };

  return (
    <HelloPageLayout className="max-w-xl w-96">
      <h1>{title}</h1>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmitForm)}
          className="flex w-full flex-col space-y-6"
        >
          <FormField
            control={control}
            name="email"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Adresse email</FormLabel>
                <FormControl>
                  <Input
                    placeholder={"mail@mail.com"}
                    {...field}
                    autoComplete="email"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={control}
            name="key"
            render={({ field }) => (
              <FormItem className="w-full">
                <FormLabel>Mot de passe</FormLabel>
                <FormControl>
                  <Input placeholder={"********"} {...field} type="password" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex items-center flex-col justify-center gap-2 self-stretch">
            <Button
              className="flex font-semibold justify-center items-center self-stretch bg-transparent text-base text-brand-800 focused hover:bg-transparent hover:text-brand-500"
              onClick={(e) => {
                e.preventDefault();
                navigate(ROUTE.forgotten.password.ask());
              }}
              type="button"
            >
              Mot de passe oublié
            </Button>
            <Button
              className="flex px-1 py-2 mt-3 justify-center gap-1 self-stretch rounded-lg border border-[#1D64D8] bg-[#2570EB] font-semibold text-base"
              type="submit"
              disabled={pending}
            >
              {pending ? "Connection en cours..." : "Se connecter"}
            </Button>
            <div className="flex flex-col self-stretch mt-4">
              {errorMsg && <ErrorDisplay msg={errorMsg} />}
            </div>
          </div>
        </form>
      </Form>
    </HelloPageLayout>
  );
};

export default ConnectionPage;
