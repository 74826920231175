import { cn } from "@/lib/utils";
import { CheckIcon } from "lucide-react";

interface GreenCheckProps {
  className?: string;
}

const GreenCheck = ({ className }: GreenCheckProps) => (
  <CheckIcon
    className={cn(
      "w-5 h-5 text-green-500 bg-green-100 rounded-full p-0.5",
      className
    )}
  />
);

export default GreenCheck;
