import { Role } from "@/api/enums/Role";
import { ExamAnswerDto } from "@/api/live-exam/dto/exam-answer.dto";
import {
  ExamDtoClient,
  ExamQuestionStateDtoClient,
} from "@/api/live-exam/dto/exam.dto";
import { Badge } from "@/components/atoms/Badge";
import { ScrollArea } from "@/components/atoms/ScrollArea";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { socket } from "@/hooks/socket";
import usePersistent from "@/hooks/use-persistent";
import { useWebSocketData } from "@/hooks/websocket-data";
import { Perimeter } from "@/lib/perimeter";
import { useEffect, useMemo, useRef, useState } from "react";
import ProgressBar from "./ProgressBar";
import QuestionCard from "./QuestionCard";
import { QuestionsTab, tabFilter } from "./questions-tab.enum";
import QuestionTabs from "./QuestionTabs";
import TopBar from "./TopBar/TopBar";

// This component sorts questions by column perimeter,
// then by question id.
//
// This is important : admins
// want to know the order of questions
// the candidate was presented with.

type QuestionsSection = {
  [perimeter: string]: {
    index: number;
    question: ExamQuestionStateDtoClient;
  }[];
};

const Questions = () => {
  const [exam, setExam] = useState<ExamDtoClient | undefined>(undefined);
  const [perimeter, setPerimeter] = useState<string>("");
  const [tab, setTab] = useState<QuestionsTab>(QuestionsTab.All);
  const [waitingQuestions, setWaitingQuestions] = usePersistent<number[]>(
    "exam-waiting-questions",
    []
  );
  // to jump to a question
  const questionRefs = useRef<{ [questionId: number]: HTMLDivElement }>({});
  const { connected } = useWebSocketData();
  const [displayWaiting, setDisplayWaiting] = usePersistent(
    "display-waiting",
    false
  );

  // reconnect if necessary
  useEffect(() => {
    if (!connected) {
      socket.io.opts.query = { role: Role.CANDIDATE };
      socket.connect();
    }
  }, [connected]);

  // query exam
  useEffect(() => {
    socket.emit("getExam", {}, (data: ExamDtoClient) => {
      setExam(data);
    });
  }, []);

  // compute displayed sections
  const questions = useMemo(
    () =>
      (exam ?? []).filter(({ question }) => {
        if (displayWaiting) return waitingQuestions.includes(question.id);
        else return Perimeter.toCode(question.perimeter) === perimeter;
      }),
    [exam, perimeter, displayWaiting, waitingQuestions]
  );

  const sections: QuestionsSection = useMemo(() => {
    const d: QuestionsSection = {};

    for (const question of questions) {
      const perimeter = Perimeter.toCode(question.question.perimeter);
      if (!d[perimeter]) d[perimeter] = [];
      d[perimeter].push({
        index: (exam ?? [])
          .filter((q) => Perimeter.toCode(q.question.perimeter) === perimeter)
          .indexOf(question),
        question,
      });
    }

    return d;
  }, [questions, exam]);

  // set initial perimeter
  useEffect(() => {
    if (!exam || perimeter) return;
    setPerimeter(Perimeter.toCode(exam[0].question.perimeter));
  }, [perimeter, exam]);

  const jumpToQuestion = (questionId: number) => {
    // find question
    const question = questions.find(
      ({ question }) => question.id === questionId
    );

    if (!question) return;

    // change tab if the question is not visible
    for (const newTab of [tab, ...Object.values(QuestionsTab)]) {
      if (!tabFilter(question, newTab)) continue;
      setTab(newTab);
      break;
    }

    questionRefs.current[questionId]?.scrollIntoView({ behavior: "smooth" });
  };

  const onAnswerAcknowledged = (data: ExamAnswerDto) =>
    setExam((prev) =>
      prev?.map((question) => {
        if (question.question.id === data.questionId)
          question.answerOrder = data.answerOrder;

        return question;
      })
    );

  const setWaiting = (questionId: number, waiting: boolean) => {
    if (waiting) setWaitingQuestions([...waitingQuestions, questionId]);
    else
      setWaitingQuestions(waitingQuestions.filter((qId) => qId !== questionId));
  };

  const remainingQuestions = useMemo(
    () =>
      exam?.filter(({ answerOrder }) => answerOrder === undefined).length ?? 0,
    [exam]
  );

  if (!connected)
    return <LoadingSpinner loadingMessage="Connexion au serveur live..." />;

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <TopBar remainingQuestions={remainingQuestions} />
      <div className="flex flex-row gap-4 px-24 py-8 flex-grow h-0">
        <ProgressBar
          exam={exam ?? []}
          jumpToQuestion={jumpToQuestion}
          perimeter={perimeter}
          setPerimeter={setPerimeter}
          displayWaiting={displayWaiting}
          setDisplayWaiting={setDisplayWaiting}
        />
        <div className="flex flex-col gap-7 flex-grow">
          {!displayWaiting && (
            <QuestionTabs questions={questions} tab={tab} setTab={setTab} />
          )}

          <ScrollArea>
            <div className="flex flex-col gap-6">
              {Object.entries(sections).map(([perimeter, sectionQuestions]) => (
                <div className="flex flex-col gap-6" key={perimeter}>
                  <div className="flex flex-row items-center gap-2 sticky top-0 bg-gray-50 z-50 border-b border-b-gray-200 py-1">
                    <h2>{Perimeter.toExamText(perimeter)}</h2>
                    <Badge variant="white">
                      {sectionQuestions.length} questions
                    </Badge>
                  </div>
                  {sectionQuestions
                    .filter(({ question }) => tabFilter(question, tab))
                    // Sort by id.
                    .sort(
                      (a, b) => a.question.question.id - b.question.question.id
                    )
                    .map(({ question, index }) => (
                      <QuestionCard
                        name={`Question ${index + 1}`}
                        key={`${perimeter}-${index}`}
                        ref={(el) => {
                          if (el)
                            questionRefs.current[question.question.id] = el;
                        }}
                        question={question}
                        waitingQuestions={waitingQuestions}
                        setWaiting={setWaiting}
                        onAnswerAcknowledged={onAnswerAcknowledged}
                      />
                    ))}
                </div>
              ))}
            </div>
          </ScrollArea>
        </div>
      </div>
    </div>
  );
};

export default Questions;
