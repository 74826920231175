import { useQueryExamPerimeters } from "@/api/exam/exam";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/atoms/Sheet";
import { Perimeter } from "@/lib/perimeter";
import { useState } from "react";

interface PerimetersFilterSheetProps {
  examId: number;
  perimeters: string[];
  setPerimeters: React.Dispatch<React.SetStateAction<string[]>>;
}

const PerimetersFilterSheet = ({
  examId,
  perimeters,
  setPerimeters,
}: PerimetersFilterSheetProps) => {
  const [open, setOpen] = useState(false);

  const { data: possiblePerimeters } = useQueryExamPerimeters(examId, {
    enabled: open,
  });

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button>QCMs</Button>
      </SheetTrigger>
      <SheetContent className="flex flex-col gap-4 h-full">
        <SheetHeader>
          <SheetTitle>QCMs de la session</SheetTitle>
          <SheetDescription hidden={true}>QCMs de la session</SheetDescription>
        </SheetHeader>
        <div className="flex flex-col justify-between h-full">
          <div className="grid grid-cols-2 gap-4">
            {(possiblePerimeters ?? [])
              .map(Perimeter.toCode)
              .map((perimeter) => (
                <div
                  className="flex flex-row items-center gap-2"
                  key={perimeter}
                >
                  <Checkbox
                    checked={perimeters.includes(perimeter)}
                    onClick={() =>
                      setPerimeters((prev) =>
                        prev.includes(perimeter)
                          ? prev.filter((p) => p !== perimeter)
                          : [...prev, perimeter]
                      )
                    }
                    id={perimeter}
                  />
                  <label htmlFor={perimeter}>{perimeter}</label>
                </div>
              ))}
          </div>
          <div className="flex flex-row items-center gap-4 justify-end">
            <Button
              onClick={() => setPerimeters([])}
              variant="ghostWithBorders"
            >
              Réinitialiser
            </Button>
            <Button onClick={() => setOpen(false)}>Filtrer</Button>
          </div>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default PerimetersFilterSheet;
