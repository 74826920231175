import { ExamLiveCandidateDtoClient } from "@/api/live-exam/dto/exam-live.dto";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Input } from "@/components/atoms/Input";
import { ComboboxField } from "@/components/molecules/Combobox";
import { socket } from "@/hooks/socket";
import { format } from "date-fns";
import { Clock } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

const HourDisplay = ({ date, text }: { date?: Date; text: string }) => (
  <div className="flex flex-col gap-1.5">
    <span>{text}</span>
    <Input
      name={text}
      disabled={true}
      value={date ? format(date, "HH:mm") : "-"}
    />
  </div>
);

interface AdditionalTimeDialogProps {
  candidate: ExamLiveCandidateDtoClient;
}

export const AdditionalTimeDialog = ({
  candidate: { candidate, examConnection, registration },
}: AdditionalTimeDialogProps) => {
  const [additionalTimeMinutes, setAdditionalTimeMinutes] = useState(
    examConnection?.additionalTimeMinutes ?? 5
  );
  const [open, setOpen] = useState(false);
  const [pending, setPending] = useState(false);

  const onAcknowledge = (success: boolean) => {
    setPending(false);
    if (success) {
      setOpen(false);
      toast.success("Temps additionnel enregistré.");
    } else toast.error("Erreur lors de l'enregistrement du temps additionnel.");
  };

  return (
    <Dialog open={open} onOpenChange={(open) => setOpen(open)}>
      <DialogTrigger
        className="flex flex-row gap-2 items-center p-2 hover:bg-gray-50 transition-colors rounded-sm"
        onClick={(e) => e.stopPropagation()}
      >
        <Clock className="w-4 h-4" />
        <span>Ajouter du temps supplémentaire</span>
      </DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        className="flex flex-col gap-6"
      >
        <DialogHeader>
          <DialogTitle>
            {candidate.personalInfo.lastName} {candidate.personalInfo.firstName}
          </DialogTitle>
          <DialogDescription hidden={true}>
            Ajout de temps supplémentaire
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <div className="flex flex-row gap-4">
            <span className="text-black">Périmètre de l'examen</span>
            <Badge variant="white">{registration.perimeter}</Badge>
          </div>
          <HourDisplay text="Connexion" date={examConnection?.connectedAt[0]} />
          <div className="flex flex-col gap-1.5">
            <label htmlFor="timeToAddMinutes">Combien de temps à ajouter</label>
            <ComboboxField
              options={Array.from({ length: 120 }, (_, i) => {
                const minutes = i * 5;
                const hours = Math.floor(minutes / 60);
                const minutesText = minutes % 60;

                return {
                  value: i * 5,
                  label: `${hours.toString().padStart(2, "0")}:${minutesText
                    .toString()
                    .padStart(2, "0")}`,
                };
              })}
              placeholder="-"
              value={additionalTimeMinutes}
              onChange={setAdditionalTimeMinutes}
              id="timeToAddMinutes"
            />
          </div>
          <HourDisplay
            text="Fin de l'examen"
            date={examConnection?.plannedFinishTime}
          />
          <Button
            onClick={() => {
              setPending(true);
              socket.emit(
                "additionalTime",
                {
                  candidateId: candidate.id,
                  additionalTimeMinutes,
                },
                onAcknowledge
              );
            }}
            disabled={pending}
          >
            {pending ? "En cours..." : "Valider l'ajout de temps"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalTimeDialog;
