import { useMutationCertificateForeignCertificatesSetStatus } from "@/api/certificate/certificate";
import { ForeignCertificateDtoClient } from "@/api/certificate/dto/foreign-certificates-paginated.dto";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "@/components/atoms/Dialog";
import { Input } from "@/components/atoms/Input";
import FileLink from "@/components/molecules/FileLink";
import PerimeterDisplay from "@/components/pages/Candidate/Dashboard/Exams/Home/ToCome/RegistrationCard/DetailsDialog/PerimeterDisplay";
import { Perimeter } from "@/lib/perimeter";
import { DialogTitle } from "@radix-ui/react-dialog";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";

interface ForeignCertificateValidateDialogProps {
  close: () => void;
  certificate?: ForeignCertificateDtoClient;
}

const ForeignCertificateValidateDialog = ({
  close,
  certificate,
}: ForeignCertificateValidateDialogProps) => {
  const queryClient = useQueryClient();

  const statusMutation = useMutationCertificateForeignCertificatesSetStatus({
    onSuccess: (_data, { status }) => {
      if (!certificate) return;

      toast.success(
        "Certificat " +
          (status === CertificateStatus.REFUSED ? "refusé" : "accepté")
      );
      queryClient.invalidateQueries({
        queryKey: keyFactory.certificate.all(certificate.candidateId),
      });
      queryClient.invalidateQueries({
        queryKey: keyFactory.certificate.foreignCertificatesPaginated(),
      });
      close();
    },
    onError: () => {
      console.error("Échec de la requête", {
        description: "Votre action n'a pas pu être prise en compte.",
      });
    },
  });

  const mutate = (validated: boolean) => {
    if (!certificate) return;

    statusMutation.mutate({
      certificateId: certificate.id,
      status: validated
        ? CertificateStatus.VALIDATED
        : CertificateStatus.REFUSED,
    });
  };

  return (
    <Dialog open={certificate !== undefined}>
      <DialogContent onClose={close} className="p-4 max-w-3xl">
        <DialogHeader>
          <DialogTitle>
            {certificate ? (
              <>
                {certificate.firstName} {certificate.lastName} - Certificats
                étrangers
              </>
            ) : (
              "-"
            )}
          </DialogTitle>
          <DialogDescription hidden={true}>
            Vérification d'un certificat étranger
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col w-full gap-4">
          <FileLink
            outerBox={true}
            file={certificate?.file}
            variant="secondary"
          />
          <div className="flex flex-col gap-1.5 ">
            <span>Pays du certificat</span>
            <Input disabled={true} value={certificate?.country ?? "-"} />
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col gap-1.5 w-full">
              <span>Numéro de certificat</span>
              <Input disabled={true} value={certificate?.number ?? "-"} />
            </div>
            <div className="flex flex-col gap-1.5 w-full">
              <span>Date de validité</span>
              <Input
                disabled={true}
                value={
                  certificate?.expirationDate
                    ? new Date(certificate?.expirationDate).toLocaleDateString(
                        "fr-FR"
                      )
                    : "-"
                }
              />
            </div>
          </div>
          <PerimeterDisplay
            perimeter={
              certificate?.perimeter
                ? Perimeter.from(certificate.perimeter)
                : Perimeter.empty()
            }
          />
          <div className="flex flex-row gap-4">
            <Button
              variant="red"
              onClick={() => mutate(false)}
              className="w-1/2"
            >
              Refuser
            </Button>
            <Button
              variant="green"
              onClick={() => mutate(true)}
              className="w-1/2"
            >
              Accepter
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ForeignCertificateValidateDialog;
