import * as Sentry from "@sentry/react";
import axios from "axios";
import { createRoot } from "react-dom/client";
import "unfonts.css";
import AppProviders from "./AppProviders.tsx";
import "./styles/index.css";

const API_URL = import.meta.env.VITE_API_URL;
const API_PORT = import.meta.env.VITE_API_PORT;

axios.defaults.baseURL = `${API_URL}:${API_PORT}`;
axios.defaults.withCredentials = true;

if (["production", "staging"].includes(import.meta.env.VITE_MODE)) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.replayIntegration(),
    ],
    environment: import.meta.env.VITE_MODE,
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

createRoot(document.getElementById("root")!).render(<AppProviders />);
