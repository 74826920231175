import { ExamLiveDtoClient } from "@/api/live-exam/dto/exam-live.dto";
import { Badge } from "@/components/atoms/Badge";
import ExamTypeBadge from "@/components/molecules/Badges/ExamTypeBadge";
import PercentageProgress from "@/components/molecules/Progress/PercentageProgress";
import { createColumnHelper } from "@tanstack/react-table";

const columnHelper = createColumnHelper<ExamLiveDtoClient>();

export const columns = [
  columnHelper.accessor("number", {
    id: "number",
    header: "N° Session",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("date", {
    id: "date",
    header: "Date exam.",
    cell: (info) => new Date(info.getValue()).toLocaleDateString("fr-FR"),
  }),
  columnHelper.accessor("type", {
    id: "type",
    header: "Type",
    cell: (info) => <ExamTypeBadge examType={info.getValue()} />,
  }),
  columnHelper.accessor("registeredCount", {
    id: "registered",
    header: "Inscrits",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor(
    ({ registeredCount, connectedCandidatesCount }) =>
      registeredCount - connectedCandidatesCount,
    {
      id: "missingCandidatesCount",
      header: "Nombre d'abs",
      cell: (info) => info.getValue(),
    }
  ),
  columnHelper.accessor("reportedCandidatesCount", {
    id: "reportedCandidatesCount",
    header: "Nombre reports",
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor("subjectsCount", {
    id: "subjectCount",
    header: "Nombre de sujets",
    cell: (info) => <Badge variant="white">{info.getValue()}</Badge>,
  }),
  columnHelper.accessor(
    ({ successfulCandidatesCount, registeredCount }) =>
      (successfulCandidatesCount / registeredCount) * 100,
    {
      id: "successPercentage",
      header: "% d'admis",
      cell: (info) => <PercentageProgress percentage={info.getValue()} />,
    }
  ),
];
