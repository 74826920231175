import { Badge } from "@/components/atoms/Badge";

interface ActiveBadgeProps {
  active: boolean;
}

export const ActiveBadge = ({ active }: ActiveBadgeProps) => {
  return active ? (
    <Badge variant="green">Active</Badge>
  ) : (
    <Badge variant="red">Inactive</Badge>
  );
};

export default ActiveBadge;
