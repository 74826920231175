import { ColumnPerimeter } from "@/constants/column-perimeter.enum";
import { PossiblePerimeter } from "@/constants/possible-perimeters.enum";
import { z } from "zod";

enum ConstantKey {
  Prices = "prices",
  AdmissionNotes = "admissionNotes",
  QCMParameters = "qcmParameters",
}

type ConstantType = {
  [ConstantKey.Prices]: {
    initial: {
      full: number;
      reduced: number;
    };
    renewing: {
      full: number;
      reduced: number;
    };
  };
  [ConstantKey.AdmissionNotes]: {
    qcmShort: number;
    qcmMedium: number;
    qcmLong: number;
    edcWithoutSpecialty: number;
    edcSpecialty1: number;
    edcSpecialty2: number;
  };
  [ConstantKey.QCMParameters]: {
    [key in PossiblePerimeter]: { [index in ColumnPerimeter]: number };
  };
};

// 0 question for each perimeter
const zerosColumns = Object.fromEntries(
  Object.values(ColumnPerimeter).map((perimeter) => [perimeter, 0])
);

const constantDefaultValue: ConstantType = {
  [ConstantKey.Prices]: {
    initial: {
      full: 580,
      reduced: 480,
    },
    renewing: {
      full: 580,
      reduced: 480,
    },
  },
  [ConstantKey.AdmissionNotes]: {
    qcmShort: 10,
    qcmMedium: 10,
    qcmLong: 10,
    edcWithoutSpecialty: 10,
    edcSpecialty1: 10,
    edcSpecialty2: 10,
  },
  [ConstantKey.QCMParameters]: Object.fromEntries(
    Object.values(PossiblePerimeter).map((perimeter) => [
      perimeter as PossiblePerimeter,
      zerosColumns,
    ])
  ) as {
    [key in PossiblePerimeter]: { [index in ColumnPerimeter]: number };
  },
};

const ConstantZodSchema = {
  [ConstantKey.Prices]: z.object({
    initial: z.object({
      full: z.number(),
      reduced: z.number(),
    }),
    renewing: z.object({
      full: z.number(),
      reduced: z.number(),
    }),
  }),
  [ConstantKey.AdmissionNotes]: z.object({
    qcmShort: z.coerce.number(),
    qcmMedium: z.coerce.number(),
    qcmLong: z.coerce.number(),
    edcWithoutSpecialty: z.coerce.number(),
    edcSpecialty1: z.coerce.number(),
    edcSpecialty2: z.coerce.number(),
  }),
  [ConstantKey.QCMParameters]: z.object(
    Object.fromEntries(
      Object.values(PossiblePerimeter).map((perimeter) => [
        perimeter,
        z.object(
          Object.fromEntries(
            Object.values(ColumnPerimeter).map((column) => [
              column,
              z.coerce.number(),
            ])
          )
        ),
      ])
    )
  ),
};

export { constantDefaultValue, ConstantKey, ConstantZodSchema };
export type { ConstantType };
