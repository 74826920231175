import { ExamStatus } from "@/api/enums/exam-status.enum";
import { useMutationExportCopies } from "@/api/export/export";
import { Button } from "@/components/atoms/Button";
import { Checkbox } from "@/components/atoms/CheckBox";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { Form } from "@/components/atoms/Form";
import ExamDateCombobox from "@/components/molecules/ExamDateCombobox";
import { id } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { HttpStatusCode } from "axios";
import { Download } from "lucide-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  examId: id(),
  global: z.boolean(),
});
type Schema = z.infer<typeof schema>;

const ExportCopyDialog = () => {
  const [open, setOpen] = useState(false);

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      examId: -1,
      global: true,
    },
  });

  const { mutate, isPending } = useMutationExportCopies();

  const onSubmit = (data: Schema) => {
    mutate(
      {
        ...data,
      },
      {
        onError: (error) => {
          if (error.response?.status === HttpStatusCode.Conflict) {
            toast.error("Aucune inscription à cet examen.");
            return;
          }

          toast.error("Échec de l'export");
        },
      }
    );
  };

  const global = form.watch("global");

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Download className="w-5 h-5" />
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Export des copies</DialogTitle>
          <DialogDescription hidden>Export des copies</DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex flex-col gap-4"
          >
            <ExamDateCombobox
              fieldName="examId"
              filter={({ status }) =>
                [
                  ExamStatus.CERTIFICATES_GENERATED,
                  ExamStatus.CORRECTED,
                  ExamStatus.NOTES_GENERATED,
                ].includes(status)
              }
              title="Examen"
              label={({ number, date }) =>
                `${number} - ${new Date(date).toLocaleDateString("fr-FR")}`
              }
            />
            <div className="flex flex-row gap-2 items-center">
              <Checkbox
                checked={global}
                onCheckedChange={() => form.setValue("global", true)}
                id="checked-global"
                name="checked-global"
              />
              <label htmlFor="checked-global">Copies complètes globales</label>
            </div>{" "}
            <div className="flex flex-row gap-2 items-center">
              <Checkbox
                checked={!global}
                onCheckedChange={() => form.setValue("global", false)}
                id="checked-not-global"
                name="checked-not-global"
              />
              <label htmlFor="checked-not-global">
                Copies partielles par candidats
              </label>
            </div>
            <Button disabled={isPending}>
              {isPending ? "Export..." : "Exporter"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default ExportCopyDialog;
