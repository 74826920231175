import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { TabsContent } from "@radix-ui/react-tabs";
import { useDashBoardContext } from "../DashBoardContext";
import Certificates from "./Certificates";
import PersonalInformation from "./PersonalInformation";
import { ProfileTab } from "./profile-tab.enum";

const Profile = () => {
  const { openAddCertificates } = useDashBoardContext();
  const [currentTab, setCurrentTab] = usePersistent(
    "profile-tab",
    ProfileTab.Information
  );

  return (
    <div className="flex p-8 px-28 flex-col flex-start gap-8 self-stretch">
      <h1>Mon profil</h1>
      <Tabs
        value={currentTab}
        onValueChange={(t) => setCurrentTab(t as ProfileTab)}
        className="flex flex-col gap-8"
      >
        <TabsList className="p-0.5 h-full w-fit">
          <TabsTrigger className="m-0" value={ProfileTab.Information}>
            Mes informations
          </TabsTrigger>
          <TabsTrigger value={ProfileTab.Certificates}>
            Mes certificats
          </TabsTrigger>
        </TabsList>
        <TabsContent value={ProfileTab.Information}>
          <PersonalInformation />
        </TabsContent>
        <TabsContent value={ProfileTab.Certificates}>
          <Certificates openCertificatePopup={() => openAddCertificates()} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Profile;
