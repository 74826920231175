import { ExamQuestionStateDtoClient } from "@/api/live-exam/dto/exam.dto";

export enum QuestionsTab {
  All = "All",
  Answered = "Answered",
  NotAnswered = "NotAnswered",
}

export const tabFilter = (
  { answerOrder }: ExamQuestionStateDtoClient,
  tab: QuestionsTab
) => {
  const hasAnswer = answerOrder !== undefined;

  switch (tab) {
    case QuestionsTab.All:
      return true;
    case QuestionsTab.Answered:
      return hasAnswer;
    case QuestionsTab.NotAnswered:
      return !hasAnswer;
  }
};
