import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { Badge } from "@/components/atoms/Badge";
import GreenCheck from "@/components/atoms/GreenCheck";
import { PayementUtil } from "@/lib/payement-util";
import { Hourglass } from "lucide-react";

interface PayementBadgeProps {
  registration: CandidateRegistrationDtoClient;
}

const PayementBadge = ({ registration }: PayementBadgeProps) => {
  if (PayementUtil.paidByCard(registration.payementStatus))
    return (
      <>
        <Badge variant="sky">Carte</Badge>
        <GreenCheck />
      </>
    );
  else if (PayementUtil.paidByTransfer(registration.payementStatus))
    return (
      <>
        <Badge variant="sky">Virement</Badge>
        <GreenCheck />
      </>
    );
  else if (PayementUtil.isWaitingValidation(registration.payementStatus))
    return (
      <div className="flex flex-row gap-2">
        <Badge variant="sky">Virement</Badge>{" "}
        <Hourglass className="w-6 h-6 text-orange-500 bg-orange-100 p-1 rounded-full stroke-2" />
      </div>
    );
  else return <Badge variant="red">En attente</Badge>;
};

export default PayementBadge;
