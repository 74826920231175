import { Role } from "@/api/enums/Role";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { socket } from "@/hooks/socket";
import { useWebSocketData } from "@/hooks/websocket-data";
import { useEffect } from "react";
import { Outlet } from "react-router-dom";

const Live = () => {
  const { connected } = useWebSocketData();

  useEffect(() => {
    socket.io.opts.query = { role: Role.ADMIN };
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);

  return connected ? (
    <Outlet />
  ) : (
    <LoadingSpinner loadingMessage="Connexion au serveur live..." />
  );
};

export default Live;
