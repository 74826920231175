import { useEffect, useState } from "react";
import { socket } from "./socket";

export const useWebSocketData = () => {
  const [connected, setConnected] = useState(socket.connected);

  const handleConnect = () => setConnected(true);
  const handleDisconnect = () => setConnected(false);

  useEffect(() => {
    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
    };
  }, []);

  return { connected };
};
