import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/atoms/ToolTip";
import { cn } from "@/lib/utils";
import { CircleHelp } from "lucide-react";
import { useFormContext } from "react-hook-form";

interface RoundedInputProps {
  modifiable: boolean;
  name: string;
  title: string;
  total?: number;
}

const RoundedInput = ({
  modifiable,
  name,
  title,
  total,
}: RoundedInputProps) => {
  const { control, watch } = useFormContext();
  const value = watch(name);

  return (
    <FormField
      control={control}
      disabled={!modifiable}
      name={name}
      render={({ field }) => (
        <FormItem className="w-full">
          <FormLabel>{title}</FormLabel>
          <div
            className={cn(
              "flex flex-row items-center border border-gray-300 rounded-lg pr-2",
              {
                "bg-gray-50": !modifiable,
              }
            )}
          >
            <span className="whitespace-nowrap text-gray-900 border-r border-gray-300 p-2">
              Note minimale
            </span>
            <FormControl>
              <Input
                {...field}
                type="number"
                className="border-none shadow-none rounded-l-none border-l-0 h-full p-3 bg-none"
              />
            </FormControl>
            {total && (
              <TooltipProvider>
                <Tooltip delayDuration={100}>
                  <TooltipTrigger onClick={(e) => e.preventDefault()}>
                    <CircleHelp className="w-4 h-4" />
                  </TooltipTrigger>
                  <TooltipContent>
                    Cette note est sur 20. Elle correspond à{" "}
                    {(total / 20) * value} / {total}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default RoundedInput;
