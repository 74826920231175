import { PayementStatus } from "@/api/enums/PayementStatus";
import { Badge } from "@/components/atoms/Badge";

interface PayementStatusBadgeProps {
  status: PayementStatus;
}

const PayementStatusBadge = ({ status }: PayementStatusBadgeProps) => {
  switch (status) {
    case PayementStatus.UNPAID:
    case PayementStatus.DELEGATION_WAITING_FOR_VALIDATION:
    case PayementStatus.PERSONAL_WAITING_FOR_VALIDATION:
      return <Badge variant="red">En attente</Badge>;
    case PayementStatus.DELEGATION_PAID_CARD:
    case PayementStatus.PERSONAL_PAID_CARD:
      return <Badge variant="blue">Carte</Badge>;
    case PayementStatus.DELEGATION_PAID_TRANSFER:
    case PayementStatus.PERSONAL_PAID_TRANSFER:
      return <Badge variant="sky">Virement</Badge>;
  }
};

export default PayementStatusBadge;
