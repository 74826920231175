import { ExamStatus } from "@/api/enums/exam-status.enum";
import { Badge } from "@/components/atoms/Badge";

interface ExamStatusBadgeProps {
  status: ExamStatus;
  registeringEndDate: Date;
}

const ExamStatusBadge = ({
  status,
  registeringEndDate,
}: ExamStatusBadgeProps) => {
  switch (status) {
    case ExamStatus.QCMS_GENERATED:
    case ExamStatus.QCMS_NOT_GENERATED:
      if (registeringEndDate > new Date())
        return <Badge variant="green">Ouvert</Badge>;
      else return <Badge variant="orange">Inscriptions clôturées</Badge>;
    case ExamStatus.PASSED:
      return <Badge variant="sky">En attente de correction</Badge>;
    case ExamStatus.CORRECTED:
      return <Badge variant="white">Corrigée</Badge>;
    case ExamStatus.NOTES_GENERATED:
      return <Badge variant="purple">Relevés de notes générés</Badge>;
    case ExamStatus.CERTIFICATES_GENERATED:
      return <Badge variant="green">Certificats générés</Badge>;
  }
};

export default ExamStatusBadge;
