import { ENDPOINT } from "@/constants/endpoints";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { CancelQuestionDto } from "./dto/cancel-question.dto";
import { ModifyGradeDto } from "./dto/modify-grade.dto";

// generate documents

const generateDocuments = async (examId: number) => {
  await axios.post<void>(ENDPOINT.examResult.postGenerateDocuments(), {
    examId,
  });
};

export const useMutationExamResultGenerateDocuments = (
  props?: Partial<UseMutationOptions<void, AxiosError, number>>
) =>
  useMutation({
    mutationFn: generateDocuments,
    ...props,
  });

// cancel question

const cancelQuestion = async (body: CancelQuestionDto) => {
  await axios.post<void>(ENDPOINT.examResult.postCancelQuestion(), body);
};

export const useMutationExamResultCancelQuestion = (
  props?: Partial<UseMutationOptions<void, AxiosError, CancelQuestionDto>>
) =>
  useMutation({
    mutationFn: cancelQuestion,
    ...props,
  });

// modify grade

const modifyGrade = async (body: ModifyGradeDto) => {
  await axios.post<void>(ENDPOINT.examResult.postModifyGrade(), body);
};

export const useMutationExamResultModifyGrade = (
  props?: Partial<UseMutationOptions<void, AxiosError, ModifyGradeDto>>
) =>
  useMutation({
    mutationFn: modifyGrade,
    ...props,
  });
