import { ExamAnswerDto } from "@/api/live-exam/dto/exam-answer.dto";
import { ExamQuestionStateDtoClient } from "@/api/live-exam/dto/exam.dto";
import { Badge } from "@/components/atoms/Badge";
import { RadioGroup, RadioGroupItem } from "@/components/atoms/RadioGroup";
import { Switch } from "@/components/atoms/Switch";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { socket } from "@/hooks/socket";
import { Check } from "lucide-react";
import { forwardRef, useState } from "react";

interface QuestionCardProps {
  name: string;
  question: ExamQuestionStateDtoClient;
  waitingQuestions: number[];
  setWaiting: (questionId: number, waiting: boolean) => void;
  onAnswerAcknowledged: (data: ExamAnswerDto) => void;
}

const QuestionCard = forwardRef<HTMLDivElement, QuestionCardProps>(
  (
    {
      name,
      question: { question, answerOrder },
      waitingQuestions,
      setWaiting,
      onAnswerAcknowledged,
    }: QuestionCardProps,
    ref
  ) => {
    const [pending, setPending] = useState(false);
    const waiting = waitingQuestions.includes(question.id);

    return (
      <div ref={ref} className="bg-white p-6 flex flex-col gap-4 rounded-lg">
        <div className="flex flex-row  justify-between items-center">
          <div className="flex flex-row items-center gap-2 min-h-7">
            <Switch
              className="data-[state=checked]:bg-brand-600"
              checked={waiting}
              onCheckedChange={() =>
                setWaiting(question.id, !waitingQuestions.includes(question.id))
              }
              id={`waiting${name}`}
            />
            <label className="text-gray-900" htmlFor={`waiting${name}`}>
              {waiting ? 'Enlever de "En attente"' : "Mettre en attente"}
            </label>
          </div>
          {(answerOrder !== undefined || pending) && (
            <Badge variant="white" className="flex flex-row gap-1 py-1">
              {pending ? (
                <>
                  <LoadingSpinner className="w-4 h-4" />
                  En cours d'enregistrement
                </>
              ) : (
                <>
                  <Check className="w-4 h-4 bg-gray-500 rounded-full p-0.5 text-white" />
                  Enregistré
                </>
              )}
            </Badge>
          )}
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-gray-950 font-bold">{name}</span>
          <span className="text-black font-semibold">{question.text}</span>
        </div>
        <RadioGroup>
          {question.answers
            .sort((a, b) => a.order - b.order)
            .map(({ text, order }, i) => {
              const answer: ExamAnswerDto = {
                answerOrder: order,
                questionId: question.id,
              };

              return (
                <div
                  className="flex flex-row items-center gap-2 px-3 py-2 border border-gray-200 rounded-lg hover:bg-gray-50 hover:cursor-pointer"
                  key={i}
                  onClick={() => {
                    if (answerOrder === order) return;
                    setPending(true);
                    socket.emit("answer", answer, (data: ExamAnswerDto) => {
                      onAnswerAcknowledged(data);
                      setPending(false);
                    });
                  }}
                >
                  <RadioGroupItem
                    value={order.toString()}
                    checked={answerOrder === order}
                    className="rounded-full border-gray-300"
                  />
                  <span className="font-medium hover:cursor-pointer">
                    {text}
                  </span>
                </div>
              );
            })}
        </RadioGroup>
      </div>
    );
  }
);

export default QuestionCard;
