import { ExamQuestionStateDtoClient } from "@/api/live-exam/dto/exam.dto";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import { QuestionsTab, tabFilter } from "./questions-tab.enum";

interface QuestionTabsProps {
  tab: QuestionsTab;
  setTab: (tab: QuestionsTab) => void;
  questions: ExamQuestionStateDtoClient[];
}

const QuestionTabs = ({ setTab, tab, questions }: QuestionTabsProps) => {
  return (
    <Tabs value={tab} onValueChange={(t) => setTab(t as QuestionsTab)}>
      <TabsList className="inline-flex flex-wrap w-full justify-between p-0">
        {[
          {
            text: "Toutes les questions",
            value: QuestionsTab.All,
          },
          {
            text: "Questions répondues",
            value: QuestionsTab.Answered,
          },
          {
            text: "Questions sans réponses",
            value: QuestionsTab.NotAnswered,
          },
        ].map(({ text, value }) => (
          <TabsTrigger
            value={value}
            className="flex-grow m-0.5 py-1.5 items-center justify-center"
            key={value}
          >
            {text}
            {value !== QuestionsTab.All && (
              <>
                {" "}
                (
                {
                  questions.filter((question) => tabFilter(question, value))
                    .length
                }
                )
              </>
            )}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default QuestionTabs;
