import { ENDPOINT } from "@/constants/endpoints";
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { keyFactory } from "../keyFactory";
import { QuestionBasicDtoClient } from "../question/dto/question.dto";
import { DateSessionsDtoClient } from "./dto/date-sessions.dto";
import { DateDto } from "./dto/date.dto";
import { ExamQCMsDtoClient } from "./dto/exam-qcms.dto";
import { GetQuestionsDto } from "./dto/get-questions.dto";
import { QCMByDatePaginationRequestDto } from "./dto/qcm-by-date-paginated.dto";
import { ReplaceQuestionDto } from "./dto/replace-question.dto";

// paginated

const byDatePaginated = async (params: QCMByDatePaginationRequestDto) =>
  await axios
    .get<PaginationResponseDto<ExamQCMsDtoClient>>(ENDPOINT.qcm.getByDate(), {
      params,
    })
    .then(({ data }) => data);

export const useQueryExamQcmsByDatePaginated = (
  params: QCMByDatePaginationRequestDto,
  props?: Partial<UseQueryOptions<PaginationResponseDto<ExamQCMsDtoClient>>>
) =>
  useQuery({
    queryKey: keyFactory.qcm.byDate(params),
    queryFn: () => byDatePaginated(params),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    ...props,
  });

// detailed

const byDateDetailed = async (params: DateDto) =>
  await axios
    .get<DateSessionsDtoClient>(ENDPOINT.qcm.getByDateDetailed(params))
    .then(({ data }) => data);

export const useQueryQcmByDateDetailed = (
  params: DateDto,
  props?: Partial<UseQueryOptions<DateSessionsDtoClient>>
) =>
  useQuery({
    queryKey: keyFactory.qcm.byDateDetailed(params),
    queryFn: () => byDateDetailed(params),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 60,
    ...props,
  });

// generate

const generate = async (body: DateDto) => {
  await axios.post(ENDPOINT.qcm.postGenerate(), body);
};

export const useMutationQcmGenerate = (
  props?: Partial<UseMutationOptions<void, AxiosError, DateDto>>
) =>
  useMutation({
    mutationFn: generate,
    ...props,
  });

// get questions

const getQuestions = async (params: GetQuestionsDto) =>
  await axios
    .get<QuestionBasicDtoClient[]>(ENDPOINT.qcm.getQuestions(), {
      params,
    })
    .then((res) => res.data);

export const useQueryQcmGetQuestions = (
  params: GetQuestionsDto,
  props?: Partial<UseQueryOptions<QuestionBasicDtoClient[], AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.qcm.questions(params),
    queryFn: () => getQuestions(params),
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60,
    ...props,
  });

// replace questions

const replaceQuestions = async (body: ReplaceQuestionDto) => {
  await axios.post<void>(ENDPOINT.qcm.postReplaceQuestions(), body);
};

export const useMutationQcmReplaceQuestions = (
  props?: Partial<UseMutationOptions<void, AxiosError, ReplaceQuestionDto>>
) =>
  useMutation({
    mutationFn: replaceQuestions,
    ...props,
  });
