import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { TransportMode } from "@/api/enums/transport-mode.enum";
import { Perimeter } from "@/lib/perimeter";
import {
  Bomb,
  Fuel,
  Radiation,
  Ship,
  TestTube,
  TrainFront,
  Truck,
  Wind,
} from "lucide-react";
import { ReactNode } from "react";

interface GrayCardProps {
  children: ReactNode;
}

const GrayCard = ({ children }: GrayCardProps) => {
  return (
    <div className="p-4 bg-gray-100 flex flex-row rounded-lg font-bold gap-2 items-center w-full">
      {children}
    </div>
  );
};

interface PerimeterDisplayProps {
  perimeter: Perimeter;
}

// TODO: store perimeters logos somewhere to avoid repeat

const PerimeterDisplay = ({
  perimeter: { merchandises, transportModes },
}: PerimeterDisplayProps) => {
  return (
    <>
      <div className="flex flex-col w-full gap-2">
        <h2>Mode(s) de transport</h2>
        <div className="flex flex-row w-full gap-2">
          {[
            {
              value: TransportMode.ROAD,
              children: (
                <>
                  <Truck className="w-6 h-6" />
                  <span>Route (R)</span>
                </>
              ),
            },
            {
              value: TransportMode.TRAIN,
              children: (
                <>
                  <TrainFront className="w-6 h-6" />
                  <span>Ferroviaire (F)</span>
                </>
              ),
            },
            {
              value: TransportMode.BOAT,
              children: (
                <>
                  <Ship className="w-6 h-6" />
                  <span>Navigable (N)</span>
                </>
              ),
            },
          ]
            .filter(({ value }) => transportModes.includes(value))
            .map(({ children, value }) => (
              <GrayCard key={value}>{children}</GrayCard>
            ))}
        </div>
      </div>
      <div className="flex flex-col w-full gap-2">
        <h2>Classe(s) de marchandises dangereuses</h2>
        <div className="flex flex-col w-full gap-2">
          {[
            {
              value: MerchandiseClass.EXPLOSIVES,
              children: (
                <>
                  <Bomb className="w-6 h-6" />
                  <span>Classe 1 : Matières explosives</span>
                </>
              ),
            },
            {
              value: MerchandiseClass.GAS,
              children: (
                <>
                  <Wind className="w-6 h-6" />
                  <span>Classe 2 : Gaz</span>
                </>
              ),
            },
            {
              value: MerchandiseClass.RADIOACTIVE,
              children: (
                <>
                  <Radiation className="w-6 h-6" />
                  <span>Classe 7 : Matières radioactives</span>
                </>
              ),
            },
            {
              value: MerchandiseClass.CHEMICAL,
              children: (
                <>
                  <TestTube className="w-6 h-6" />
                  <span>Classes 3 à 9 sauf 7 : Chimie</span>
                </>
              ),
            },
            {
              value: MerchandiseClass.HYDROCARBONS,
              children: (
                <>
                  <Fuel className="w-6 h-6" />
                  <span>
                    Hydrocarbures : N°ONU 1202, 1203, 1223, 3475 et le carburant
                    aviation classé sous les N°ONU 1268 ou 1863
                  </span>
                </>
              ),
            },
          ]
            .filter(({ value }) => merchandises.includes(value))
            .map(({ children, value }) => (
              <GrayCard key={value}>{children}</GrayCard>
            ))}
        </div>
      </div>
    </>
  );
};

export default PerimeterDisplay;
