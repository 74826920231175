import { ExamStatus } from "@/api/enums/exam-status.enum";
import { useQueryQcmByDateDetailed } from "@/api/qcm/qcm";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import GreenCheck from "@/components/atoms/GreenCheck";
import GenerateQcmButton from "@/components/molecules/GenerateQcmButton";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { Perimeter } from "@/lib/perimeter";
import { cn } from "@/lib/utils";
import { format, parseISO } from "date-fns";
import { ChevronLeft } from "lucide-react";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ReplaceQuestionDialog from "./ReplaceQuestionDialog/ReplaceQuestionDialog";

type DataType = {
  [key: string]: {
    id: number;
    status: ExamStatus;
    number: string;
    candidatesCount: number;
    questionsCount: number;
  }[];
};

const Qcm = () => {
  const { date: dateStr } = useParams<{ date: string }>();
  const date = dateStr ? parseISO(dateStr) : undefined;
  const navigate = useNavigate();

  const query = useQueryQcmByDateDetailed({ date: date || new Date() });

  const perimeters = useMemo<DataType>(() => {
    if (!query.data) return {};

    const d: DataType = {};
    query.data.exams.forEach(({ perimeters, ...exam }) => {
      perimeters.forEach(({ perimeter, candidatesCount, questionsCount }) => {
        const key = Perimeter.toCode(perimeter);

        if (!d[key]) d[key] = [];

        d[key].push({
          ...exam,
          candidatesCount,
          questionsCount,
        });
      });
    });

    return d;
  }, [query.data]);

  const perimetersCount = Object.keys(perimeters).length;

  const allGenerated = useMemo(() => {
    if (!perimetersCount) return false;

    return Object.keys(perimeters).every((perimeter) =>
      perimeters[perimeter].every(
        ({ status }) => status === ExamStatus.QCMS_GENERATED
      )
    );
  }, [perimeters, perimetersCount]);

  const canGenerate = !!perimetersCount;

  if (!date || Number.isNaN(date.getTime())) return <>Invalid id</>;

  return (
    <div className="flex flex-col bg-white h-screen overflow-hidden">
      <div className="flex flex-row items-center border-b border-b-gray-200 px-6 py-2 gap-4 bg-white">
        <Button
          variant="ghostWithBorders"
          className="rounded-lg px-2"
          onClick={() => navigate(-1)}
        >
          <ChevronLeft />
        </Button>
        <h1>{format(date, "dd/MM/yyyy")}</h1>
        {allGenerated && (
          <Badge variant="green" className="gap-1">
            <GreenCheck /> Généré
          </Badge>
        )}
        {canGenerate && (
          <GenerateQcmButton regenerating={allGenerated} date={date} />
        )}
      </div>
      <div className="flex flex-col px-6 py-4 gap-3 overflow-auto">
        {!query.data ? (
          <LoadingSpinner
            isLoading={query.isLoading}
            isError={query.isError}
            loadingMessage="Chargement du QCM..."
            errorMessage="Erreur lors du chargement du QCM."
          />
        ) : !Object.keys(perimeters).length ? (
          <span className="italic">Aucun QCM à générer pour cette date.</span>
        ) : (
          Object.entries(perimeters)
            .sort(([, a], [, b]) => {
              const aCount = a.reduce(
                (acc, { candidatesCount }) => acc + candidatesCount,
                0
              );
              const bCount = b.reduce(
                (acc, { candidatesCount }) => acc + candidatesCount,
                0
              );

              return bCount - aCount;
            })
            .map(([perimeter, exams]) => (
              <div className="flex flex-col gap-3" key={perimeter}>
                <Badge variant="white">{perimeter}</Badge>
                <div className="rounded-lg overflow-auto border border-gray-200 w-full">
                  <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
                    <TableHeader className="items-center bg-gray-50">
                      <TableRow>
                        <TableHead className="w-[100px]">Session</TableHead>
                        <TableHead>Candidats</TableHead>
                        <TableHead>Nombre de questions</TableHead>
                        <TableHead className="justify-end text-end">
                          <ReplaceQuestionDialog
                            date={date}
                            perimeter={Perimeter.from(perimeter)}
                          />
                        </TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {exams.map(
                        ({ number, candidatesCount, questionsCount }, i) => (
                          <TableRow
                            key={`${perimeter}-${number}`}
                            className={cn({
                              "bg-gray-50": i % 2,
                            })}
                          >
                            <TableCell className="w-[100px]">
                              <Badge variant="white">{number}</Badge>
                            </TableCell>
                            <TableCell>
                              <Badge variant="blue">
                                {candidatesCount} candidats
                              </Badge>
                            </TableCell>
                            <TableCell>
                              <Badge variant="white">{questionsCount}</Badge>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </div>
              </div>
            ))
        )}
      </div>
    </div>
  );
};

export default Qcm;
