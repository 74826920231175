import { PerimeterDto } from "@/api/dto/perimeter.dto";
import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { TransportMode } from "@/api/enums/transport-mode.enum";

type GenericPerimeter = Perimeter | string;

class Perimeter {
  merchandises: MerchandiseClass[];
  transportModes: TransportMode[];

  constructor(
    transportModes: TransportMode[] = [],
    merchandises: MerchandiseClass[] = []
  ) {
    this.transportModes = transportModes;
    this.merchandises = merchandises;
  }

  // from/to code

  private static toCodeHelper = (
    transportModes: TransportMode[],
    merchandises: MerchandiseClass[]
  ) =>
    [
      [TransportMode.ROAD, TransportMode.TRAIN, TransportMode.BOAT]
        .filter((t) => transportModes.includes(t))
        .join(""),
      [
        MerchandiseClass.EXPLOSIVES,
        MerchandiseClass.GAS,
        MerchandiseClass.RADIOACTIVE,
        MerchandiseClass.CHEMICAL,
        MerchandiseClass.HYDROCARBONS,
      ]
        .filter((m) => merchandises.includes(m))
        .join(""),
    ].join("");

  public toCode = (): string =>
    Perimeter.toCodeHelper(this.transportModes, this.merchandises);

  public static toCode = (dto: PerimeterDto): string =>
    Perimeter.toCodeHelper(dto.transportModes, dto.merchandises);

  public static from = (code: string | PerimeterDto): Perimeter => {
    if (typeof code === "string")
      return new Perimeter(
        Object.values(TransportMode).filter((transportMode) =>
          code.includes(transportMode)
        ),
        Object.values(MerchandiseClass).filter((merchandise) =>
          code.includes(merchandise)
        )
      );
    else return new Perimeter(code.transportModes, code.merchandises);
  };

  public toDto = (): PerimeterDto => ({
    transportModes: this.transportModes,
    merchandises: this.merchandises,
  });

  public static toDto = (perimeter: GenericPerimeter): PerimeterDto =>
    Perimeter.toPerimeter(perimeter).toDto();

  private static toPerimeter = (perimeter: GenericPerimeter): Perimeter =>
    typeof perimeter === "string" ? Perimeter.from(perimeter) : perimeter;

  private static toPerimeters = (
    ...perimeters: GenericPerimeter[]
  ): Perimeter[] => perimeters.map((perimeter) => this.toPerimeter(perimeter));

  // object methods

  public includes = (other: GenericPerimeter): boolean => {
    const p = Perimeter.toPerimeter(other);

    return (
      p.transportModes.every((mode) => this.transportModes.includes(mode)) &&
      p.merchandises.every((mode) => this.merchandises.includes(mode))
    );
  };

  public restrictByOther = (other: GenericPerimeter): Perimeter => {
    const p = Perimeter.toPerimeter(other);

    return new Perimeter(
      this.transportModes.filter((val) => p.transportModes.includes(val)),
      this.merchandises.filter((val) => p.merchandises.includes(val))
    );
  };

  public isEmpty = (): boolean =>
    this.merchandises.length === 0 && this.transportModes.length === 0;

  public equals = (b: GenericPerimeter) =>
    this.toCode() === (typeof b === "string" ? b : b.toCode());

  // static methods

  public static equals = (a: GenericPerimeter, b: GenericPerimeter) =>
    Perimeter.toPerimeter(a).toCode() === Perimeter.toPerimeter(b).toCode();

  static empty = (): Perimeter => new Perimeter();

  static full = (): Perimeter =>
    new Perimeter(
      Object.values(TransportMode),
      Object.values(MerchandiseClass)
    );

  public static union = (...perimeters: GenericPerimeter[]): Perimeter => {
    const ps = Perimeter.toPerimeters(...perimeters);
    if (!perimeters.length) return Perimeter.empty();

    return ps.reduce((acc, perimeter) => {
      return new Perimeter(
        [...new Set([...acc.transportModes, ...perimeter.transportModes])],
        [...new Set([...acc.merchandises, ...perimeter.merchandises])]
      );
    }, Perimeter.empty());
  };

  public static difference = (a: GenericPerimeter, b: GenericPerimeter) => {
    const p0 = Perimeter.toPerimeter(a);
    const p1 = Perimeter.toPerimeter(b);

    return new Perimeter(
      Object.values(TransportMode).filter(
        (transportMode) =>
          !p0.transportModes.includes(transportMode) ||
          !p1.transportModes.includes(transportMode)
      ),
      Object.values(MerchandiseClass).filter(
        (merchandise) =>
          !p0.merchandises.includes(merchandise) ||
          !p1.merchandises.includes(merchandise)
      )
    );
  };

  public static transportModeToWord = (
    transportMode: TransportMode
  ): string => {
    switch (transportMode) {
      case TransportMode.ROAD:
        return "Route";
      case TransportMode.TRAIN:
        return "Ferroviaire";
      case TransportMode.BOAT:
        return "Navigable";
    }
  };

  public static merchandiseToWord = (merchandise: MerchandiseClass): string => {
    switch (merchandise) {
      case MerchandiseClass.EXPLOSIVES:
        return "Classe 1";
      case MerchandiseClass.GAS:
        return "Classe 2";
      case MerchandiseClass.RADIOACTIVE:
        return "Classe 7";
      case MerchandiseClass.CHEMICAL:
        return "Classe C";
      case MerchandiseClass.HYDROCARBONS:
        return "Hydrocarbures";
    }
  };

  public static toExamText = (p: string | PerimeterDto): string => {
    if (typeof p === "string") p = Perimeter.from(p);
    else p = Perimeter.from(p);

    return `${
      p.transportModes.length > 1
        ? "Tous modes"
        : Perimeter.transportModeToWord(p.transportModes[0])
    } / 
      ${
        p.merchandises.length > 1
          ? "Toutes classes"
          : Perimeter.merchandiseToWord(p.merchandises[0])
      }
    `;
  };
}

export { Perimeter };
