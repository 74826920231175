import {
  useQueryCandidateCanRegister,
  useQueryCandidateRegistrations,
} from "@/api/candidate/candidate";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/atoms/Tabs";
import BlueNavLink from "@/components/molecules/BlueNavLink";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { MyExamsTab } from "./my-exams-tab.enum";
import NoExams from "./NoExamsMessage";
import ToCome from "./ToCome/ToCome";

const Home = () => {
  const [currentTab, setCurrentTab] = usePersistent(
    "my-exams-tab",
    MyExamsTab.ToCome
  );

  const registrationsQuery = useQueryCandidateRegistrations();
  const canRegisterQuery = useQueryCandidateCanRegister();

  if (!registrationsQuery.data)
    return (
      <LoadingSpinner
        isLoading={registrationsQuery.isLoading}
        isError={registrationsQuery.isError}
        loadingMessage="Chargement de vos inscriptions..."
        errorMessage="Erreur lors du chargement de vos inscriptions."
      />
    );

  return (
    <div className="flex flex-col items-start gap-6 self-stretch py-8 px-28">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <h1 className="text-2xl">Mes examens</h1>
          <span className="text-gray-600 text-sm font-">
            M'inscrire et visualiser mes résultats aux examens.
          </span>
        </div>
        {registrationsQuery.data.length > 0 && (
          <BlueNavLink
            to={ROUTE.candidate.dashboard.exams.register.root()}
            // candidate can't register to multiple exams at the same time
            disabled={!canRegisterQuery.data}
          >
            S'inscrire à une session d'examen
          </BlueNavLink>
        )}
      </div>
      {registrationsQuery.data.length === 0 && <NoExams />}
      {registrationsQuery.data.length > 0 && (
        <Tabs
          value={currentTab}
          className="flex flex-col gap-6 w-full"
          onValueChange={(t) => setCurrentTab(t as MyExamsTab)}
        >
          <TabsList className="p-0.5 h-full w-fit">
            <TabsTrigger value={MyExamsTab.ToCome}>
              Mes examens à venir
            </TabsTrigger>
            <TabsTrigger value={MyExamsTab.DocumentsAndResults}>
              Mes documents et résultats
            </TabsTrigger>
          </TabsList>
          <TabsContent value={MyExamsTab.ToCome}>
            <ToCome />
          </TabsContent>
          <TabsContent value={MyExamsTab.DocumentsAndResults}>TODO</TabsContent>
        </Tabs>
      )}
    </div>
  );
};

export default Home;
