import { ExamType } from "@/api/enums/ExamType";
import { useMutationExamCreate } from "@/api/exam/exam";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import { Form } from "@/components/atoms/Form";
import {
  examDefaultValues,
  examSchema,
} from "@/components/molecules/ExamForm/exam-form-schema";
import ExamForm from "@/components/molecules/ExamForm/ExamForm";
import { ROUTE } from "@/constants/routes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { ChevronLeft, Plus } from "lucide-react";
import { useForm } from "react-hook-form";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { z } from "zod";

const schema = examSchema();
type Schema = z.infer<typeof schema>;

const SessionCreation = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: examDefaultValues,
  });

  const createMutation = useMutationExamCreate();

  const onSubmit = (data: Schema) => {
    createMutation.mutate(data, {
      onSuccess: (sessionId) => {
        toast.success("Session créée");
        queryClient.invalidateQueries({
          queryKey: keyFactory.exam.base(),
        });
        form.reset();
        navigate(ROUTE.admin.dashboard.session.information(sessionId));
      },
      onError: () => {
        toast.error("Erreur lors de la création de la session");
      },
    });
  };

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((data) => onSubmit(data))}
        className="flex w-full flex-col space-y-8"
      >
        <div className="w-full h-screen flex flex-col bg-white">
          <div className="flex flex-row items-center px-6 py-2 border-b border-b-gray-200 justify-between">
            <div className="flex flex-row items-center gap-4">
              <NavLink
                to={ROUTE.admin.dashboard.sessions.root()}
                className="flex p-2 rounded-lg border border-gray-200"
                type="button"
              >
                <ChevronLeft className="w-5 h-5" />
              </NavLink>
              <h1>Créer une session</h1>
            </div>
            <Button className="flex flex-row items-center gap-1 rounded-lg">
              <Plus />
              {createMutation.isPending
                ? "création de la session..."
                : `Créer la session ${
                    form.watch("type") === ExamType.INITIAL
                      ? "initiale"
                      : "renouvellement"
                  }`}
            </Button>
          </div>
          <div className="p-6">
            <ExamForm />
          </div>
        </div>
      </form>
    </Form>
  );
};

export default SessionCreation;
