import { TransportMode } from "@/api/enums/transport-mode.enum";
import { FormControl, FormField, FormItem } from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import {
  ColumnPerimeter,
  ColumnPerimeterUtil,
} from "@/constants/column-perimeter.enum";
import {
  PossiblePerimeter,
  QCMSizePerimeters,
} from "@/constants/possible-perimeters.enum";
import useChildForm from "@/hooks/ChildForm";
import { cn } from "@/lib/utils";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";

interface QCMParametersTableProps {
  disabled?: boolean;
  transportModes: TransportMode[];
  size: number;
}

const columnHelper = createColumnHelper<PossiblePerimeter>();

const QCMParametersTable = ({
  disabled = false,
  size,
  transportModes,
}: QCMParametersTableProps) => {
  const { control, watch } = useChildForm();

  const columns = useMemo(
    () => [
      columnHelper.accessor((x) => x, {
        id: "possiblePerimeter",
        header: "",
        cell: (info) => (
          <span className="text-gray-900 font-medium flex justify-center">
            {info.getValue()}
          </span>
        ),
        maxSize: 1,
      }),
      columnHelper.accessor((x) => x, {
        id: "total",
        header: () => <span className="font-bold px-2">Total</span>,
        cell: (info) => {
          const counts = watch(info.getValue()) as {
            [key in ColumnPerimeter]: number;
          };

          const total = Object.values(counts).reduce(
            (acc, val) => acc + Number(val),
            0
          );

          const colorClass =
            total > size
              ? "text-red-500"
              : total < size
              ? "text-yellow-500"
              : "text-green-500";

          return (
            <span className={`flex justify-center ${colorClass}`}>{total}</span>
          );
        },
      }),
      ...ColumnPerimeterUtil.sorted().map((columnPerimeter) =>
        columnHelper.accessor((x) => x, {
          id: columnPerimeter,
          header: columnPerimeter,
          maxSize: 100,
          minSize: 100,
          cell: (info) => {
            const name = `${info.getValue()}.${columnPerimeter}`;

            return (
              <FormField
                control={control}
                name={name}
                render={({ field }) => (
                  <FormItem className="min-w-16">
                    <FormControl>
                      <Input
                        className="text-end"
                        disabled={disabled}
                        {...field}
                        type="number"
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            );
          },
        })
      ),
    ],
    // `size` and `transportModes` are needed in the dependency array,
    // because `watch` does not change on filter changes
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [watch, disabled, control, size, transportModes]
  );

  const rows = useMemo(() => {
    const v = QCMSizePerimeters[size as keyof typeof QCMSizePerimeters];
    if (!v) return [];

    return v.filter((perimeter) =>
      Object.values(TransportMode).every(
        (t) => perimeter.includes(t) === transportModes.includes(t)
      )
    );
  }, [size, transportModes]);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col w-full overflow-auto max-h-[calc(100vh-200px)] rounded-lg border border-gray-200 relative">
      <Table className="text-gray-600 font-medium text-sm leading-6">
        <TableHeader className="items-center bg-gray-50 sticky top-0 z-10">
          <TableRow>
            {table.getHeaderGroups()[0].headers.map((header, index) => (
              <TableHead
                key={header.id}
                className={cn("p-0", {
                  "sticky left-0 z-20 bg-[#f7fafc]": index === 0,
                })}
                style={{ width: `${header.getSize()}px` }}
              >
                <div
                  className={cn("flex text-xs justify-center", {
                    "text-brand-600 font-bold": header.id !== "total",
                  })}
                >
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </div>
              </TableHead>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows.map((row, i) => (
            <TableRow
              key={row.id}
              className={cn("", {
                "bg-gray-50": i % 2,
              })}
            >
              {row.getVisibleCells().map((cell, index) => (
                <TableCell
                  key={cell.id}
                  className={cn("h-14 py-0 px-1", {
                    "sticky z-10 left-0": index === 0,
                    "bg-white": index === 0 && !(i % 2),
                    "bg-gray-50": index === 0 && i % 2,
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default QCMParametersTable;
