import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { Badge } from "@/components/atoms/Badge";

interface FormationAttestationBadgeProps {
  status: ValidationStatus;
}

const FormationAttestationsBadge = ({
  status,
}: FormationAttestationBadgeProps) => {
  switch (status) {
    case ValidationStatus.UNAVAILABLE:
      return <Badge variant="red">Non déposées</Badge>;
    case ValidationStatus.PENDING:
      return <Badge variant="orange">En attente de validation</Badge>;
    case ValidationStatus.VALIDATED:
      return <Badge variant="green">Validé</Badge>;
  }
};

export default FormationAttestationsBadge;
