import logo from "@/assets/logo.png";
import SubmitExamDialog from "./SubmitExamDialog";
// import { LifeBuoy } from "lucide-react";

interface TopBarProps {
  remainingQuestions: number;
}

const TopBar = ({ remainingQuestions }: TopBarProps) => {
  return (
    <div className="flex flex-row items-center justify-between bg-white py-4 px-20">
      <img className="w-11 h-11" src={logo} alt="logo" />
      <div className="flex flex-row items-center gap-3">
        <SubmitExamDialog remainingQuestions={remainingQuestions} />
        {/* <Button className="rounded-lg flex flex-row items-center gap-1">
          <LifeBuoy className="w-5 h-5" />
          Besoin d'aide
        </Button> */}
      </div>
    </div>
  );
};

export default TopBar;
