import { PerimeterDto } from "@/api/dto/perimeter.dto";
import { ResultsTab } from "@/api/exam/dto/results-tab.enum";
import { useQueryExamRegistrations } from "@/api/exam/exam";
import { Skeleton } from "@/components/atoms/Skeleton";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../../session-context";
import { generateColumns } from "./Columns";

interface CandidatesTableProps {
  searchText?: string;
  complete?: boolean;
  perimeters?: PerimeterDto[];
  resultsTab?: ResultsTab;
}

const CandidatesTable = ({
  searchText,
  complete,
  perimeters,
  resultsTab,
}: CandidatesTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent(
    "admin-session-candidates-pageIndex",
    0
  );
  const navigate = useNavigate();
  const { exam, tab } = useSessionContext();

  const { data: registrations, isLoading } = useQueryExamRegistrations(
    {
      examId: exam.id,
      pageIndex,
      searchText,
      complete,
      perimeters,
      resultsTab,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(() => generateColumns(exam, tab), [exam, tab]);
  const [rows, is_placeholder] = useTablePlaceholderRows(registrations);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!registrations)
    return isLoading ? (
      <div className="min-w-full min-h-96">
        <Skeleton className="w-full h-full" />
      </div>
    ) : (
      <LoadingSpinner
        isLoading={false}
        isError={true}
        errorMessage="Erreur lors du chargement des inscriptions"
      />
    );

  return (
    <>
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs whitespace-nowrap">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.candidate.onGoingSession(
                            row.original.candidate.id
                          ),
                          {
                            state: { from: location.pathname },
                          }
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-16 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={registrations.totalPages}
      />
    </>
  );
};

export default CandidatesTable;
