import { PaginationResponseDto } from "@/api/dto/pagination.dto";
import { useMemo } from "react";

// returns rows with placeholders,
// and a function to know if a row is a placeholder given its index

const useTablePlaceholderRows = <T>(
  paginatedReponse?: PaginationResponseDto<T>
): [T[], (index: number) => boolean] => {
  const [rows, is_placeholder] = useMemo(() => {
    if (!paginatedReponse?.data) return [[], () => false];
    const { data, itemsOnPage } = paginatedReponse;
    if (data.length === 0) return [[], () => false];

    const is_placeholder = (i: number) => i >= data.length;
    const placeholders_count = itemsOnPage - data.length;
    const rows: T[] = [...data, ...Array(placeholders_count).fill(data[0])];

    return [rows, is_placeholder];
  }, [paginatedReponse]);

  return [rows, is_placeholder];
};

export default useTablePlaceholderRows;
