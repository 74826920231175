import { ExamStatus } from "@/api/enums/exam-status.enum";
import { useQueryExamDetailed } from "@/api/exam/exam";
import { Button } from "@/components/atoms/Button";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import ExamStatusBadge from "@/components/molecules/Badges/ExamStatusBadge";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { ArrowLeft } from "lucide-react";
import { useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import CancelQuestionDialog from "./CancelQuestionDialog";
import GenerateNotesDialog from "./GenerateNotesDialog";
import { SessionContext } from "./session-context";
import { SessionTab } from "./session-tab.enum";

const Session = () => {
  const { id: idStr } = useParams();
  const examId = Number(idStr);
  const [tab, setTab] = usePersistent(
    "admin-session-tab",
    SessionTab.Candidates
  );
  const navigate = useNavigate();

  const { data: exam, isLoading, isError } = useQueryExamDetailed(examId);

  // navigate to current tab
  useEffect(() => {
    switch (tab) {
      case SessionTab.Candidates:
      case SessionTab.Results:
        navigate(ROUTE.admin.dashboard.session.candidates(examId));
        break;
      case SessionTab.Information:
        navigate(ROUTE.admin.dashboard.session.information(examId));
        break;
    }
  }, [tab, examId, navigate]);

  const context: SessionContext | undefined = exam ? { exam, tab } : undefined;

  if (idStr === undefined) navigate(ROUTE.admin.dashboard.sessions.root());

  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex flex-col w-full border-b border-b-gray-200 px-6 pt-3 gap-2">
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-4 items-center">
            <Button
              variant="ghostWithBorders"
              className="p-2 rounded-lg"
              onClick={() => navigate(ROUTE.admin.dashboard.sessions.root())}
            >
              <ArrowLeft className="w-5 h-5 text-gray-500" />
            </Button>
            {exam ? (
              <div className="flex flex-col justify-center">
                <div className="flex flex-row gap-2 items-center">
                  <h1>{exam.number}</h1>
                  <ExamStatusBadge
                    registeringEndDate={new Date(exam.registeringEndDate)}
                    status={exam.status}
                  />
                </div>
                <div className="flex flex-row text-gray-600 gap-4 items-center">
                  <span>
                    Clôture inscr. :{" "}
                    {new Date(exam.registeringEndDate).toLocaleDateString(
                      "fr-FR"
                    )}
                  </span>
                  <span>
                    Clôture att. form. :{" "}
                    {new Date(
                      exam.formationCertificateEndDate
                    ).toLocaleDateString("fr-FR")}
                  </span>
                  <span>
                    Examen : {new Date(exam.date).toLocaleDateString("fr-FR")}
                  </span>
                </div>
              </div>
            ) : (
              <LoadingSpinner
                isLoading={isLoading}
                // error if sessions are loaded but no session is found
                // with given id
                isError={isError || !!exam}
                loadingMessage="Chargement de la session..."
                errorMessage="Erreur lors du chargement de la session."
              />
            )}
          </div>
          <div className="flex flex-row gap-2 items-center">
            {/* <Button
              variant="ghostWithBorders"
              className="text-gray-800 font-semibold"
            >
              Exporter
            </Button> */}
            {exam &&
              [ExamStatus.QCMS_NOT_GENERATED, ExamStatus.PASSED].includes(
                exam.status
              ) && (
                <Button
                  variant="ghostWithBorders"
                  className="text-gray-800 font-semibold"
                  onClick={() =>
                    navigate(ROUTE.admin.dashboard.live.session(examId))
                  }
                >
                  Accéder à la session en temps réel
                </Button>
              )}
            {exam?.status === ExamStatus.CORRECTED && (
              <GenerateNotesDialog examId={exam.id} />
            )}
            {exam?.status &&
              [
                ExamStatus.PASSED,
                ExamStatus.CORRECTED,
                ExamStatus.CERTIFICATES_GENERATED,
                ExamStatus.NOTES_GENERATED,
              ].includes(exam.status) && (
                <CancelQuestionDialog examId={exam.id} />
              )}
          </div>
        </div>
        <Tabs onValueChange={(e) => setTab(e as SessionTab)} value={tab}>
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={SessionTab.Candidates}>
              Suivi candidats
            </TabsTrigger>
            {exam &&
              [
                ExamStatus.CORRECTED,
                ExamStatus.NOTES_GENERATED,
                ExamStatus.CERTIFICATES_GENERATED,
              ].includes(exam.status) && (
                <TabsTrigger variant="underlineBlue" value={SessionTab.Results}>
                  Résultats
                </TabsTrigger>
              )}
            <TabsTrigger variant="underlineBlue" value={SessionTab.Information}>
              Informations session
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      {context && <Outlet context={context} />}
    </div>
  );
};

export default Session;
