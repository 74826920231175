import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "@/components/atoms/Form";
import { RadioGroup, RadioGroupItem } from "@/components/atoms/RadioGroup";
import AddressForm from "@/components/molecules/AddressForm/AddressForm";
import {
  addressDefaultValues,
  mandatoryAddressSchema,
} from "@/components/molecules/AddressForm/AddressFormSchema";
import DelegationForm from "@/components/molecules/DelegationForm/DelegationForm";
import {
  delegationDefaultValues,
  delegationSchema,
} from "@/components/molecules/DelegationForm/DelegationFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import NextStepButton from "../NextStepButtons";
import { useRegisterContext } from "../RegisterContext";

const payementSchema = z.discriminatedUnion("hasDelegation", [
  z.object({
    hasDelegation: z.literal(true),
    delegationDraft: delegationSchema,
  }),
  z.object({
    hasDelegation: z.literal(false),
    billingAddress: mandatoryAddressSchema,
  }),
]);

const Payement = () => {
  const { changeDraft, onNextStep, registrationDraft, pending } =
    useRegisterContext();

  const form = useForm<z.infer<typeof payementSchema>>({
    resolver: zodResolver(payementSchema),
    defaultValues: {
      hasDelegation: !!registrationDraft.delegationDraft,
      delegationDraft:
        registrationDraft.delegationDraft ?? delegationDefaultValues,
      billingAddress: registrationDraft.billingAddress ?? addressDefaultValues,
    },
  });

  const { watch, reset } = form;
  const hasDelegation = watch("hasDelegation");

  useEffect(() => {
    if (hasDelegation) {
      reset({
        hasDelegation: true,
        delegationDraft:
          registrationDraft.delegationDraft || delegationDefaultValues,
      });
    } else {
      reset({
        hasDelegation: false,
        billingAddress:
          registrationDraft.billingAddress || addressDefaultValues,
      });
    }
  }, [
    hasDelegation,
    reset,
    registrationDraft.billingAddress,
    registrationDraft.delegationDraft,
  ]);

  const onSubmit = (data: z.infer<typeof payementSchema>) => {
    changeDraft({
      ...(data.hasDelegation && {
        delegationDraft: data.delegationDraft,
      }),
      ...(!data.hasDelegation && { billingAddress: data.billingAddress }),
    });
  };

  return (
    <div className="flex flex-col gap-8 items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-6 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Délégation</h1>
            <span className="text-gray-600 text-sm font-normal">
              La délégation permet la prise en charge du paiement de vos frais
              d'inscription, directement par votre entreprise (délégataire
              désigné). Le délégataire peut également gérer le dépôt des
              documents requis pour l'inscription (attestation(s) de formation)
              et a un accès à la note de débit, la convocation et l'attestation
              de présence. Vos autres données et documents restent personnels et
              confidentiels.Vous restez responsable du suivi de votre
              inscription et nous vous invitons à vous connecter régulièrement à
              votre espace pour consulter l'avancement de votre inscription.
            </span>

            <FormField
              control={form.control}
              name="hasDelegation"
              render={({ field }) => (
                <FormItem>
                  <span className="font-semibold text-black">
                    Souhaitez-vous déléguer à une société le paiement de votre
                    examen ?
                  </span>
                  <FormControl>
                    <RadioGroup
                      id={field.name}
                      name={field.name}
                      defaultValue={String(field.value)}
                      onValueChange={(val) => field.onChange(val === "true")}
                      className="flex flex-row gap-6 w-full mt-1"
                    >
                      {[
                        {
                          value: false,
                          msg: "Non",
                        },
                        {
                          value: true,
                          msg: "Oui",
                        },
                      ].map(({ value, msg }) => {
                        const itemId = `${field.name}${value}`;

                        return (
                          <div
                            className="flex items-center self-stretch"
                            key={itemId}
                          >
                            <RadioGroupItem
                              id={itemId}
                              value={String(value)}
                              className="flex items-center justify-center gap-2"
                            />
                            <FormLabel htmlFor={itemId}>{msg}</FormLabel>
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                </FormItem>
              )}
            />
            <div className="flex items-start flex-col p-4 gap-2 self-stretch border border-gray-200 rounded-sm">
              {hasDelegation && (
                <>
                  <h2>Informations personnelles du délégataire désigné</h2>
                  <DelegationForm fieldName="delegationDraft" />
                </>
              )}

              {!hasDelegation && (
                <AddressForm
                  fieldName="billingAddress"
                  title="Votre adresse de facturation"
                  isMandatory={true}
                />
              )}
            </div>
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data))}
            onPrevious={() => onNextStep(false)}
            pending={pending}
          />
        </form>
      </Form>
    </div>
  );
};

export default Payement;
