import { PayementStatus } from "@/api/enums/PayementStatus";

export class PayementUtil {
  static isPaid = (payementStatus: PayementStatus) =>
    (
      [
        PayementStatus.PERSONAL_PAID_CARD,
        PayementStatus.PERSONAL_PAID_TRANSFER,
        PayementStatus.DELEGATION_PAID_CARD,
        PayementStatus.DELEGATION_PAID_TRANSFER,
      ] as PayementStatus[]
    ).includes(payementStatus);

  static isWaitingValidation = (payementStatus: PayementStatus) =>
    payementStatus === PayementStatus.PERSONAL_WAITING_FOR_VALIDATION ||
    payementStatus === PayementStatus.DELEGATION_WAITING_FOR_VALIDATION;

  static delegationPaid = (payementStatus: PayementStatus) =>
    payementStatus === PayementStatus.DELEGATION_PAID_CARD ||
    payementStatus === PayementStatus.DELEGATION_PAID_TRANSFER;

  static personalPaid = (payementStatus: PayementStatus) =>
    payementStatus === PayementStatus.PERSONAL_PAID_CARD ||
    payementStatus === PayementStatus.PERSONAL_PAID_TRANSFER;

  static paidByCard = (payementStatus: PayementStatus) =>
    payementStatus === PayementStatus.PERSONAL_PAID_CARD ||
    payementStatus === PayementStatus.DELEGATION_PAID_CARD;

  static paidByTransfer = (payementStatus: PayementStatus) =>
    payementStatus === PayementStatus.PERSONAL_PAID_TRANSFER ||
    payementStatus === PayementStatus.DELEGATION_PAID_TRANSFER;

  static paidStatus = () => [
    PayementStatus.PERSONAL_PAID_CARD,
    PayementStatus.PERSONAL_PAID_TRANSFER,
    PayementStatus.DELEGATION_PAID_CARD,
    PayementStatus.DELEGATION_PAID_TRANSFER,
  ];
}
