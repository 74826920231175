import { format } from "date-fns";

const ROUTE = {
  home: () => "/",
  connectionHome: () => "/home",
  seedData: () => "/seed-data",
  admin: {
    root: () => "/admin",
    login: () => ROUTE.admin.root() + "/login",
    dashboard: {
      root: () => ROUTE.admin.root() + "/dashboard",
      candidates: () => ROUTE.admin.dashboard.root() + "/candidates",
      candidate: {
        root: (id?: number) =>
          ROUTE.admin.dashboard.root() +
          (id ? `/candidate/${id}` : "/candidate"),
        onGoingSession: (id: number) =>
          ROUTE.admin.dashboard.candidate.root(id),
        results: (id: number) =>
          ROUTE.admin.dashboard.candidate.root(id) + "/results",
        documents: (id: number) =>
          ROUTE.admin.dashboard.candidate.root(id) + "/documents",
        personalInformation: (id: number) =>
          ROUTE.admin.dashboard.candidate.root(id) + "/personal-information",
        certificates: (id: number) =>
          ROUTE.admin.dashboard.candidate.root(id) + "/certificates",
      },
      sessions: {
        root: () => ROUTE.admin.dashboard.root() + "/sessions",
        creation: () => ROUTE.admin.dashboard.sessions.root() + "/creation",
      },
      foreignCertificates: () =>
        ROUTE.admin.dashboard.root() + "/foreign-certificates",
      sessionCreation: () => ROUTE.admin.dashboard.root() + "/session-creation",
      session: {
        root: (sessionId?: number) =>
          ROUTE.admin.dashboard.root() +
          (sessionId !== undefined ? `/session/${sessionId}` : "/session"),
        candidates: (sessionId: number) =>
          ROUTE.admin.dashboard.session.root(sessionId),
        information: (sessionId: number) =>
          ROUTE.admin.dashboard.session.root(sessionId) + "/information",
      },
      companies: () => ROUTE.admin.dashboard.root() + "/companies",
      company: (companyId: number) =>
        ROUTE.admin.dashboard.root() + `/company/${companyId}`,
      questions: () => ROUTE.admin.dashboard.root() + "/questions",
      question: (questionId: number) =>
        ROUTE.admin.dashboard.root() + `/question/${questionId}`,
      administration: {
        root: () => ROUTE.admin.dashboard.root() + "/administration",
        formationInstitutes: () =>
          ROUTE.admin.dashboard.administration.root() + "/formation-institutes",
        prices: () => ROUTE.admin.dashboard.administration.root() + "/prices",
        admissionNotes: () =>
          ROUTE.admin.dashboard.administration.root() + "/admission-notes",
        QCMParameters: () =>
          ROUTE.admin.dashboard.administration.root() + "/qcm-parameters",
        exports: () => ROUTE.admin.dashboard.administration.root() + "/exports",
      },
      registrations: () => ROUTE.admin.dashboard.root() + "/registrations",
      payements: () => ROUTE.admin.dashboard.root() + "/payements",
      qcms: () => ROUTE.admin.dashboard.root() + "/qcms",
      qcm: (date: Date) =>
        `${ROUTE.admin.dashboard.root()}/qcm/${format(date, "yyyy-MM-dd")}`,
      live: {
        root: () => ROUTE.admin.dashboard.root() + "/live",
        sessions: () => ROUTE.admin.dashboard.live.root() + "/sessions",
        session: (examId: number) =>
          ROUTE.admin.dashboard.live.root() + `/session/${examId}`,
      },
    },
  },
  candidate: {
    root: () => "/candidate",
    accountCreation: () => ROUTE.candidate.root() + "/account-creation",
    dashboard: {
      root: () => ROUTE.candidate.root() + "/dashboard",
      profile: () => ROUTE.candidate.dashboard.root() + "/profile",
      exams: {
        root: () => ROUTE.candidate.dashboard.root() + "/exams",
        home: () => ROUTE.candidate.dashboard.exams.root() + "/home",
        register: {
          root: () => ROUTE.candidate.dashboard.exams.root() + "/register",
          checkInfo: () =>
            ROUTE.candidate.dashboard.exams.register.root() + "/check-info",
          acknowledgment: () =>
            ROUTE.candidate.dashboard.exams.register.root() + "/acknowledgment",
          certificate: () =>
            ROUTE.candidate.dashboard.exams.register.root() + "/certificate",
          pricing: () =>
            ROUTE.candidate.dashboard.exams.register.root() + "/pricing",
          payement: () =>
            ROUTE.candidate.dashboard.exams.register.root() + "/payement",
          registerStep: () =>
            ROUTE.candidate.dashboard.exams.register.root() + "/register-step",
        },
      },
    },
  },
  company: {
    root: () => "/company",
    dashboard: {
      root: () => ROUTE.company.root() + "/dashboard",
      information: () => ROUTE.company.dashboard.root() + "/information",
      delegations: () => ROUTE.company.dashboard.root() + "/delegations",
      candidates: () => ROUTE.company.dashboard.root() + "/candidates",
    },
    accountCreation: () => ROUTE.company.root() + "/account-creation",
  },
  exam: {
    root: () => "/exam",
    questions: () => ROUTE.exam.root(),
    login: () => ROUTE.exam.root() + "/login",
    done: () => ROUTE.exam.root() + "/done",
  },
  forgotten: {
    root: () => "/forgotten",
    password: {
      root: () => ROUTE.forgotten.root() + "/password",
      ask: () => ROUTE.forgotten.password.root() + "/ask",
      reset: () => ROUTE.forgotten.password.root() + "/reset",
    },
    email: {
      root: () => ROUTE.forgotten.root() + "/email",
      ask: () => ROUTE.forgotten.email.root() + "/ask",
    },
  },
};

export { ROUTE };
