import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/atoms/Dialog";
import { socket } from "@/hooks/socket";
import { useQueryClient } from "@tanstack/react-query";
import { CheckIcon } from "lucide-react";
import { useState } from "react";
import { toast } from "sonner";

interface CloseExamDialogProps {
  examId: number;
}

const CloseExamDialog = ({ examId }: CloseExamDialogProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const queryClient = useQueryClient();

  return (
    <Dialog open={open} onOpenChange={(e) => setOpen(e)}>
      <DialogTrigger asChild>
        <Button className="flex flex-row items-center gap-1">
          <CheckIcon className="w-4 h-4 text-brand-600 bg-white rounded-full p-0.5" />
          <span>Clôturer l'examen</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="p-4" onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogDescription hidden>
            Confirmer la clôture de l'examen ?
          </DialogDescription>
          <DialogTitle>Clôturer l'examen</DialogTitle>
        </DialogHeader>
        <div className="flex flex-row gap-2">
          <Button
            onClick={() => setOpen(false)}
            variant="ghostWithBorders"
            className="flex-grow"
          >
            Annuler
          </Button>
          <Button
            disabled={loading}
            onClick={() => {
              setLoading(true);
              socket.emit("closeExam", { examId }, (success: boolean) => {
                setLoading(false);
                if (success) {
                  setOpen(false);
                  toast.success("L'examen a bien été clôturé");
                } else toast.error("Une erreur est survenue");

                queryClient.invalidateQueries({
                  queryKey: keyFactory.exam.base(),
                });
              });
            }}
            className="flex-grow"
          >
            {!loading ? "Valider" : "En cours..."}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CloseExamDialog;
