import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import usePersistent from "@/hooks/use-persistent";
import { CandidatesTab } from "./candidates-tab.enum";

const completePerTab = {
  [CandidatesTab.All]: undefined,
  [CandidatesTab.Incomplete]: false,
  [CandidatesTab.Complete]: true,
};

interface CandidatesFilterTabsProps {
  setComplete: React.Dispatch<React.SetStateAction<boolean | undefined>>;
}

const CandidatesFilterTabs = ({ setComplete }: CandidatesFilterTabsProps) => {
  const [tab, setTab] = usePersistent<CandidatesTab>(
    "admin-session-candidates-tab",
    CandidatesTab.All
  );

  return (
    <Tabs
      onValueChange={(e) => {
        setTab(e as CandidatesTab);
        setComplete(completePerTab[e as CandidatesTab]);
      }}
      value={tab}
    >
      <TabsList
        variant="transparent"
        className="pb-0 mb-0 border-b border-b-gray-200 rounded-none"
      >
        {[
          {
            name: "Tous",
            tab: CandidatesTab.All,
          },
          {
            name: "En cours",
            tab: CandidatesTab.Incomplete,
          },
          {
            name: "Finalisée",
            tab: CandidatesTab.Complete,
          },
        ].map(({ name, tab }) => (
          <TabsTrigger variant="underlineBlue" value={tab} key={tab}>
            {name}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  );
};

export default CandidatesFilterTabs;
