import { Button } from "@/components/atoms/Button";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import DisconnectPrompt from "@/components/molecules/DisconnectPrompt";
import { PhoneInput } from "@/components/molecules/PhoneInput";
import { ROUTE } from "@/constants/routes";
import { Edit } from "lucide-react";
import { useState } from "react";
import { useFormContext } from "react-hook-form";

interface InfoFormProps {
  disabled: boolean;
}
const InfoForm = ({ disabled }: InfoFormProps) => {
  const { control } = useFormContext();
  const [disconnectsOpen, setDisconnectsOpen] = useState(false);

  return (
    <div className="flex flex-col w-full gap-2">
      <DisconnectPrompt
        isOpen={disconnectsOpen}
        close={() => setDisconnectsOpen(false)}
        redirectionPage={ROUTE.forgotten.password.ask()}
      />
      <h2>Informations entreprise</h2>
      <div className="flex flex-row gap-6">
        <FormField
          control={control}
          name="personName"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Nom</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="phoneNumber"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Mobile</FormLabel>
              <FormControl>
                <PhoneInput
                  defaultCountry="FR"
                  disabled={disabled}
                  placeholder={"01 XX XX XX XX"}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex flex-row gap-6">
        <FormField
          control={control}
          name="personSurname"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Prénom</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name="email"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Adresse email *</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
      <div className="flex flex-row gap-6">
        <FormField
          control={control}
          name="name"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormLabel>Nom de l'entreprise *</FormLabel>
              <FormControl>
                <Input placeholder={"-"} disabled={disabled} {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {!disabled && (
          <div className="flex w-full items-end">
            <Button
              type="button"
              variant="outlineBlue"
              onClick={() => setDisconnectsOpen(true)}
              className="w-full"
            >
              <Edit className="w-4 h-4" />
              Modifier mon mot de passe
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default InfoForm;
