import {
  QuestionBasicDtoClient,
  QuestionBasicPaginationRequestDto,
} from "@/api/question/dto/question.dto";
import { useQuestionsQuery } from "@/api/question/question";
import { Skeleton } from "@/components/atoms/Skeleton";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ActiveBadge from "./Badges/ActiveBadge";
import MerchandiseBadge from "./Badges/MerchandisesBadge";
import TransportModeBadge from "./Badges/TransportModeBadge";

const columnHelper = createColumnHelper<QuestionBasicDtoClient>();

interface QuestionsTableProps {
  filters: Partial<QuestionBasicPaginationRequestDto>;
}

const QuestionsTable = ({ filters }: QuestionsTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent(
    "admin-questions-pageIndex",
    0
  );
  const navigate = useNavigate();
  const { isLoading, data: questions } = useQuestionsQuery(
    {
      pageIndex,
      ...filters,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("active", {
        id: "active",
        header: "",
        cell: (info) => <ActiveBadge active={info.getValue()} />,
      }),
      columnHelper.accessor("number", {
        id: "number",
        header: "N°",
        cell: (info) => <span>{info.getValue()}</span>,
      }),
      columnHelper.accessor("text", {
        id: "text",
        header: "Question",
        cell: (info) => (
          <span className="text-nowrap text-ellipsis overflow-hidden max-w-60 inline-block">
            {info.getValue()}
          </span>
        ),
      }),
      columnHelper.accessor("perimeter.transportModes", {
        id: "transportModes",
        header: "Mode",
        cell: (info) => <TransportModeBadge transportModes={info.getValue()} />,
      }),
      columnHelper.accessor("perimeter.merchandises", {
        id: "merchandises",
        header: "Classe",
        cell: (info) => <MerchandiseBadge merchandises={info.getValue()} />,
      }),
      columnHelper.accessor("rulingDate", {
        id: "rulingDate",
        header: "Année de validation",
        cell: (info) => {
          const date = info.getValue();
          return (
            <span>
              {date ? new Date(date).toLocaleDateString("fr-FR") : "-"}
            </span>
          );
        },
      }),
      columnHelper.accessor("deactivationDate", {
        id: "deactivationDate",
        header: "Date de désactivation",
        cell: (info) => {
          const date = info.getValue();
          return (
            <span>
              {date ? new Date(date).toLocaleDateString("fr-FR") : "-"}
            </span>
          );
        },
      }),
      columnHelper.accessor("activationDate", {
        id: "activationDate",
        header: "Date d'activation",
        cell: (info) => {
          const date = info.getValue();
          return (
            <span>
              {date ? new Date(date).toLocaleDateString("fr-FR") : "-"}
            </span>
          );
        },
      }),
    ],
    []
  );

  const [rows, is_placeholder] = useTablePlaceholderRows(questions);

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!questions)
    return isLoading ? (
      <div className="min-w-full min-h-96">
        <Skeleton className="w-full h-full" />
      </div>
    ) : (
      <LoadingSpinner
        isLoading={false}
        isError={true}
        errorMessage="Erreur lors du chargement des questions"
      />
    );

  return (
    <>
      <div className="rounded-lg overflow-auto border border-gray-200">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody className="text-gray-900">
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.question(row.original.id)
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-16 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={questions.totalPages}
      />
    </>
  );
};

export default QuestionsTable;
