import { keyFactory } from "@/api/keyFactory";
import { Form } from "@/components/atoms/Form";
import {
  examDefaultValues,
  ExamFormField,
  examSchema,
} from "@/components/molecules/ExamForm/exam-form-schema";
import ExamForm from "@/components/molecules/ExamForm/ExamForm";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

import { useMutationExamModify } from "@/api/exam/exam";
import ModifiableForm from "@/components/molecules/ModifiableForm";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { z } from "zod";
import { useSessionContext } from "../session-context";

const schema = examSchema(true);
type Schema = z.infer<typeof schema>;

const Information = () => {
  const { id: idStr } = useParams();
  const examId = Number(idStr);
  const queryClient = useQueryClient();
  const { exam } = useSessionContext();
  const [modifiable, setModifiable] = useState(false);

  const modifyMutation = useMutationExamModify();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: examDefaultValues,
  });

  const onSubmit = (data: Schema) => {
    const { registeringStartDate, ...filteredData } = data;

    modifyMutation.mutate(
      { examId: examId, ...filteredData },
      {
        onSuccess: () => {
          toast.success("Session modifiée");
          queryClient.invalidateQueries({
            queryKey: keyFactory.exam.base(),
          });
          setModifiable(false);
        },
        onError: () => {
          toast.error("Erreur lors de la modification de la session");
        },
      }
    );
  };

  const { reset } = form;
  // reset form when data is received
  useEffect(() => {
    reset(exam);
  }, [exam, reset]);

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit((data) => onSubmit(data))}>
        <ModifiableForm
          onCancel={() => form.reset(exam)}
          isModifiable={modifiable}
          setModifiable={setModifiable}
          className="bg-gray-25 m-6 border border-gray-200"
        >
          <div className="flex w-full pt-10">
            <ExamForm
              currentNumber={exam.number}
              disabledFields={
                modifiable
                  ? [ExamFormField.RegisteringStartDate]
                  : Object.values(ExamFormField)
              }
            />
          </div>
        </ModifiableForm>
      </form>
    </Form>
  );
};

export default Information;
