import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { Role } from "../enums/Role";
import { keyFactory } from "../keyFactory";
import {
  AdminCompanyDtoClient,
  AdminCompanyPaginationRequestDto,
} from "./dto/company-admin.dto";
import { CompanyDelegationDtoClient } from "./dto/company-delegation.dto";
import { CompanyInfoDto } from "./dto/company-info.dto";

// info

type CompanyInfoType = {
  [Role.ADMIN]: AdminCompanyDtoClient;
  [Role.CANDIDATE]: CompanyInfoDto;
};

const getInfo = async <K extends keyof CompanyInfoType>(companyId?: number) =>
  axios
    .get<CompanyInfoType[K]>(ENDPOINT.company.getInfo(companyId))
    .then((res) => res.data);

export const useQueryCompanyInfo = <K extends keyof CompanyInfoType>(
  companyId?: number,
  props?: Partial<UseQueryOptions<CompanyInfoType[K], AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.company.info(companyId),
    queryFn: () => getInfo<K>(companyId),
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });

const changeInfo = async (data: CompanyInfoDto, companyId?: number) =>
  await axios.post<void>(ENDPOINT.company.postChangeInfo(companyId), data);

export const useMutationCompanyChangeInfo = (
  companyId?: number,
  props?: UseMutationOptions<AxiosResponse<void>, AxiosError, CompanyInfoDto>
) =>
  useMutation({
    mutationFn: (data: CompanyInfoDto) => changeInfo(data, companyId),
    ...props,
  });

// delegations

const getDelegations = async () =>
  axios
    .get<CompanyDelegationDtoClient[]>(ENDPOINT.company.getDelegations())
    .then((res) => res.data);

export const useQueryCompanyDelegations = (
  props?: Partial<UseQueryOptions<CompanyDelegationDtoClient[], AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.company.delegations(),
    queryFn: getDelegations,
    staleTime: Infinity,
    ...props,
  });

// all paginated

const allPaginated = async (params: AdminCompanyPaginationRequestDto) =>
  axios
    .get<PaginationResponseDto<AdminCompanyDtoClient>>(
      ENDPOINT.company.getAllPaginated(),
      {
        params,
      }
    )
    .then(({ data }) => data);

export const useQueryCompanyAllPaginated = (
  params: AdminCompanyPaginationRequestDto,
  props?: Partial<UseQueryOptions<PaginationResponseDto<AdminCompanyDtoClient>>>
) =>
  useQuery({
    queryKey: keyFactory.company.allPaginated(params),
    queryFn: () => allPaginated(params),
    staleTime: Infinity,
    gcTime: 1000 * 60,
    ...props,
  });
