import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { ExamDtoClient } from "../dto/exam.dto";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { PerimeterDto } from "../dto/perimeter.dto";
import { ExamType } from "../enums/ExamType";
import { keyFactory } from "../keyFactory";
import { AdminRegistrationDtoClient } from "../registration-dto/dto/admin-registration.dto";
import { ExamCreationDto } from "./dto/exam-creation.dto";
import { ExamDetailedDtoClient } from "./dto/exam-detailed.dto";
import { ExamModificationDto } from "./dto/exam-modification.dto";
import { ExamPaginationRequestDto } from "./dto/exams-paginated.dto";
import { RegistrationsPaginationRequestDto } from "./dto/registrations.dto";
import { RemovePreRegisteredDto } from "./dto/remove-pre-registered.dto";

// all

const allExams = async () =>
  axios
    .get<ExamDtoClient[] | null>(ENDPOINT.exam.getAll())
    .then((res) => res.data);

export const useQueryExamAll = (
  props?: Partial<UseQueryOptions<ExamDtoClient[] | null, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.exam.all(),
    queryFn: allExams,
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });

// all detailed paginated

const allExamsDetailed = async (params: ExamPaginationRequestDto) =>
  axios
    .get<PaginationResponseDto<ExamDetailedDtoClient>>(
      ENDPOINT.exam.getAllDetailedPaginated(),
      {
        params,
      }
    )
    .then((res) => res.data);

export const useQueryExamDetailedPaginated = (
  params: ExamPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<ExamDetailedDtoClient>, AxiosError>
  >
) =>
  useQuery({
    queryKey: keyFactory.exam.detailedPaginated(params),
    queryFn: () => allExamsDetailed(params),
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });

// detailed

const getDetailedExam = async (examId: number) =>
  axios
    .get<ExamDetailedDtoClient>(ENDPOINT.exam.getDetailed(examId))
    .then(({ data }) => data);

export const useQueryExamDetailed = (
  examId: number,
  props?: Partial<UseQueryOptions<ExamDetailedDtoClient>>
) =>
  useQuery({
    queryKey: keyFactory.exam.detailed(examId),
    queryFn: () => getDetailedExam(examId),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 5,
    ...props,
  });

// next number

const nextExamNumber = async (type: ExamType) =>
  axios
    .get<string | null>(ENDPOINT.exam.getNextNumber(type))
    .then((res) => res.data);

export const useQueryExamNextNumber = (
  type: ExamType,
  props?: Partial<UseQueryOptions<string | null, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.exam.nextNumber(type),
    queryFn: () => nextExamNumber(type),
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });

// create

const create = async (body: ExamCreationDto) => {
  return await axios
    .post<number>(ENDPOINT.exam.postCreate(), body)
    .then(({ data }) => data);
};

export const useMutationExamCreate = (
  props?: Partial<UseMutationOptions<number, AxiosError, ExamCreationDto>>
) =>
  useMutation({
    mutationFn: create,
    ...props,
  });

// modify

const modify = async (body: ExamModificationDto) => {
  await axios.post<void>(ENDPOINT.exam.postModify(), body);
};

export const useMutationExamModify = (
  props?: Partial<UseMutationOptions<void, AxiosError, ExamModificationDto>>
) =>
  useMutation({
    mutationFn: modify,
    ...props,
  });

// registrations

const registrations = async (params: RegistrationsPaginationRequestDto) =>
  axios
    .get<PaginationResponseDto<AdminRegistrationDtoClient>>(
      ENDPOINT.exam.getRegistrations(),
      {
        params,
      }
    )
    .then(({ data }) => data);

export const useQueryExamRegistrations = (
  params: RegistrationsPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<AdminRegistrationDtoClient>>
  >
) =>
  useQuery({
    queryKey: keyFactory.exam.registrations(params),
    queryFn: () => registrations(params),
    staleTime: 1000 * 60 * 60,
    gcTime: 1000 * 60 * 5,
    ...props,
  });

// remove pre-registered

const removePreRegistered = async (body: RemovePreRegisteredDto) => {
  await axios.post<void>(ENDPOINT.exam.postRemovePreRegistered(), body);
};

export const useMutationExamRemovePreRegistered = (
  props?: Partial<UseMutationOptions<void, AxiosError, RemovePreRegisteredDto>>
) =>
  useMutation({
    mutationFn: removePreRegistered,
    ...props,
  });

// perimeters

const perimeters = async (examId: number) =>
  axios
    .get<PerimeterDto[]>(ENDPOINT.exam.getPerimeters(examId))
    .then((res) => res.data);

export const useQueryExamPerimeters = (
  examId: number,
  props?: Partial<UseQueryOptions<PerimeterDto[], AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.exam.perimeters(examId),
    queryFn: () => perimeters(examId),
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
