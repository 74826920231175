import { Button } from "@/components/atoms/Button";
import GreenCheck from "@/components/atoms/GreenCheck";
import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
} from "@atoms/Dialog";
import { Send } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

interface SubmitExamDialogProps {
  remainingQuestions: number;
}

const SubmitExamDialog = ({ remainingQuestions }: SubmitExamDialogProps) => {
  const [open, setOpen] = useState(false);
  const [pending, setPending] = useState(false);
  const navigate = useNavigate();

  const onAcknowledge = (success: boolean) => {
    setPending(false);
    if (success) {
      setOpen(false);
      socket.disconnect();
      toast.success("Examen soumis");
      navigate(ROUTE.exam.done());
    } else
      toast.error("Erreur", {
        description:
          "Votre examen n'a pas pu être soumis (mais vos réponses ont bien été enregistrées).",
      });
  };

  return (
    <Dialog open={open} onOpenChange={(e) => setOpen(e)}>
      <DialogTrigger asChild>
        <Button className="rounded-lg">Soumettre mon examen</Button>
      </DialogTrigger>
      <DialogContent
        className="w-full flex flex-col justify-between items-center min-h-64 max-w-xl"
        onClick={(e) => e.stopPropagation()}
      >
        <GreenCheck />
        <div className="flex flex-col gap-1 items-center">
          <DialogTitle>Soumettre mon examen</DialogTitle>
          <DialogDescription className="text-center">
            Attention, il vous reste{" "}
            {remainingQuestions
              ? "des questions sans réponses"
              : "encore du temps"}
            , êtes vous sûr de vouloir soumettre votre examen ? Vous ne pourrez
            plus effectuer de modifications.
          </DialogDescription>
        </div>
        <div className="flex flex-row items-center gap-4 w-full">
          <Button
            variant="ghostWithBorders"
            className="w-1/2"
            onClick={() => setOpen(false)}
            disabled={pending}
          >
            Retour
          </Button>
          <Button
            variant="green"
            className="w-1/2 flex flex-row items-center gap-1.5"
            onClick={() => {
              socket.emit("submit", {}, onAcknowledge);
              setOpen(false);
            }}
            disabled={pending}
          >
            <Send className="w-4 h-4" />
            {pending ? "En cours..." : "Confirmer et envoyer"}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitExamDialog;
