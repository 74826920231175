import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { DelegationStatus } from "@/api/enums/DelegationStatus";
import { PayementStatus } from "@/api/enums/PayementStatus";
import { PriceType } from "@/api/enums/PriceType";
import { ValidationStatus } from "@/api/enums/ValidationStatus";
import { PayementUtil } from "./payement-util";

export class RegistrationUtil {
  static isCompleted(registration: CandidateRegistrationDtoClient) {
    const { formationCertificatesStatus, reducedPriceStatus, preTestStatus } =
      registration;

    return (
      formationCertificatesStatus === ValidationStatus.VALIDATED &&
      [ValidationStatus.VALIDATED, ValidationStatus.UNAVAILABLE].includes(
        reducedPriceStatus
      ) &&
      preTestStatus === ValidationStatus.VALIDATED &&
      PayementUtil.isPaid(registration.payementStatus)
    );
  }

  static currentDebitNote = (registration: CandidateRegistrationDtoClient) => {
    const {
      delegation,
      debitCompanyPaidFile,
      debitCompanyUnpaidFile,
      debitPersonalPaidFile,
      debitPersonalUnpaidFile,
    } = registration;

    return delegation
      ? PayementUtil.delegationPaid(registration.payementStatus)
        ? debitCompanyPaidFile
        : debitCompanyUnpaidFile
      : PayementUtil.personalPaid(registration.payementStatus)
      ? debitPersonalPaidFile
      : debitPersonalUnpaidFile;
  };

  static candidateCanPay = (registration: CandidateRegistrationDtoClient) => {
    const { delegation, payementStatus, priceType, reducedPriceStatus } =
      registration;

    return (
      !PayementUtil.isPaid(registration.payementStatus) &&
      ((delegation && delegation.status === DelegationStatus.REFUSED) ||
        (!delegation &&
          payementStatus === PayementStatus.UNPAID &&
          (priceType === PriceType.FULL ||
            // reduced price document must be validated
            // in order to pay
            (priceType === PriceType.REDUCED &&
              reducedPriceStatus === ValidationStatus.VALIDATED))))
    );
  };

  static hasNonRefusedDelegation = (
    registration: CandidateRegistrationDtoClient
  ) => {
    return (
      registration.delegation &&
      registration.delegation.status !== DelegationStatus.REFUSED
    );
  };

  static paidDebitNote = (registration: CandidateRegistrationDtoClient) => {
    return RegistrationUtil.hasNonRefusedDelegation(registration)
      ? registration.debitCompanyPaidFile
      : registration.debitPersonalPaidFile;
  };

  static unpaidDebitNote = (registration: CandidateRegistrationDtoClient) => {
    return RegistrationUtil.hasNonRefusedDelegation(registration)
      ? registration.debitCompanyUnpaidFile
      : registration.debitPersonalUnpaidFile;
  };
}
