import { removeNullsDeep } from "@/api/app";
import { useQueryCertificateAll } from "@/api/certificate/certificate";
import { CertificateStatus } from "@/api/enums/CertificateStatus";
import { ExtensionType } from "@/api/enums/ExtensionType";
import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { RegistrationType } from "@/api/enums/RegistrationType";
import { RenewingType } from "@/api/enums/RenewingType";
import ExamDateCombobox from "@/components/molecules/ExamDateCombobox";
import { Perimeter } from "@/lib/perimeter";
import { Form } from "@atoms/Form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  MerchandisesForm,
  PerimeterForm,
  TransportModesForm,
} from "@molecules/PerimeterForm/PerimeterForm";
import _ from "lodash";
import { Info } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import NextStepButton from "../NextStepButtons";
import { useRegisterContext } from "../RegisterContext";
import CertificatesSwitches from "./CertificatesSwitches";
import ExtensionTypeButtons from "./ExtensionTypeButtons";
import { RegisterStepSchema, registerStepSchema } from "./RegisterStepSchema";
import RegistrationTypeRadio from "./RegistrationTypeRadio";
import RenewingTypeRadio from "./RenewingTypeRadio";
import UTCCombobox from "./UTCCombobox";

const WarningMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <div className="flex flex-col">
        <span className="font-bold">Attention</span>
        <span className="text-gray-600 font-normal">
          Votre choix de mode(s) de transport et de choix de la ou les classe(s)
          de marchandises dangereuses correspond à votre périmètre d'examen et
          de certificat. Nous vous invitons donc à bien vérifier l'adéquation de
          votre périmètre avec celui de votre ou vos formation(s), ainsi que
          celui de votre activité professionnelle.
        </span>
        <span className="text-brand-700 font-semibold">
          Si la date de clôture des inscriptions est dépassée, un changement ne
          sera plus possible.
        </span>
      </div>
    </div>
  );
};

const ExtensionMessage = () => {
  return (
    <div className="flex flex-row p-4 items-start self-stretch gap-4 border rounded-md border-gray-300 bg-white shadow">
      <Info className="w-5 h-5 flex-shrink-0" />
      <span className="font-bold">
        Vous êtes titulaire d'un certificat en cours de validité et souhaitez
        son extension avec l'ajout d'un ou deux mode(s) de transport{" "}
        <span className="text-brand-700">ou </span> d'une ou plusieurs classe(s)
        de danger. Veuillez dans ce cas sélectionner le certificat associé.
      </span>
    </div>
  );
};

const RegisterStep = () => {
  const { changeDraft, onNextStep, registrationDraft, pending } =
    useRegisterContext();

  ////////////
  // api calls
  ////////////

  const certificatesQuery = useQueryCertificateAll();

  ////////////
  // form
  ////////////

  const form = useForm<RegisterStepSchema>({
    resolver: zodResolver(registerStepSchema),
    defaultValues: _.merge(
      {
        type: RegistrationType.INITIAL,
        initialExamId: -1,
        renewingExamId: -1,
        extensionExamId: -1,
        initialProperties: {
          utc: 1,
          perimeter: Perimeter.empty(),
        },
        renewingProperties: {
          type: RegistrationType.RENEW,
          perimeter: Perimeter.empty(),
          renewingCertificatesIDs: [],
          renewingType: RenewingType.SAME,
        },
        extensionProperties: {
          type: RegistrationType.EXTENSION,
          utc: 1,
          certificateId: [],
          extension: {
            extensionType: ExtensionType.MERCHANDISES,
            extendedMerchandiseClasses: [],
            extendedTransportModes: [],
          },
        },
      },
      removeNullsDeep({
        type: registrationDraft.type,
        initialExamId:
          registrationDraft.type === RegistrationType.INITIAL
            ? registrationDraft.examId
            : -1,
        renewingExamId:
          registrationDraft.type === RegistrationType.RENEW
            ? registrationDraft.examId
            : -1,
        extensionExamId:
          registrationDraft.type === RegistrationType.EXTENSION
            ? registrationDraft.examId
            : -1,
        initialProperties: registrationDraft.initialProperties,
        renewingProperties: registrationDraft.renewingProperties,
        extensionProperties: {
          utc: registrationDraft.extensionProperties?.utc,
          certificateId: [registrationDraft.extensionProperties?.certificateId],
          extension: {
            extensionType: registrationDraft.extensionProperties?.extensionType,
            extendedMerchandiseClasses:
              registrationDraft.extensionProperties?.extensionPerimeter
                .merchandises,
            extendedTransportModes:
              registrationDraft.extensionProperties?.extensionPerimeter
                .transportModes,
          },
        },
      })
    ),
  });

  const onSubmit = (data: RegisterStepSchema) => {
    changeDraft({
      ...(data.type === RegistrationType.INITIAL && {
        examId: data.initialExamId,
        initialProperties: data.initialProperties,
      }),
      ...(data.type === RegistrationType.EXTENSION && {
        examId: data.extensionExamId,
        extensionProperties: {
          extensionPerimeter: Perimeter.empty(),
          utc: data.extensionProperties.utc,
          certificateId: data.extensionProperties.certificateId[0],
          ...data.extensionProperties.extension,
        },
      }),
      ...(data.type === RegistrationType.RENEW && {
        examId: data.renewingExamId,
        renewingProperties: data.renewingProperties,
      }),
      type: data.type,
    });
  };

  const { setValue, watch } = form;
  const registrationType = watch("type");

  const validCertificates = useMemo(
    () =>
      certificatesQuery.data?.filter(
        ({ expirationDate, status }) =>
          new Date(expirationDate) >= new Date() &&
          (status as CertificateStatus) === CertificateStatus.VALIDATED
      ) ?? [],
    [certificatesQuery.data]
  );

  /// renewal update mechanism

  const renewingType = watch("renewingProperties.renewingType");
  const renewingCertificatesIDs = watch(
    "renewingProperties.renewingCertificatesIDs"
  );
  const [renewalAllowedPerimeter, setRenewalAllowedPerimeter] =
    useState<Perimeter>(Perimeter.empty());

  // merging perimeters is allowed only if the difference is
  // in merchandises or transport modes, not both
  const renewalAllowedCertificates = useMemo(() => {
    if (
      renewingCertificatesIDs.length === 0 ||
      renewingType !== RenewingType.FUSION
    )
      return validCertificates;

    // if there is only one certificate, it is allowed to merge
    // with any other certificate that doesn't differ in both
    // merchandises and transport modes

    if (renewingCertificatesIDs.length === 1) {
      const p = validCertificates.find(
        ({ id }) => id === renewingCertificatesIDs[0]
      );

      if (!p) throw new Error("no certificate found");

      return validCertificates.filter(({ perimeter }) => {
        const difference = Perimeter.difference(
          Perimeter.from(p.perimeter),
          Perimeter.from(perimeter)
        );

        return (
          difference.merchandises.length === 0 ||
          difference.transportModes.length === 0
        );
      });
    }

    // if there are multiple certificates, they must all share
    // the same merchandises or the same transport modes

    const p0 = validCertificates.find(
      ({ id }) => id === renewingCertificatesIDs[0]
    );
    const p1 = validCertificates.find(
      ({ id }) => id === renewingCertificatesIDs[1]
    );

    if (!p0 || !p1) throw new Error("no certificate found");

    const difference = Perimeter.difference(
      Perimeter.from(p0.perimeter),
      Perimeter.from(p1.perimeter)
    );

    return validCertificates.filter(({ perimeter }) => {
      const d = Perimeter.difference(
        Perimeter.from(p0.perimeter),
        Perimeter.from(perimeter)
      );

      if (d.merchandises.length !== 0 && d.transportModes.length !== 0)
        return false;
      if (d.merchandises.length !== 0 && difference.merchandises.length === 0)
        return false;
      if (
        d.transportModes.length !== 0 &&
        difference.transportModes.length === 0
      )
        return false;

      return true;
    });
  }, [validCertificates, renewingType, renewingCertificatesIDs]);

  // auto-select allowed fields
  useEffect(() => {
    const newAllowedPerimeter = Perimeter.union(
      ...(certificatesQuery.data
        ?.filter(({ id }) => renewingCertificatesIDs.includes(id as number))
        .map(({ perimeter }) => Perimeter.from(perimeter)) || [])
    );

    if ([RenewingType.SAME, RenewingType.FUSION].includes(renewingType)) {
      setValue("renewingProperties.perimeter", newAllowedPerimeter);
    } else {
      const restrictedPerimeter = Perimeter.from(
        watch("renewingProperties.perimeter")
      ).restrictByOther(newAllowedPerimeter);
      setValue("renewingProperties.perimeter", restrictedPerimeter);
    }

    setRenewalAllowedPerimeter(newAllowedPerimeter);
  }, [
    renewingType,
    renewingCertificatesIDs,
    setValue,
    watch,
    certificatesQuery.data,
  ]);

  // avoid multiple selections if forbidden
  useEffect(() => {
    if ([RenewingType.SAME, RenewingType.REDUCED].includes(renewingType)) {
      setValue(
        "renewingProperties.renewingCertificatesIDs",
        watch("renewingProperties.renewingCertificatesIDs").slice(0, 1)
      );
    }
  }, [renewingType, setValue, watch]);

  /// extension update mechanism

  const extensionType = watch("extensionProperties.extension.extensionType");
  const extensionCertificateID = watch("extensionProperties.certificateId");
  const [extensionMinimumPerimeter, setExtensionMinimumPerimeter] =
    useState<Perimeter>(Perimeter.full());

  useEffect(() => {
    const newMinimumPerimeter =
      certificatesQuery.data?.find(({ id }) =>
        extensionCertificateID.includes(id as number)
      )?.perimeter || Perimeter.full();

    if (extensionType === ExtensionType.MERCHANDISES)
      setValue(
        "extensionProperties.extension.extendedMerchandiseClasses",
        watch(
          "extensionProperties.extension.extendedMerchandiseClasses"
        ).filter((val) => !newMinimumPerimeter.merchandises.includes(val))
      );
    else
      setValue(
        "extensionProperties.extension.extendedTransportModes",
        watch("extensionProperties.extension.extendedTransportModes").filter(
          (val) => !newMinimumPerimeter.transportModes.includes(val)
        )
      );

    setExtensionMinimumPerimeter(Perimeter.from(newMinimumPerimeter));
  }, [
    extensionType,
    extensionCertificateID,
    setValue,
    watch,
    certificatesQuery.data,
  ]);

  return (
    <div className="flex flex-col items-start w-full max-w-4xl">
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((data) => onSubmit(data))}
          className="flex w-full flex-col space-y-8"
        >
          <div className="flex p-4 flex-col items-start gap-8 self-stretch rounded-md border-gray-200 bg-white border w-full">
            <h1>Inscription à l'examen</h1>
            <RegistrationTypeRadio />
            {registrationType === RegistrationType.INITIAL && (
              <>
                <ExamDateCombobox
                  fieldName="initialExamId"
                  registrationType={RegistrationType.INITIAL}
                />
                <UTCCombobox fieldName="initialProperties.utc" />
                <PerimeterForm
                  fieldName="initialProperties.perimeter"
                  // hydrocarbons not proposed anymore in initial
                  hiddenPerimeter={
                    new Perimeter([], [MerchandiseClass.HYDROCARBONS])
                  }
                />
              </>
            )}
            {registrationType === RegistrationType.RENEW && (
              <>
                <ExamDateCombobox
                  fieldName="renewingExamId"
                  registrationType={RegistrationType.RENEW}
                />
                <RenewingTypeRadio />
                {certificatesQuery.isLoading ? (
                  <div>Chargement des certificats...</div>
                ) : certificatesQuery.isError ? (
                  <div>Erreur lors du chargement des certificats.</div>
                ) : (
                  <>
                    <CertificatesSwitches
                      certificates={renewalAllowedCertificates}
                      unique={[
                        RenewingType.SAME,
                        RenewingType.REDUCED,
                      ].includes(renewingType)}
                      fieldName="renewingProperties.renewingCertificatesIDs"
                      titleElement={<h2>Vos certificat(s) à renouveler</h2>}
                    />
                    <PerimeterForm
                      fieldName="renewingProperties.perimeter"
                      disabledPerimeter={
                        [RenewingType.SAME, RenewingType.FUSION].includes(
                          renewingType
                        )
                          ? Perimeter.full()
                          : Perimeter.empty()
                      }
                      displayedPerimeter={renewalAllowedPerimeter}
                      uncheckedAreRed={renewingType === RenewingType.REDUCED}
                    />
                  </>
                )}
              </>
            )}
            {registrationType === RegistrationType.EXTENSION && (
              <>
                <ExamDateCombobox
                  fieldName="extensionExamId"
                  registrationType={RegistrationType.EXTENSION}
                />
                <UTCCombobox fieldName="extensionProperties.utc" />
                <CertificatesSwitches
                  certificates={validCertificates}
                  unique={true}
                  fieldName="extensionProperties.certificateId"
                  titleElement={
                    <>
                      <h2>Extension de certificat</h2>
                      <ExtensionMessage />
                    </>
                  }
                />
                <ExtensionTypeButtons />
                {extensionType === ExtensionType.MERCHANDISES && (
                  <MerchandisesForm
                    fieldName="extensionProperties.extension.extendedMerchandiseClasses"
                    hiddenMerchandises={[
                      ...extensionMinimumPerimeter.merchandises,
                      MerchandiseClass.HYDROCARBONS,
                    ]}
                  />
                )}
                {extensionType === ExtensionType.TRANSPORTS && (
                  <TransportModesForm
                    fieldName="extensionProperties.extension.extendedTransportModes"
                    hiddenTransportModes={
                      extensionMinimumPerimeter.transportModes
                    }
                  />
                )}
              </>
            )}
            <WarningMessage />
          </div>
          <NextStepButton
            onNext={form.handleSubmit((data) => onSubmit(data))}
            onPrevious={() => onNextStep(false)}
            pending={pending}
          />
        </form>
      </Form>
    </div>
  );
};

export default RegisterStep;
