import { ExamLiveDtoClient } from "@/api/live-exam/dto/exam-live.dto";

interface InfoCardsProps {
  exam: ExamLiveDtoClient;
}

const InfoCards = ({
  exam: {
    connectedCandidatesCount,
    registeredCount,
    successfulCandidatesCount,
  },
}: InfoCardsProps) => {
  return (
    <div className="flex flex-row gap-4">
      {[
        {
          text: "Candidats Absents",
          value: registeredCount - connectedCandidatesCount,
        },
        {
          text: "Taux de réussite",
          value: `${
            registeredCount
              ? ((successfulCandidatesCount / registeredCount) * 100).toFixed(1)
              : "-"
          }%`,
        },
        {
          text: "Candidats admis",
          value: successfulCandidatesCount,
        },
      ].map(({ text, value }) => (
        <div
          className="flex flex-row items-center justify-between border border-gray-200 rounded-lg px-6 py-4 w-full"
          key={text}
        >
          <span className="text-gray-600">{text}</span>
          <span className="text-gray-950 font-semibold text-xl">{value}</span>
        </div>
      ))}
    </div>
  );
};

export default InfoCards;
