import { mandatoryString, optionalString } from "@/constants/zodTypes";
import { z } from "zod";

const optionalAddressSchema = z.object({
  country: optionalString(),
  personalAddress: optionalString(),
  complement: optionalString(),
  postalCode: optionalString(),
  city: optionalString(),
});

const mandatoryAddressSchema = z.object({
  country: mandatoryString(),
  personalAddress: mandatoryString(),
  complement: optionalString(),
  postalCode: mandatoryString(),
  city: mandatoryString(),
});

const addressDefaultValues: z.infer<typeof optionalAddressSchema> = {
  country: "",
  personalAddress: "",
  complement: "",
  postalCode: "",
  city: "",
};

type OptionalAddressSchema = z.infer<typeof optionalAddressSchema>;
type MandatoryAddressSchema = z.infer<typeof mandatoryAddressSchema>;

export { addressDefaultValues, mandatoryAddressSchema, optionalAddressSchema };
export type { MandatoryAddressSchema, OptionalAddressSchema };
