import { useMutationAuthExamLogin } from "@/api/auth/auth";
import { Role } from "@/api/enums/Role";
import { ConnectionSchema } from "@/components/templates/ConnectPage/ConnectionForm";
import ConnectionPage from "@/components/templates/ConnectPage/ConnectPage";
import { ROUTE } from "@/constants/routes";
import { socket } from "@/hooks/socket";
import { useWebSocketData } from "@/hooks/websocket-data";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const Login = () => {
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const { connected } = useWebSocketData();
  const [webSocketPending, setWebSocketPending] = useState(false);

  const loginMutation = useMutationAuthExamLogin({
    onSuccess: async () => {
      setErrorMsg("");
      toast.success("Connexion réussie.");
      socket.io.opts.query = { role: Role.CANDIDATE };
      socket.connect();
      setWebSocketPending(true);
    },
    onError: (error: any) =>
      setErrorMsg(
        error.response?.data?.message ??
          "Une erreur est survenue lors de la connexion."
      ),
  });

  const onSubmit = ({ email, key }: ConnectionSchema) =>
    loginMutation.mutate({
      username: email,
      key,
    });

  // redirect user on websocket connexion
  useEffect(() => {
    if (!connected) return;

    setWebSocketPending(false);
    navigate(ROUTE.exam.questions());
  }, [connected, navigate]);

  return (
    <ConnectionPage
      title="Se connecter à un examen"
      onSubmit={onSubmit}
      pending={loginMutation.isPending || webSocketPending}
      errorMsg={errorMsg}
    />
  );
};

export default Login;
