import { useFormationInstituteCreateMutation } from "@/api/formation-institute/formation-institute";
import { keyFactory } from "@/api/keyFactory";
import { Button } from "@/components/atoms/Button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/atoms/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@/components/atoms/Form";
import { Input } from "@/components/atoms/Input";
import { mandatoryString } from "@/constants/zodTypes";
import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  description: mandatoryString(),
});

type Schema = z.infer<typeof schema>;

interface AddInstituteDialogProps {
  isOpen: boolean;
  close: () => void;
}

const AddInstituteDialog = ({ close, isOpen }: AddInstituteDialogProps) => {
  const queryClient = useQueryClient();
  const createMutation = useFormationInstituteCreateMutation();

  const form = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      description: "",
    },
  });

  const onSubmit = (data: Schema) => {
    createMutation.mutate(data, {
      onSuccess: () => {
        toast.success("Institut de formation créé");
        queryClient.invalidateQueries({
          queryKey: keyFactory.formationInstitutes.base(),
        });
        close();
      },
      onError: () => {
        toast.error("Échec de la requête", {
          description: "L'institut de formation n'a pas pu être créé",
        });
      },
    });
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent
        onClose={close}
        className="max-w-xl flex flex-col gap-4 overflow-auto max-h-[85%]"
      >
        <DialogHeader>
          <DialogTitle>Ajout d'un institut de formation</DialogTitle>
          <DialogDescription hidden={true}>
            Ajout d'un institut de formation
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form
            onSubmit={form.handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <FormField
              control={form.control}
              name="description"
              render={({ field }) => (
                <FormItem className="w-full">
                  <FormControl>
                    <Input {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button type="submit" disabled={createMutation.isPending}>
              {createMutation.isPending ? "Création..." : "Valider"}
            </Button>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AddInstituteDialog;
