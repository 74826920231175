import { CandidateRegistrationDtoClient } from "@/api/candidate/dto/candidate-registration.dto";
import { Button } from "@/components/atoms/Button";
import RegistrationTypeBadge from "@/components/molecules/Badges/RegistrationTypeBadge";
import GrayCard from "@/components/molecules/RegistrationCard/GrayCard";
import { Calendar, User } from "lucide-react";
import { useState } from "react";
import AttestationCard from "./Cards/AttestationsCard/AttestationsCard";
import ConvocationCard from "./Cards/ConvocationCard";
import DelegationCard from "./Cards/DelegationCard";
import PayementCard from "./Cards/PayementCard";
import PreTestCard from "./Cards/PreTestCard";
import ReducedPriceCard from "./Cards/ReducedPriceCard";
import DetailsDialog from "./DetailsDialog/DetailsDialog";
import TopMessage from "./TopMessage";

interface RegistrationCardProps {
  registration: CandidateRegistrationDtoClient;
}

const RegistrationCard = ({ registration }: RegistrationCardProps) => {
  const [detailsOpen, setDetailsOpen] = useState(false);

  return (
    <>
      <DetailsDialog
        isOpen={detailsOpen}
        close={() => setDetailsOpen(false)}
        registration={registration}
      />
      <div className="flex flex-col gap-4 p-4 bg-white border border-gray-200 rounded-lg w-full">
        <div className="flex flex-row justify-between gap-6">
          <div className="flex flex-row w-full gap-6">
            <GrayCard>
              <User className="w-4 h-4 text-gray-500" />
              N° inscription : {registration.registrationNumber ?? "Non généré"}
              <RegistrationTypeBadge registrationType={registration.type} />
            </GrayCard>
            <GrayCard>
              <Calendar className="w-4 h-4 text-gray-500" />
              Date de l'examen :{" "}
              {new Date(registration.exam.date).toLocaleDateString()}
            </GrayCard>
            <GrayCard>
              <Calendar className="w-4 h-4 text-gray-500" />
              Date clôture des inscriptions :{" "}
              {new Date(
                registration.exam.registeringEndDate
              ).toLocaleDateString()}
            </GrayCard>
          </div>
          <Button onClick={() => setDetailsOpen(true)}>
            Détails de mon inscription
          </Button>
        </div>
        <TopMessage registration={registration} />
        <div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-5">
            <PayementCard registration={registration} />
            <ReducedPriceCard registration={registration} />
            <PreTestCard registration={registration} />
            <AttestationCard registration={registration} />
            <ConvocationCard registration={registration} />
            <DelegationCard registration={registration} />
          </div>
        </div>
      </div>
    </>
  );
};

export default RegistrationCard;
