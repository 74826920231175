import { AdminRegistrationDtoClient } from "@/api/registration-dto/dto/admin-registration.dto";
import { useQueryRegistrationAllPaginated } from "@/api/registration/registration";
import { Badge } from "@/components/atoms/Badge";
import SmartPagination from "@/components/molecules/SmartPagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/molecules/Table";
import { ROUTE } from "@/constants/routes";
import useTablePlaceholderRows from "@/hooks/table-placeholder-rows";
import usePersistent from "@/hooks/use-persistent";
import { Perimeter } from "@/lib/perimeter";
import { cn } from "@/lib/utils";
import { keepPreviousData } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ArrowDown } from "lucide-react";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ConvocationBadge from "./ConvocationBadge";
import FormationAttestationsBadge from "./FormationAttestationBadge";
import PreTestStatusBadge from "./PreTestStatusStatusBadge";
import RegistrationStatusBadge from "./RegistrationStatusBadge";

interface RegistrationsTableProps {
  openExamFilter?: () => void;
  searchText?: string;
  examIds?: number[];
  complete?: boolean;
}

const columnHelper = createColumnHelper<AdminRegistrationDtoClient>();

const RegistrationsTable = ({
  examIds,
  searchText,
  complete,
  openExamFilter,
}: RegistrationsTableProps) => {
  const [pageIndex, setPageIndex] = usePersistent(
    "admin-dashboard-registrations-pageIndex",
    0
  );
  const navigate = useNavigate();

  const registrationsQuery = useQueryRegistrationAllPaginated(
    {
      pageIndex,
      searchText,
      examIds,
      complete,
    },
    {
      placeholderData: keepPreviousData,
    }
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("exam", {
        id: "exam",
        header: () => (
          <div className="flex flex-row items-center gap-2">
            <span>Session</span>
            {openExamFilter && (
              <button onClick={() => openExamFilter()}>
                <ArrowDown
                  className={cn("w-4 h-4", {
                    "text-brand-700": examIds && examIds.length > 0,
                  })}
                />
              </button>
            )}
          </div>
        ),
        cell: (info) => {
          const { date, number } = info.getValue();

          return (
            <div className="flex flex-col">
              <span className="text-gray-900">{number}</span>
              <span className="text-gray-500">
                {new Date(date).toLocaleDateString("fr-FR")}
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor(
        ({ candidate: { firstName, lastName }, currentDelegation }) => ({
          name: `${firstName} ${lastName}`,
          currentDelegation,
        }),
        {
          id: "candidate",
          header: "Candidat",
          cell: (info) => {
            const { name, currentDelegation } = info.getValue();

            return (
              <div className="flex flex-col h-9 justify-center">
                <span className="text-gray-900 font-medium  whitespace-nowrap">
                  {name}
                </span>
                {currentDelegation && (
                  <span className="text-gray-500 font-normal whitespace-nowrap">
                    {currentDelegation.company.name}
                  </span>
                )}
              </div>
            );
          },
        }
      ),
      columnHelper.accessor("registrationNumber", {
        id: "registrationNumber",
        header: "N° insc.",
        cell: (info) => (
          <span className="text-gray-900">{info.getValue()}</span>
        ),
      }),
      columnHelper.accessor("perimeter", {
        id: "perimeter",
        header: "Pér. examen",
        cell: (info) => (
          <Badge variant="white">{Perimeter.toCode(info.getValue())}</Badge>
        ),
      }),
      columnHelper.accessor((x) => x, {
        id: "status",
        header: "Statut inscr.",
        cell: (info) => (
          <RegistrationStatusBadge registration={info.getValue()} />
        ),
      }),
      columnHelper.accessor("preTestStatus", {
        id: "preTestStatus",
        header: "Test Préalable.",
        cell: (info) => <PreTestStatusBadge status={info.getValue()} />,
      }),
      columnHelper.accessor("formationCertificatesStatus", {
        id: "formationAttestationsStatus",
        header: "Attest. de form.",
        cell: (info) => <FormationAttestationsBadge status={info.getValue()} />,
      }),
      columnHelper.accessor("candidate", {
        id: "currentPerimeter",
        header: "Pér. actuel",
        cell: (info) => {
          const { currentPerimeter } = info.getValue();
          if (!currentPerimeter) return <></>;

          return (
            <Badge variant="white">{Perimeter.toCode(currentPerimeter)}</Badge>
          );
        },
      }),
      columnHelper.accessor("extensionPerimeter", {
        id: "extendedPerimeter",
        header: "Ext.",
        cell: (info) => {
          const perimeter = info.getValue();
          if (!perimeter) return <></>;

          return <Badge variant="white">{Perimeter.toCode(perimeter)}</Badge>;
        },
      }),
      columnHelper.accessor("exam.registeringEndDate", {
        id: "exam.registeringEndDate",
        header: "Clôt. insc.",
        cell: (info) => (
          <span className="text-gray-500">
            {new Date(info.getValue()).toLocaleDateString("fr-FR")}
          </span>
        ),
      }),
      columnHelper.accessor("exam.formationCertificateEndDate", {
        id: "exam.formationCertificateEndDate",
        header: "Clôt. attes. form.",
        cell: (info) => (
          <span className="text-gray-500">
            {new Date(info.getValue()).toLocaleDateString("fr-FR")}
          </span>
        ),
      }),
      columnHelper.accessor("convocation", {
        id: "convocation",
        header: "Convocation",
        cell: (info) => (
          <ConvocationBadge sentDate={info.getValue()?.createdAt} />
        ),
      }),
    ],
    [examIds, openExamFilter]
  );

  const [rows, is_placeholder] = useTablePlaceholderRows(
    registrationsQuery.data
  );

  const table = useReactTable({
    data: rows,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex flex-col w-full gap-5">
      <div className="rounded-lg overflow-auto border border-gray-200 w-full">
        <Table className="text-gray-600 font-medium text-sm leading-6 rounded-lg">
          <TableHeader className="items-center bg-gray-50">
            <TableRow>
              {table.getHeaderGroups()[0].headers.map((header) => (
                <TableHead
                  key={header.id}
                  style={{ width: `${header.getSize()}px` }}
                >
                  <div className="flex flex-row items-center gap-3 text-xs">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </div>
                </TableHead>
              ))}
            </TableRow>
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows.map((row, i) => (
              <TableRow
                key={row.id}
                className={cn({
                  "bg-gray-50": i % 2,
                  "opacity-0 border-none": is_placeholder(i),
                  "cursor-pointer": !is_placeholder(i),
                })}
                onClick={
                  is_placeholder(i)
                    ? undefined
                    : () =>
                        navigate(
                          ROUTE.admin.dashboard.candidate.onGoingSession(
                            row.original.candidate.id
                          ),
                          {
                            state: { from: location.pathname },
                          }
                        )
                }
              >
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id} className="h-14 py-0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
      <SmartPagination
        currentPage={pageIndex}
        onPageChange={(newPageIndex) => setPageIndex(newPageIndex)}
        totalPages={registrationsQuery.data?.totalPages ?? 0}
      />
    </div>
  );
};

export default RegistrationsTable;
