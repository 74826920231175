import { ExamType } from "@/api/enums/ExamType";
import { Input } from "@/components/atoms/Input";
import { Tabs, TabsList, TabsTrigger } from "@/components/atoms/Tabs";
import BlueNavLink from "@/components/molecules/BlueNavLink";
import { ROUTE } from "@/constants/routes";
import usePersistent from "@/hooks/use-persistent";
import { Plus } from "lucide-react";
import { useState } from "react";
import { useDebounce } from "use-debounce";
import { SessionsTab } from "./sessions-tab.enum";
import SessionsTable from "./SessionsTable/SessionsTable";

const Sessions = () => {
  const [searchText, setSearchText] = useState("");
  const [debouncedSearchText] = useDebounce(searchText, 300);
  const [tab, setTab] = usePersistent<SessionsTab>(
    "sessions-tab",
    SessionsTab.All
  );
  const [sessionTypeTab, setSessionTypeTab] = usePersistent<ExamType | "all">(
    "sessions-type-tab",
    "all"
  );

  return (
    <div className="flex flex-col bg-white h-screen">
      <div className="flex flex-col border-b border-b-gray-200 px-6 pt-3 gap-2 bg-white relative">
        <BlueNavLink
          className="absolute top-2 right-6"
          to={ROUTE.admin.dashboard.sessionCreation()}
        >
          <Plus />
          Créer une session
        </BlueNavLink>
        <h1>Sessions</h1>
        <Tabs onValueChange={(e) => setTab(e as SessionsTab)} value={tab}>
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={SessionsTab.All}>
              Toutes
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={SessionsTab.Open}>
              Ouvertes
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={SessionsTab.Closed}>
              Clôturées
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={SessionsTab.Corrected}>
              Corrigées
            </TabsTrigger>
            <TabsTrigger
              variant="underlineBlue"
              value={SessionsTab.NotesGenerated}
            >
              Relevés de notes générés
            </TabsTrigger>
            <TabsTrigger
              variant="underlineBlue"
              value={SessionsTab.CertificatesGenerated}
            >
              Certificats générés
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <Tabs
          onValueChange={(e) =>
            e === "all"
              ? setSessionTypeTab("all")
              : setSessionTypeTab(e as ExamType)
          }
          value={sessionTypeTab}
          className="border-b border-b-gray-100"
        >
          <TabsList variant="transparent" className="pb-0 mb-0">
            <TabsTrigger variant="underlineBlue" value={"all"}>
              Toutes
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={ExamType.RENEW}>
              Renouvellement
            </TabsTrigger>
            <TabsTrigger variant="underlineBlue" value={ExamType.INITIAL}>
              Initial
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <Input
          className="shadow-none"
          id="shadcn-input"
          searchIcon
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value as string)}
          placeholder="Rechercher"
        />
        <SessionsTable
          tab={tab}
          sessionTypeTab={sessionTypeTab}
          searchText={debouncedSearchText}
        />
      </div>
    </div>
  );
};

export default Sessions;
