export enum PersonalInfoField {
  Sex = "personalInfo.sex",
  LastName = "personalInfo.lastName",
  FirstName = "personalInfo.firstName",
  Birthdate = "personalInfo.birthdate",
  BirthCity = "personalInfo.birthCity",
  BirthCountry = "personalInfo.birthCountry",
  Nationality = "personalInfo.nationality",
  HomePhone = "personalInfo.homePhone",
  MobilePhone = "personalInfo.mobilePhone",
  Email = "credentials.email",
  EmailConfirmation = "credentials.emailConfirmation",
  Password = "credentials.key",
  PasswordConfirmation = "credentials.keyConfirmation",
}

export interface Modifiers {
  disabledFields: PersonalInfoField[];
  hiddenFields: PersonalInfoField[];
  unmodifiableToolTipFields: PersonalInfoField[];
}
