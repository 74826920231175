import { ENDPOINT } from "@/constants/endpoints";
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosResponse } from "axios";
import { PaginationResponseDto } from "../dto/pagination.dto";
import { keyFactory } from "../keyFactory";
import { CandidateInfoDtoClient } from "./dto/candidate-info.dto";
import { CandidateRegistrationDtoClient } from "./dto/candidate-registration.dto";
import {
  CandidateBasicDtoClient,
  CandidateBasicPaginationRequestDto,
} from "./dto/candidate.dto";
import { ChangeCandidateInfoDto } from "./dto/change-candidate-info.dto";

// info

const getInfo = async (candidateId?: number) =>
  axios
    .get<CandidateInfoDtoClient>(ENDPOINT.candidate.getInfo(candidateId))
    .then((res) => res.data);

export const useQueryCandidateInfo = (
  candidateId?: number,
  props?: Partial<UseQueryOptions<CandidateInfoDtoClient, AxiosError>>
) =>
  useQuery({
    queryKey: keyFactory.candidate.info(),
    queryFn: () => getInfo(candidateId),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });

const changeInfo = async (data: ChangeCandidateInfoDto, candidateId?: number) =>
  await axios.post(ENDPOINT.candidate.postChangeInfo(candidateId), data);

export const useMutationCandidateChangeInfo = (
  candidateId?: number,
  props?: UseMutationOptions<AxiosResponse, AxiosError, ChangeCandidateInfoDto>
) =>
  useMutation({
    mutationFn: (data: ChangeCandidateInfoDto) => changeInfo(data, candidateId),
    ...props,
  });

// registrations

const getRegistrations = async (candidateId?: number) =>
  axios
    .get<CandidateRegistrationDtoClient[]>(
      ENDPOINT.candidate.getRegistrations(candidateId)
    )
    .then((res) => res.data);

export const useQueryCandidateRegistrations = (
  candidateId?: number,
  props?: UseQueryOptions<CandidateRegistrationDtoClient[], AxiosError>
) =>
  useQuery({
    queryKey: keyFactory.candidate.registrations(candidateId),
    queryFn: () => getRegistrations(candidateId),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });

// can register

const canRegister = async () =>
  axios
    .get<boolean>(ENDPOINT.candidate.getCanRegister())
    .then((res) => res.data);

export const useQueryCandidateCanRegister = () =>
  useQuery({
    queryKey: keyFactory.candidate.canRegister(),
    queryFn: canRegister,
    staleTime: Infinity,
    gcTime: Infinity,
  });

// all paginated

const getAllPaginated = async (params: CandidateBasicPaginationRequestDto) =>
  axios
    .get<PaginationResponseDto<CandidateBasicDtoClient>>(
      ENDPOINT.candidate.getAllPaginated(),
      {
        params,
      }
    )
    .then((res) => res.data);

export const useQueryCandidateAllPaginated = (
  params: CandidateBasicPaginationRequestDto,
  props?: Partial<
    UseQueryOptions<PaginationResponseDto<CandidateBasicDtoClient>>
  >
) => {
  return useQuery<PaginationResponseDto<CandidateBasicDtoClient>>({
    queryKey: keyFactory.candidate.allPaginated(params),
    queryFn: () => getAllPaginated(params),
    staleTime: 1000 * 60 * 5,
    gcTime: 1000 * 60 * 5,
    ...props,
  });
};

// basic

const getBasicInfo = async (candidateId: number) =>
  axios
    .get<CandidateBasicDtoClient | "id-not-found">(
      ENDPOINT.candidate.getBasicInfo(candidateId)
    )
    .then((res) => res.data);

export const useQueryCandidateBasicInfo = (
  candidateId: number,
  props?: Partial<
    UseQueryOptions<CandidateBasicDtoClient | "id-not-found", AxiosError>
  >
) => {
  return useQuery({
    queryKey: keyFactory.candidate.basicInfo(candidateId),
    queryFn: () => getBasicInfo(candidateId),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });
};

// delete

const deleteCandidate = async (candidateId: number) =>
  await axios.post(ENDPOINT.candidate.postDelete(candidateId));

export const useMutationCandidateDelete = (
  props?: UseMutationOptions<AxiosResponse, AxiosError, number>
) =>
  useMutation({
    mutationFn: deleteCandidate,
    ...props,
  });

// on-going registration

const getOnGoingRegistration = async (candidateId: number) =>
  axios
    .get<CandidateRegistrationDtoClient | "no-registration">(
      ENDPOINT.candidate.getOnGoingRegistration(candidateId)
    )
    .then((res) => res.data);

export const useQueryCandidateOnGoingRegistration = (
  candidateId: number,
  props?: Partial<
    UseQueryOptions<
      CandidateRegistrationDtoClient | "no-registration",
      AxiosError
    >
  >
) =>
  useQuery({
    queryKey: keyFactory.candidate.onGoingRegistration(candidateId),
    queryFn: () => getOnGoingRegistration(candidateId),
    staleTime: Infinity,
    gcTime: Infinity,
    ...props,
  });
