import LiveSessionsTable from "./LiveSessionsTable/LiveSessionsTable";

const LiveSessions = () => {
  return (
    <div className="flex flex-col w-full bg-white h-screen">
      <div className="flex w-full border-b border-b-gray-200 px-6 py-3 bg-white">
        <h1>Sessions temps réel</h1>
      </div>
      <div className="py-4 px-6 flex flex-col gap-3">
        <LiveSessionsTable />
      </div>
    </div>
  );
};

export default LiveSessions;
