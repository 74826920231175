import { ExamType } from "@/api/enums/ExamType";
import { useQueryRegistrationExamPerimeters } from "@/api/registration/registration";
import { Badge } from "@/components/atoms/Badge";
import { Button } from "@/components/atoms/Button";
import { Input } from "@/components/atoms/Input";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/atoms/Sheet";
import { LoadingSpinner } from "@/components/molecules/LoadingSpinner";
import { Perimeter } from "@/lib/perimeter";
import { useState } from "react";

const ExamPerimetersSheet = () => {
  const [searchText, setSearchText] = useState("");

  const query = useQueryRegistrationExamPerimeters();

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button>Visualiser les périmètres</Button>
      </SheetTrigger>
      <SheetContent className="min-w-[800px] flex flex-col gap-4 h-full">
        <SheetHeader>
          <SheetTitle>Visualisation des périmètres</SheetTitle>
          <SheetDescription hidden={true}>
            Visualisation des périmètres
          </SheetDescription>
        </SheetHeader>
        <div className="flex flex-col gap-3 items-center h-full">
          <Input
            className="shadow-none"
            id="shadcn-input"
            searchIcon
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value as string)}
            placeholder="Rechercher"
          />
          {!query.data ? (
            <LoadingSpinner
              isLoading={query.isLoading}
              isError={query.isError}
              loadingMessage="Chargement des examens..."
              errorMessage="Erreur lors du chargement des examens."
            />
          ) : (
            <div className="flex flex-col gap-4 justify-start w-full pt-2 overflow-auto h-[90%]">
              {query.data
                ?.filter(
                  ({ exam: { number } }) =>
                    searchText.length === 0 ||
                    number.toLowerCase().includes(searchText.toLowerCase())
                )
                .sort((exam0, exam1) => exam0.exam.id - exam1.exam.id)
                .map(({ exam, perimeters }) => (
                  <div className="flex flex-row gap-2 flex-wrap" key={exam.id}>
                    <Badge
                      variant={
                        exam.type === ExamType.INITIAL ? "purple" : "sky"
                      }
                    >
                      {exam.number} -{" "}
                      {perimeters.reduce((acc, curr) => acc + curr.count, 0)}{" "}
                      candidats
                    </Badge>
                    {perimeters.map(({ perimeter, count }, i) => (
                      <Badge variant="white" key={i}>
                        {Perimeter.toCode(perimeter)} x{count}
                      </Badge>
                    ))}
                  </div>
                ))}
            </div>
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default ExamPerimetersSheet;
