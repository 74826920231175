import { Sex } from "@/api/enums/Sex";
import { z } from "zod";
import {
  date,
  email,
  mandatoryString,
  nullDate,
  optionalPhone,
  optionalString,
  password,
} from "@/constants/zodTypes";

// credentials

const credentialsSchema = z
  .object({
    email: email(),
    emailConfirmation: email(),
    key: password(),
    keyConfirmation: z.string(),
  })
  .superRefine(({ key, keyConfirmation, email, emailConfirmation }, ctx) => {
    if (key != keyConfirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Les mots de passe ne correspondent pas",
        path: ["keyConfirmation"],
      });
    }

    if (email != emailConfirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Les adresses mail ne correspondent pas",
        path: ["emailConfirmation"],
      });
    }
  });

const credentialsDefaultValues: z.infer<typeof credentialsSchema> = {
  email: "",
  emailConfirmation: "",
  key: "",
  keyConfirmation: "",
};

// email

const emailSchema = z
  .object({
    email: email(),
    emailConfirmation: email(),
  })
  .superRefine(({ email, emailConfirmation }, ctx) => {
    if (email != emailConfirmation) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Les adresses mail ne correspondent pas",
        path: ["emailConfirmation"],
      });
    }
  });

const emailDefaultValues: z.infer<typeof emailSchema> = {
  email: "",
  emailConfirmation: "",
};

// personal information

const mandatoryStringUpperLower = () =>
  mandatoryString().transform(
    (str) => str[0].toUpperCase() + str.slice(1).toLowerCase()
  );

const personalInfoSchema = z.object({
  sex: z.nativeEnum(Sex),
  lastName: mandatoryStringUpperLower(),
  firstName: mandatoryStringUpperLower(),
  // minimum 18 years old, maximum 100 years old
  birthdate: date(
    new Date(new Date().getTime() - 100 * 365 * 24 * 60 * 60 * 1000),
    new Date(new Date().getTime() - 18 * 365 * 24 * 60 * 60 * 1000)
  ),
  birthCity: mandatoryStringUpperLower(),
  birthCountry: optionalString(),
  nationality: mandatoryString(),
  homePhone: optionalPhone(),
  mobilePhone: optionalPhone(),
});

const personalInfoDefaultValues: z.infer<typeof personalInfoSchema> = {
  sex: Sex.FEMALE,
  lastName: "",
  firstName: "",
  birthdate: nullDate(),
  birthCity: "",
  birthCountry: "",
  nationality: "",
  homePhone: "",
  mobilePhone: "",
};

export {
  credentialsSchema,
  credentialsDefaultValues,
  personalInfoDefaultValues,
  personalInfoSchema,
  emailSchema,
  emailDefaultValues,
};
