import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { StrictMode, useCallback, useEffect, useState } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { toast } from "sonner";
import "unfonts.css";
import { Toaster } from "./components/atoms/Sonner.tsx";
import { ROUTE } from "./constants/routes";
import { useAuthStore } from "./hooks/AuthStore";
import { routesTree } from "./routes-tree.tsx";
import "./styles/index.css";

const AppProviders = () => {
  const { logout } = useAuthStore();
  const routes = routesTree();

  const [router] = useState(() => {
    return createBrowserRouter(routes);
  });

  const { navigate } = router;

  const disconnect = useCallback(() => {
    logout();
    toast.info("Session expirée", {
      description: "Reconnectez vous pour accéder à votre compte.",
    });
    navigate(ROUTE.connectionHome());
  }, [logout, navigate]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        if (error.response?.status === 419) disconnect();
        else throw error;
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor); // Cleanup interceptor on unmount
    };
  }, [disconnect]);

  const [queryClient] = useState(() => new QueryClient());

  return (
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <Toaster />
      </QueryClientProvider>
    </StrictMode>
  );
};

export default AppProviders;
