import { MerchandiseClass } from "@/api/enums/merchandise-class.enum";
import { TransportMode } from "@/api/enums/transport-mode.enum";
import { z } from "zod";

const merchandisesSchema = z
  .enum([
    MerchandiseClass.CHEMICAL,
    MerchandiseClass.EXPLOSIVES,
    MerchandiseClass.GAS,
    MerchandiseClass.HYDROCARBONS,
    MerchandiseClass.RADIOACTIVE,
  ])
  .array()
  .superRefine((array, ctx) => {
    if (array.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vous devez choisir au moins 1 classe de marchandises",
      });
    }
  });

const transportsSchema = z
  .enum([TransportMode.BOAT, TransportMode.ROAD, TransportMode.TRAIN])
  .array()
  .superRefine((array, ctx) => {
    if (array.length === 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Vous devez choisir au moins 1 mode de transport",
      });
    }
  });

const perimeterSchema = z.object({
  merchandises: merchandisesSchema,
  transportModes: transportsSchema,
});

export { merchandisesSchema, perimeterSchema, transportsSchema };
