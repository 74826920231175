import { AdminRegistrationDtoClient } from "@/api/registration-dto/dto/admin-registration.dto";
import { Badge } from "@/components/atoms/Badge";

interface RegistrationStatusBadgeProps {
  registration: AdminRegistrationDtoClient;
}

const RegistrationStatusBadge = ({
  registration: { complete, examResult, exam },
}: RegistrationStatusBadgeProps) => {
  if (!complete) return <Badge variant="orange">Pré-inscrit</Badge>;

  if (examResult) return <Badge variant="sky">Validé</Badge>;

  if (new Date(exam.date) > new Date())
    return <Badge variant="green">Inscrit</Badge>;

  return <Badge variant="red">En attente de résultats</Badge>;
};

export default RegistrationStatusBadge;
